import theme from "shared/theme";

import styles from "./styles";
import Placeholders from "../../styles";

export const StatisticsCard: React.FC = () => {
  return (
    <styles.Container>
      <styles.Row>
        <div>
          <styles.Title />
          <styles.Label />
        </div>
        <styles.StageLabel />
      </styles.Row>
      <styles.Row>
        <div>
          <styles.Title />
          <styles.Label />
        </div>
      </styles.Row>
      <Placeholders.Button fullWidth color={theme.colors.placeholderElement} />
    </styles.Container>
  );
};
