import { DEFAULT_SCHEDULE, EMPTY_STRING } from "shared/constants";
import { ISecondStageData } from "shared/interfaces";
import { secondStageInitialData } from "store/slices/createLockup";

import { getDateInSecondsToISO } from "./dateOperations";

export const getSecondStageInitialData = (secondStage: ISecondStageData): ISecondStageData => {
  const schedule = secondStage.schedule.length === 0 ? [DEFAULT_SCHEDULE] : secondStage.schedule;
  const startDate = secondStage.startDate ? getDateInSecondsToISO(secondStage.startDate) : EMPTY_STRING;

  return {
    ...secondStage,
    startDateISOFormat: startDate,
    schedule,
  };
};

export const clearedSecondStageForm = (token: string): ISecondStageData => {
  return {
    ...secondStageInitialData,
    token,
    schedule: [DEFAULT_SCHEDULE],
  };
};
