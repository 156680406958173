import FungibleTokenContract from "services/contracts/FungibleToken";
import { IRPCProviderService } from "services/RPCProviderService";
import { ZERO_STRING } from "shared/constants";
import { IBalance } from "shared/interfaces";
import { isNotNullOrUndefined, toMap } from "shared/utils";

export async function retrieveTokensMetadata(
  tokenAddresses: string[],
  provider: IRPCProviderService,
  accountId: string
): Promise<{ [key: string]: FungibleTokenContract }> {
  const tokensMetadata: (FungibleTokenContract | null)[] = await Promise.all(
    tokenAddresses.map(async (address: string) => {
      const ftTokenContract: FungibleTokenContract = new FungibleTokenContract({
        provider,
        contractId: address,
        accountId,
      });
      const metadata = await ftTokenContract.getMetadata();
      if (!metadata) return null;
      return ftTokenContract;
    })
  );
  const tokensMetadataFiltered = tokensMetadata.filter(isNotNullOrUndefined);
  return toMap(tokensMetadataFiltered, "contractId");
}

export async function retrieveBalances(
  tokens: { [key: string]: FungibleTokenContract },
  accountId: string
): Promise<{ [key: string]: IBalance }> {
  const balancesArray: IBalance[] = await Promise.all(
    Object.values(tokens).map(async (tokenContract) => {
      const balance: string = (await tokenContract.getBalanceOf({ accountId })) || ZERO_STRING;
      return {
        address: tokenContract.contractId,
        balance,
        decimal: tokenContract.metadata.decimals,
        symbol: tokenContract.metadata.symbol,
      };
    })
  );

  return toMap(balancesArray, "address");
}

export const getToken = (id: string, tokens: { [key: string]: FungibleTokenContract }): FungibleTokenContract | null =>
  tokens[id] || null;
