import { FinalExecutionOutcome } from "@near-wallet-selector/core";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";

import { contractId } from "services/config";
import LockupContract from "services/contracts/LockupContract";
import RPCProviderService from "services/RPCProviderService";
import ToastService from "shared/components/Toast";
import { CLAIM_TOKEN_METADATA_KEY } from "shared/constants";
import { getToken } from "shared/helpers/tokens";
import { ITransaction } from "shared/interfaces";
import { isNotNullOrUndefined } from "shared/utils";
import { RootState } from "store";

import { updateState } from "./updateState";

export const claim = createAsyncThunk<
  void,
  {
    provider: RPCProviderService;
    lockupIds: number[];
    requestSignTransactions: (t: ITransaction[]) => Promise<void | FinalExecutionOutcome[]>;
  },
  { state: RootState }
>("lockups/claim", async ({ provider, lockupIds, requestSignTransactions }, { getState, dispatch }) => {
  try {
    const {
      user,
      lockups,
      tokens: { tokens },
    } = getState();
    const transactions: ITransaction[] = [];
    const uniqTokens = lockupIds.map((lockupId) => {
      const lockup = lockups.incoming[lockupId];
      const token = getToken(lockup.distributionTokenId, tokens);
      return token;
    });

    const storageDeposits = await Promise.all(
      uniqTokens.map((token) => token?.checkStorageBalance({ accountId: user.id }))
    );
    const filteredStorageDeposits = storageDeposits.filter(isNotNullOrUndefined);
    if (filteredStorageDeposits.length) transactions.push(...filteredStorageDeposits);

    lockupIds.forEach((lockupId) => {
      transactions.push(LockupContract.createClaimTransaction({ receiverId: user.lockupId, lockupId }));
    });

    if (lockupIds.length === 1) {
      const [firstItem] = lockupIds;
      const { distributionTokenId } = lockups.incoming[firstItem];
      const token = getToken(distributionTokenId, tokens);
      if (token) localStorage.setItem(CLAIM_TOKEN_METADATA_KEY, JSON.stringify(token.metadata));
    }

    const result = await requestSignTransactions(transactions);
    if (result) dispatch(updateState({ provider: provider, contractId }));
  } catch (error) {
    ToastService.error({ text: t("toast.error.claim") });
    console.error("Error during claim: ", error);
  }
});
