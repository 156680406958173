import styled from "styled-components";

import { changeTransition } from "shared/theme/styles";

const StepperProgressWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const ProgressBar = styled.progress`
  border: 0;
  width: 100%;
  height: 0.25rem;
  border-radius: 0.125rem;
  background-color: rgba(${({ theme }) => theme.colors.blueRGB}, 0.4);

  &::-webkit-progress-bar {
    border: 0;
    height: 0.25rem;
    border-radius: 0.125rem;
    background-color: rgba(${({ theme }) => theme.colors.blueRGB}, 0.4);
  }

  &::-webkit-progress-value {
    border: 0;
    height: 0.25rem;
    border-radius: 0.125rem;
    background-color: rgb(${({ theme }) => theme.colors.blueRGB});
    transition: ${changeTransition};
  }

  &::-moz-progress-bar {
    border: 0;
    height: 0.25rem;
    border-radius: 0.125rem;
    background-color: rgba(${({ theme }) => theme.colors.blueRGB}, 0.4);
  }
`;

const StepOfSteps = styled.div`
  font-weight: 500;
  line-height: 1rem;
  text-align: center;
  font-size: 0.875rem;
  margin-bottom: 0.6875rem;
  color: ${({ theme }) => theme.colors.textGrey};
`;

export default {
  StepOfSteps,
  ProgressBar,
  StepperProgressWrapper,
};
