import styled from "styled-components";

const Image = styled.img<{ rounded?: boolean; marginRight?: boolean }>`
  width: 1.5rem;
  display: flex;
  height: 1.5rem;
  object-fit: cover;
  align-items: center;
  justify-content: center;
  border-radius: ${({ rounded }) => (rounded ? "50%" : 0)};
`;

export default {
  Image,
};
