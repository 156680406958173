import styles from "./styles";
import { Translate } from "../Translate";

export const successContent = (text: string, additionalText?: string | null) => (
  <styles.Container>
    <styles.SuccessIcon />
    <styles.Description>
      <p>{text}</p>
      {additionalText && <p>{additionalText}</p>}
    </styles.Description>
  </styles.Container>
);

export const errorContent = (text: string, href?: string) => (
  <styles.Container>
    <styles.ErrorIcon />
    <styles.Description $error>
      <p>{text}</p>
      {href && (
        <styles.Link href={href} target="_blank" rel="noreferrer">
          <Translate value="toast.openTransaction" />
        </styles.Link>
      )}
    </styles.Description>
  </styles.Container>
);
