import { ReactComponent as Close } from "assets/images/icons/close.svg";

import { IButtonFABProps } from "./interfaces";
import styles from "./styles";

export function ButtonFAB({ handleClick, isOpened, iconToOpen, loading }: IButtonFABProps) {
  return (
    <styles.ButtonFABWrapper onClick={() => !loading && handleClick()} $loading={loading}>
      {isOpened ? <Close /> : iconToOpen}
    </styles.ButtonFABWrapper>
  );
}
