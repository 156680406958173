import { ELockupStage, ILockupCheckpoint } from "shared/interfaces";

import { getCurrentTimestamp } from "./dateOperations";

export const validateStage = (stage: ELockupStage, cliff = 0, distributionSchedule: ILockupCheckpoint[]) => {
  if (stage !== ELockupStage.Running) return stage;
  const lastCheckpoint = distributionSchedule[distributionSchedule.length - 1].endDate;
  const lockupEnded = lastCheckpoint < getCurrentTimestamp();
  if (lockupEnded) return ELockupStage.Ended;
  const currentTimeStamp = getCurrentTimestamp();
  return currentTimeStamp > cliff ? ELockupStage.Running : ELockupStage.Cliff;
};
