import { t } from "i18next";

import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow.svg";
import { ReactComponent as PlusIcon } from "assets/images/icons/plus-in-circle.svg";
import { ReactComponent as WalletIcon } from "assets/images/icons/wallet.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { Logo } from "shared/components/Logo";
import Placeholders from "shared/components/Placeholders/styles";
import { EMPTY_STRING } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import theme from "shared/theme";
import { cropString } from "shared/utils/cropString";
import { selectLoading } from "store/selectors/selectLoading";
import { EModals, showModal } from "store/slices/modals";

import { IMobileHeader } from "./interface";
import styles from "./styles";

const MobileHeader: React.FC<IMobileHeader> = ({ isSmallScreen, accountId }: IMobileHeader) => {
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const { signOut } = useWalletSelector();
  return (
    <styles.Container>
      <Logo width={isSmallScreen ? 6.25 : 7.5} />
      <div>
        {loading ? (
          <Placeholders.Button color={theme.colors.placeholder} />
        ) : (
          <ButtonPrimary
            name={t("actions.createLockup")}
            handler={() => dispatch(showModal({ modal: EModals.CREATE_LOCKUP_MODAL, props: {} }))}
            iconLeft={<PlusIcon />}
          />
        )}
        <ButtonPrimary
          name={isSmallScreen ? EMPTY_STRING : cropString(accountId)}
          handler={() => dispatch(showModal({ modal: EModals.ACCOUNT_MODAL, props: { signOut } }))}
          iconLeft={<WalletIcon />}
          iconRight={<ArrowIcon />}
          color={EButtonColors.WALLET}
        />
      </div>
    </styles.Container>
  );
};
export default MobileHeader;
