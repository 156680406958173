import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ITokenPrice } from "shared/interfaces";
import { RootState } from "store";

export const pricesSlice = createSlice({
  name: "prices",
  initialState: {} as { [key: string]: ITokenPrice },
  reducers: {
    setPrices: (_, action: PayloadAction<{ [key: string]: ITokenPrice }>) => action.payload,
  },
});

// SELECTORS
export const selectPrices = (state: RootState) => state.prices;
// ACTIONS
export const { setPrices } = pricesSlice.actions;

export const pricesReducer = pricesSlice.reducer;
