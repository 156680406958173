import styled from "styled-components";

const ButtonsGroupWrapper = styled.div`
  display: flex;
  width: 100%;

  button {
    font-size: 1rem;

    &:first-child {
      width: 5.375rem;
      margin-right: 0.75rem;
    }

    &:last-child {
      flex: 1;
    }
  }
`;

export default { ButtonsGroupWrapper };
