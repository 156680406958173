import { t } from "i18next";

import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { showModal, EModals } from "store/slices/modals";

import styles from "./styles";
import { ButtonPrimary } from "../Buttons/ButtonPrimary";
import { EButtonColors } from "../Buttons/interfaces";
import { Translate } from "../Translate";

export const DeployContractBlock: React.FC = () => {
  const dispatch = useAppDispatch();
  return (
    <styles.Container>
      <styles.Description>
        <styles.Info />
        <Translate value="deployedContract" />
      </styles.Description>
      <ButtonPrimary
        name={t("actions.deployContract")}
        handler={() => dispatch(showModal({ modal: EModals.DEPLOY_CONTRACT_MODAL, props: {} }))}
        color={EButtonColors.DARK_BLUE}
      />
    </styles.Container>
  );
};
