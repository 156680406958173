import { t } from "i18next";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Arrow } from "assets/images/icons/arrow-diagonal.svg";
import Medium from "assets/images/icons/medium.svg";
import Telegram from "assets/images/icons/telegram.svg";
import Twitter from "assets/images/icons/twitter.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { APP_ROUTES } from "routes/appRoutes";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { Logo } from "shared/components/Logo";
import { EDimensions } from "shared/constants";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { slideDown } from "shared/theme/animations/landing";

import styles from "./styles";

//TODO: ADD SOCIAL LINK
const socials = [
  { icon: Medium, link: "https://medium.com/lockup-dapp" },
  { icon: Telegram, link: "https://t.me/lockup_dapp" },
  { icon: Twitter, link: "https://twitter.com/Lockup_dapp" },
];

export default function Header() {
  const { isSignedIn, openModal } = useWalletSelector();
  const navigate = useNavigate();

  const onClick = useCallback(
    () => (isSignedIn() ? navigate(APP_ROUTES.APP) : openModal()),
    [isSignedIn, navigate, openModal]
  );

  const dimensions = useWindowDimensions();
  const hideOpenApp = dimensions !== EDimensions.SMALL;
  const logoWidth = dimensions === EDimensions.SMALL ? 7.938 : 12.85;
  return (
    <styles.Header variants={slideDown} initial="initial" animate="animate">
      <Logo width={logoWidth} />
      <styles.RightContainer>
        <styles.SocialsContainer>
          {socials.map(({ icon, link }) => (
            <a key={link} href={link} target="_blank" rel="noreferrer">
              <styles.SocialImage loading="lazy" src={icon} />
            </a>
          ))}
        </styles.SocialsContainer>
        {hideOpenApp && (
          <ButtonPrimary
            fullWidth
            name={isSignedIn() ? t("landing.openApp") : t("actions.connectWallet")}
            handler={onClick}
            iconRight={isSignedIn() ? <Arrow /> : undefined}
            color={isSignedIn() ? undefined : EButtonColors.DARK_BLUE}
          />
        )}
      </styles.RightContainer>
    </styles.Header>
  );
}
