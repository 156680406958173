import { Trans } from "react-i18next";

import { ReactComponent as Archived } from "assets/images/icons/archived.svg";
import { ReactComponent as Cliff } from "assets/images/icons/cliff.svg";
import { ReactComponent as DoubleCheckMark } from "assets/images/icons/double-check-mark.svg";
import { ReactComponent as CheckMark } from "assets/images/icons/ended.svg";
import { ReactComponent as Incoming } from "assets/images/icons/incoming.svg";
import { ReactComponent as Outgoing } from "assets/images/icons/outgoing.svg";
import { ReactComponent as Terminated } from "assets/images/icons/terminated.svg";
import { ZERO_STRING } from "shared/constants";
import { ELockupStage, ETypeLockup } from "shared/interfaces";
import theme from "shared/theme";
import { displayBalance } from "shared/utils";

import { Translate } from "../Translate";

const redBG = `rgba(${theme.colors.red}, 0.1)`;
const greenBG = `rgba(${theme.colors.green}, 0.1)`;
const orangeBG = `rgba(${theme.colors.orange}, 0.1)`;
const greyBG = `rgba(${theme.colors.grey}, 0.1)`;

export function getStageData(
  stage: ELockupStage = ELockupStage.Running,
  type: ETypeLockup,
  value?: string,
  symbol?: string,
  isFiatValue = false
) {
  switch (stage) {
    case ELockupStage.Running: {
      const formattedValue = displayBalance(value || ZERO_STRING);
      return {
        icon: type === ETypeLockup.Incoming ? <Incoming /> : <Outgoing />,
        text: isFiatValue ? (
          <Trans i18nKey="financialStatusPerDay" values={{ value: formattedValue }} />
        ) : (
          <Trans i18nKey="tokenStatusPerDay" values={{ value: formattedValue, symbol }} />
        ),
        labelBGColor: type === ETypeLockup.Incoming ? greenBG : redBG,
      };
    }
    case ELockupStage.Cliff:
      return { icon: <Cliff />, text: <Translate value="lockup.stages.cliff" />, labelBGColor: orangeBG };
    case ELockupStage.Created:
      return { icon: <CheckMark />, text: <Translate value="lockup.stages.initialized" />, labelBGColor: greenBG };
    case ELockupStage.Archived:
      return { icon: <Archived />, text: <Translate value="lockup.stages.archived" />, labelBGColor: greenBG };
    case ELockupStage.Terminated:
      return { icon: <Terminated />, text: <Translate value="lockup.stages.terminated" />, labelBGColor: redBG };
    case ELockupStage.Ended:
      return { icon: <DoubleCheckMark />, text: <Translate value="lockup.stages.ended" />, labelBGColor: greyBG };
    default:
      return null;
  }
}
