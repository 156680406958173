import Statistics from "pages/HomePage/components/Statistics";
import { Translate } from "shared/components/Translate";
import { IModalProps } from "shared/interfaces";

import styles from "./styles";
import ModalWrapper from "../ModalWrapper";

export const StatisticsModal: React.FC<IModalProps> = ({ closeModal }: IModalProps) => {
  return (
    <ModalWrapper closeModal={closeModal} clearDefaultStyles>
      <styles.Title>
        <Translate value="statistic.dashboard" />
      </styles.Title>
      <Statistics />
    </ModalWrapper>
  );
};
