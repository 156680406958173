import styled from "styled-components";

const LogoWrapper = styled.div<{ width?: number }>`
  line-height: 0;
  cursor: pointer;
  svg {
    height: auto;
    width: ${({ width }) => (width ? `${width}rem` : "10.875rem")};
  }
`;

export default {
  LogoWrapper,
};
