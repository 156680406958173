import styled from "styled-components";

import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow.svg";
import { ReactComponent as WalletIcon } from "assets/images/icons/wallet.svg";
import { flexRowNoWrap } from "shared/theme/styles";

const Container = styled.header`
  ${flexRowNoWrap}
  padding: 1.5rem 3rem 0 0;
  justify-content: space-between;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 100;
  backdrop-filter: blur(5px);
`;

export default {
  Container,
  ArrowIcon,
  WalletIcon,
};
