import { Variants } from "framer-motion";
import { css } from "styled-components";

export const defaultVariant: Variants = {
  initial: { opacity: 0, scale: 0.9 },
  animate: { opacity: 1, scale: 1, transition: { duration: 0.3 } },
};

export const getFadeIn = (isInView: boolean) => css`
  opacity: ${isInView ? 1 : 0};
  transition: all 1s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s;
`;

export const slideDown: Variants = {
  initial: {
    y: "-200px",
    opacity: 0,
  },
  animate: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.3,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
};
