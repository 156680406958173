import { LockupCheckpointOutput } from "services/contracts/LockupContract/interfaces";
import { ICreateLockupSchedule } from "shared/interfaces";

import { BigNumber, convertToDecimalsNumber } from "./calculations";

export function setScheduleContractType(schedule: ICreateLockupSchedule[], decimals: number): LockupCheckpointOutput[] {
  const initialAcc: { [key: number]: LockupCheckpointOutput } = {
    0: { end_date: schedule[0].endDate, amount: schedule[0].amount },
  };

  const convertedSchedule = schedule.reduce((acc, currentCheckpoint, index) => {
    if (index === 0) return initialAcc;

    const checkpointTotalAmount = BigNumber(acc[index - 1].amount)
      .plus(currentCheckpoint.amount)
      .toString();
    const checkpoint = { end_date: currentCheckpoint.endDate, amount: checkpointTotalAmount };

    return { ...acc, [index]: checkpoint };
  }, initialAcc);

  return Object.values(convertedSchedule).map((checkpoint) => {
    return { ...checkpoint, amount: convertToDecimalsNumber(checkpoint.amount, decimals) };
  });
}
