import dayjs, { Dayjs } from "dayjs";
import { t } from "i18next";

import {
  ONE_DAY,
  TEN,
  THOUSAND,
  ZERO_STRING,
  LOCALE_STRING_ARGUMENT,
  LOCALE_STRING_OPTIONS,
  MONTHS_IN_YEAR,
  DATE_IN_MONTH,
} from "shared/constants";

export const getDateInSeconds = (date: string) => {
  return new Date(date).getTime() / THOUSAND;
};

export const getDateInSecondsToISO = (date: number) => {
  return new Date(date * THOUSAND).toISOString().split("T")[0];
};

const formatAmount = (amount: number, addZero = true) => {
  return amount < TEN && addZero ? `${ZERO_STRING}${amount}` : amount.toString();
};

export const getCurrentTimestamp = () => {
  return Math.floor(new Date().getTime() / THOUSAND);
};

export function formatDate(timestamp: number) {
  const newDate = new Date(timestamp * THOUSAND);
  const month = new Date(newDate).toLocaleString(LOCALE_STRING_ARGUMENT, LOCALE_STRING_OPTIONS);

  return `${formatAmount(newDate.getUTCDate())} ${month} ${newDate.getUTCFullYear()}`;
}

export const getTwoDateDifference = (startDateNum: number, endDateNum: number) => {
  const startDate = new Date(startDateNum * THOUSAND);
  const endDate = new Date(endDateNum * THOUSAND);
  const differenceInTime = endDateNum - startDateNum;
  const days = Math.ceil(differenceInTime / ONE_DAY);
  if (days <= DATE_IN_MONTH) return t("createLockup.daysDifference", { days });
  const yearDifferenceInMonth = (endDate.getUTCFullYear() - startDate.getUTCFullYear()) * MONTHS_IN_YEAR;
  const months = endDate.getUTCMonth() - startDate.getUTCMonth() + yearDifferenceInMonth;
  return t("createLockup.monthDifference", { months });
};

export const createFormattedDate = (date: Dayjs): string => dayjs(date).format("MM/DD/YYYY");
