import { FirstStageForm, SecondStageForm, ThirdStageForm } from "shared/components/Form";
import { ECreateLockupStage } from "shared/interfaces";

import { IModalStageSwitcher } from "./interface";

export function ModalStageSwitcher({ stage }: IModalStageSwitcher) {
  switch (stage) {
    case ECreateLockupStage.FIRST:
      return <FirstStageForm />;
    case ECreateLockupStage.SECOND:
      return <SecondStageForm />;
    case ECreateLockupStage.THIRD:
      return <ThirdStageForm />;
    default:
      return null;
  }
}
