import { contractId } from "services/config";
import { FactoryContract } from "services/contracts";
import { IRPCProviderService } from "services/RPCProviderService";
import { EMPTY_STRING, ZERO_STRING } from "shared/constants";
import { IFirstStageData } from "shared/interfaces";

export async function getOrCreateLockupContractId(
  receiver: string,
  provider: IRPCProviderService
): Promise<IFirstStageData["receiverLockupContract"]> {
  const factory = new FactoryContract({ provider, contractId });
  const lockupContractId = await factory.getLockupId({ accountId: receiver });

  if (lockupContractId) {
    return { id: lockupContractId, isDeployed: true };
  } else {
    const queryAsBuffer = new TextEncoder().encode(receiver);
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", queryAsBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashArraySliced = hashArray.slice(0, 10);
    const hashArrayToHex = hashArraySliced.map((b) => b.toString(16).padStart(2, ZERO_STRING)).join(EMPTY_STRING);

    return { id: `${hashArrayToHex}.${contractId}`, isDeployed: false };
  }
}
