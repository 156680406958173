export interface IFactoryContract {
  getLockupsCount(): Promise<number | undefined>;
  getLockups({ from, limit }: { from: number; limit: number }): Promise<{ [key: string]: string } | undefined>;
  getLockupId({ accountId }: { accountId: string }): Promise<string | undefined>;
  getLockupsByOwnerCount({ ownerId }: { ownerId: string }): Promise<number | undefined>;
  getLockupsByOwner({
    ownerId,
    from,
    limit,
  }: {
    ownerId: string;
    from: number;
    limit: number;
  }): Promise<{ [key: string]: number[] } | undefined>;
}

export enum FactoryMethods {
  getLockupsContractsCount = "get_lockup_contracts_count", // ()
  getLockupsContracts = "get_lockup_contracts", // (from_index: u64, limit: u64)
  getLockupContractId = "get_lockup_contract_id", // (account_id: AccountId)
  getLockupsByOwner = "get_lockups_by_owner", //(owner_id: AccountId, from_index: Option<u64>, limit: Option<u64>)
  getLockupsByOwnerCount = "get_lockups_by_owner_count", // (owner_id: AccountId)
}

export const FactoryChangeMethods = {
  deployLockup: "deploy_lockup_contract",
};
