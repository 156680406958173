import { Variants } from "framer-motion";

export const slideDown: Variants = {
  initial: {
    height: 0,
    overflow: "hidden",
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
  animate: {
    height: "auto",
    transition: {
      staggerChildren: 0.05,
      staggerDirection: 1,
      height: {
        duration: 0.3,
      },
    },
  },
  exit: {
    height: 0,
    overflow: "hidden",
    transition: {
      staggerChildren: 0.01,
      staggerDirection: -1,
      height: {
        duration: 0.3,
      },
    },
  },
};

export const itemVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
