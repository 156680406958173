import styled, { css } from "styled-components";

import { getFadeIn } from "shared/theme/animations/landing";
import { flexColumnNoWrap } from "shared/theme/styles";

const Section = styled.section<{ isInView: boolean }>`
  ${flexColumnNoWrap}
  margin-top: 5.625rem;
  max-width: 62rem;
  width: 100%;
  ${({ isInView }) => getFadeIn(isInView)}
`;

const Title = styled.p`
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.8;
  margin-bottom: 2rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1rem;
        line-height: 1.813rem;
      `
    )}
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 2.875rem 3.625rem;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        grid-gap: 1.938rem 1.25rem;
      `
    )}
`;

const Link = styled.a`
  text-decoration: none;
  width: 10rem;
  height: 3rem;
  img {
    width: 100%;
    height: 100%;
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        width: 5.813rem;
        height: 1.75rem;
      `
    )}
`;

export default {
  Section,
  Title,
  List,
  Link,
};
