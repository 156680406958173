import styled, { css } from "styled-components";

import { getFadeIn } from "shared/theme/animations/landing";
import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const Section = styled.section<{ isInView: boolean }>`
  max-width: 55rem;
  width: 100%;
  ${flexColumnNoWrap}
  margin-top: 7.5rem;
  ${({ isInView }) => getFadeIn(isInView)}
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 2rem;
  line-height: 2.375rem;
  text-align: center;
  margin-bottom: 4.5rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1.5rem;
        line-height: 1.813rem;
        margin-bottom: 3rem;
      `
    )}
`;

const List = styled.div`
  ${flexColumnNoWrap}
  gap: 2rem;
`;

const Element = styled.div`
  ${flexRowNoWrap}
  gap: 3.75rem;
  padding: 2rem 2rem 2rem 3.75rem;
  backdrop-filter: blur(6px);
  min-height: 13.75rem;
  position: relative;
  border-radius: 1.5rem;
  ::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 1.5rem;
    padding: 2px;
    background: ${({ theme }) => theme.colors.landingLinearGradient};
    mask: linear-gradient(${({ theme }) => theme.colors.white} 0 0) content-box,
      linear-gradient(${({ theme }) => theme.colors.white} 0 0);
    mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        ${flexColumnNoWrap}
        gap: 1.25rem;
        padding: 1.5rem 1rem 2.625rem 1rem;
        img {
          width: 4.063rem;
          height: 4.063rem;
        }
      `
    )}
`;

const ElementTitle = styled.div`
  ${flexColumnNoWrap}
  gap: 1.5rem;
  p {
    :first-child {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.813rem;
      color: ${({ theme }) => theme.colors.white};
    }
    :last-child {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.75rem;
      color: ${({ theme }) => theme.colors.textGrey};
    }
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        p {
          :first-child {
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
          :last-child {
            font-size: 1rem;
            line-height: 1.375rem;
          }
        }
      `
    )}
`;

export default {
  Section,
  Title,
  List,
  Element,
  ElementTitle,
};
