import { t } from "i18next";
import { Trans } from "react-i18next";

import { ReactComponent as WarningIcon } from "assets/images/icons/warning.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { NEAR_TOKEN } from "services/contracts/FungibleToken";
import { ButtonsGroup } from "shared/components/Buttons/ButtonsGroup";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { Translate } from "shared/components/Translate";
import TwoColumnList from "shared/components/TwoColumnList";
import { MessageWarning } from "shared/components/TwoColumnList/components/MessageWarning";
import { ITwoColumnListProps } from "shared/components/TwoColumnList/interface";
import { DEPLOY_CONTRACT_FEE, EMPTY_STRING } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { getTwoDateDifference, formatDate, isNotNullOrUndefined } from "shared/utils";
import { calcLockupStorageFee, formatUnits } from "shared/utils/calculations";
import { setScheduleContractType } from "shared/utils/setScheduleContractType";
import { createLockup } from "store/actions/createLockup";
import { selectedCreateLockupData } from "store/selectors/selectCreateLockupData";
import { selectToken } from "store/selectors/selectToken";
import { closeModal } from "store/slices/modals";

import styles from "./styles";

const ThirdStageForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { RPCProvider, requestSignTransactions } = useWalletSelector();
  const { receiver, thirdPartyOwner, schedule, canTerminate, token, startDate, cliff, receiverLockupContract } =
    useAppSelector(selectedCreateLockupData);
  const tokenData = useAppSelector((state) => selectToken(state, token));

  if (!tokenData) return null;

  const listTitle = t("createLockup.confirmLockup.title");

  const scheduleContractTyped = setScheduleContractType(schedule, tokenData.metadata.decimals);
  const lastCheckpoint = scheduleContractTyped[schedule.length - 1];

  const storageDeposit = calcLockupStorageFee(scheduleContractTyped.length);
  const readableStorageDeposit = formatUnits(storageDeposit, NEAR_TOKEN.decimals);
  const deployContractFee = formatUnits(DEPLOY_CONTRACT_FEE, NEAR_TOKEN.decimals);

  const detailsList: ITwoColumnListProps["list"] = [
    {
      title: <Translate value="createLockup.receiver.title" />,
      value: receiver,
    },
    {
      title: <Translate value="createLockup.owner.title" />,
      value: thirdPartyOwner ? thirdPartyOwner : <Translate value="createLockup.owner.me" />,
    },
    {
      title: <Translate value="createLockup.confirmLockup.totalLockupAmount" />,
      value: (
        <Trans
          i18nKey="tokenAmount"
          values={{
            amount: formatUnits(lastCheckpoint.amount, tokenData.metadata.decimals),
            symbol: tokenData.metadata.symbol,
          }}
        />
      ),
    },
    {
      title: <Translate value="createLockup.confirmLockup.startDate" />,
      value: startDate ? formatDate(startDate) : EMPTY_STRING,
    },
    {
      title: <Translate value="createLockup.confirmLockup.endDate" />,
      value: formatDate(lastCheckpoint.end_date),
    },
    {
      title: <Translate value="createLockup.confirmLockup.cliffPeriod" />,
      value: startDate && cliff ? getTwoDateDifference(startDate, cliff) : "-",
    },
    {
      title: <Translate value="createLockup.confirmLockup.type.title" />,
      value: canTerminate ? (
        <Translate value="createLockup.confirmLockup.type.terminated" />
      ) : (
        <Translate value="createLockup.confirmLockup.type.unterminated" />
      ),
      valueAddition: canTerminate ? undefined : (
        <MessageWarning text={t("createLockup.confirmLockup.type.unterminatedAlert")} icon={<WarningIcon />} />
      ),
    },
    (!receiverLockupContract.isDeployed && {
      title: <Translate value="createLockup.confirmLockup.fee.deployContract" />,
      value: <Trans i18nKey="createLockup.confirmLockup.fee.value" values={{ fee: deployContractFee }} />,
    }) ||
      null,
    {
      title: <Translate value="createLockup.confirmLockup.fee.storageDeposit" />,
      value: <Trans i18nKey="createLockup.confirmLockup.fee.value" values={{ fee: readableStorageDeposit }} />,
    },
  ].filter(isNotNullOrUndefined);

  function createLockupHandler() {
    dispatch(createLockup({ provider: RPCProvider, requestSignTransactions }));
  }

  return (
    <styles.ThirdStageWrapper>
      <TwoColumnList list={detailsList} title={listTitle} largeList />
      <styles.Buttons>
        <ButtonsGroup
          cancelButtonHandler={() => dispatch(closeModal())}
          rightButton={{
            name: t("actions.confirm"),
            handler: createLockupHandler,
            color: EButtonColors.GREEN,
          }}
        />
      </styles.Buttons>
    </styles.ThirdStageWrapper>
  );
};

export default ThirdStageForm;
