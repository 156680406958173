import { t } from "i18next";

import { ReactComponent as PlusIcon } from "assets/images/icons/plus-in-circle.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { DeployContractBlock } from "shared/components/DeployContractBlock";
import Placeholders from "shared/components/Placeholders";
import StatisticsCard from "shared/components/StatisticsCard";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { ETypeLockup } from "shared/interfaces";
import { statisticDetails } from "shared/utils";
import { claim } from "store/actions/claim";
import { selectData } from "store/selectors/selectData";
import { EModals, showModal } from "store/slices/modals";

import styles from "./styles";

const Statistics: React.FC = () => {
  const { requestSignTransactions, RPCProvider } = useWalletSelector();
  const {
    tokens,
    lockups,
    loading,
    prices,
    user: { isDeployedContract },
  } = useAppSelector(selectData);
  const dispatch = useAppDispatch();
  const details = statisticDetails(lockups.incoming.arr, lockups.outgoing.arr, tokens, prices);
  if (loading) return <Placeholders.Statistics />;

  const claimAllHandler = () => {
    dispatch(claim({ provider: RPCProvider, lockupIds: details.incomingLockupIds, requestSignTransactions }));
  };
  const disabledClaim = details.incomingLockupIds.length === 0;
  return (
    <styles.Container>
      {!isDeployedContract && <DeployContractBlock />}
      <StatisticsCard
        typeLockup={ETypeLockup.Outgoing}
        amount={lockups.outgoing.arr.length}
        details={details.outgoingDetails}
        valuePerDay={details.outgoingPricePerDay}
      >
        <ButtonPrimary
          name={t("actions.createLockup")}
          handler={() => dispatch(showModal({ modal: EModals.CREATE_LOCKUP_MODAL, props: {} }))}
          iconLeft={<PlusIcon />}
        />
      </StatisticsCard>
      <StatisticsCard
        typeLockup={ETypeLockup.Incoming}
        amount={lockups.incoming.arr.length}
        details={details.incomingDetails}
        valuePerDay={details.incomingPricePerDay}
      >
        <ButtonPrimary
          name={t("actions.claimAll")}
          handler={claimAllHandler}
          disabled={disabledClaim}
          color={EButtonColors.DARK_GREEN}
        />
      </StatisticsCard>
    </styles.Container>
  );
};

export default Statistics;
