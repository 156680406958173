import { t } from "i18next";
import { Trans } from "react-i18next";

import { ReactComponent as Terminate } from "assets/images/icons/terminate-white.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";
import { ButtonsGroup } from "shared/components/Buttons/ButtonsGroup";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { Translate } from "shared/components/Translate";
import TwoColumnList from "shared/components/TwoColumnList";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { ITerminationModal } from "shared/interfaces";
import { terminate } from "store/actions/terminate";

import styles from "./styles";
import ModalWrapper from "../ModalWrapper";

export const TerminationModal: React.FC<ITerminationModal> = ({
  closeModal,
  lockupId,
  receiverLockupId,
  amount,
  symbol,
  receiver,
}: ITerminationModal) => {
  const { requestSignTransactions, RPCProvider } = useWalletSelector();
  const dispatch = useAppDispatch();
  const terminateHandler = () => {
    dispatch(terminate({ provider: RPCProvider, requestSignTransactions, receiverLockupId, lockupId }));
  };

  const detailsList = [
    {
      title: <Translate value="lockup.details.amount" />,
      value: <Trans i18nKey="lockup.details.amountValue" values={{ amount, symbol }} />,
    },
    {
      title: <Translate value="lockup.details.receiver" />,
      value: receiver,
    },
  ];

  return (
    <ModalWrapper closeModal={closeModal} widthInRem="20.5">
      <styles.Header>
        <ButtonIcon control={{ icon: <styles.CloseIcon />, handler: closeModal }} />
      </styles.Header>
      <styles.Title>
        <Translate value="modals.terminate.title" />
      </styles.Title>
      <styles.ListWrapper>
        <TwoColumnList list={detailsList} />
      </styles.ListWrapper>
      <ButtonsGroup
        cancelButtonHandler={closeModal}
        rightButton={{
          name: t("actions.terminateYes"),
          handler: () => {
            terminateHandler();
            closeModal();
          },
          iconLeft: <Terminate />,
          color: EButtonColors.PINK,
        }}
      />
    </ModalWrapper>
  );
};
