import styled, { css } from "styled-components";

import { flexColumnNoWrap } from "shared/theme/styles";

const Form = styled.form`
  ${flexColumnNoWrap}
  padding: 0 2rem;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 0 1rem;
      `
    )}
`;

const Buttons = styled.div`
  bottom: 0;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  padding: 2rem 0;
  position: sticky;
  background-color: ${({ theme }) => theme.colors.cardBg};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 2rem 0 1rem 0;
      `
    )}
`;

export default {
  Form,
  Buttons,
};
