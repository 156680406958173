import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.interSerif};
  }

  body, #root {
    background-color: ${({ theme }) => theme.colors.background};
    margin: 0;
    padding: 0;
    min-height: 100vh;
    position: relative;
  }

  #root {
    color: ${({ theme }) => theme.colors.white};
  }

  p {
    margin: 0;
  }

  a {
    font-weight: 600;
    font-size: 0.875rem;
    text-decoration: none;
    color: rgb(${({ theme }) => theme.colors.grey});
  }

  .Toastify__toast-container {
    max-width: 27.625rem;
    width: fit-content;
    min-width: 20rem;
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }

  .MuiCalendarPicker-root {
    background-color: ${({ theme }) => theme.colors.toastBg} !important;
  }
`;

export default GlobalStyle;
