import { ICreateLockupSchedule } from "shared/interfaces";

export const MEDIA_WIDTHS = {
  upToSmall: 600,
  upToMedium: 900,
  upToLarge: 1280,
};

export enum EDimensions {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
  UNKNOWN = "UNKNOWN",
}

export const TEN = 10;
export const HUNDRED = 100;
export const THOUSAND = 1000;
export const FIVE_SECOND = 1000 * 5;
export const ONE_DAY = 3600 * 24;
export const DEFAULT_PAGE_LIMIT = 100;
export const BALANCE_PRECISION = 3;
export const MONTHS_IN_YEAR = 12;
export const DATE_IN_MONTH = 30;

export const ZERO = 0;
export const ZERO_STRING = "0";
export const EMPTY_STRING = "";
export const HUNDRED_STRING = "100";
export const FULFILLED = "fulfilled";
export const DEFAULT_MODAL_WIDTH_IN_REM = "20.5";
export const CREATE_LOCKUP_MODAL_WIDTH_IN_REM = "32.5";

export const DEFAULT_SCHEDULE: ICreateLockupSchedule = {
  endDate: 0,
  endDateISOFormat: EMPTY_STRING,
  amount: EMPTY_STRING,
};

export const DATE_FORMAT = "__ / __ / ____";
export const LOCALE_STRING_ARGUMENT: Intl.LocalesArgument = "en-US";
export const LOCALE_STRING_OPTIONS: Intl.DateTimeFormatOptions = { month: "short" };
export const linkOptions = {
  blank: "_blank",
  noreferrer: "noreferrer",
};

export const FORMAT_DOT = ".";
export const FORMAT_COMMA = ",";

export * from "./wallets";
export * from "./key";
