import styled, { css } from "styled-components";

const CreateLockupWrapper = styled.div`
  > div {
    & > div {
      padding: 0;
      position: relative;
    }
  }
`;

const HeaderStepper = styled.div`
  top: 0;
  z-index: 9;
  position: sticky;
  padding: 1.5rem 2rem 2rem 2rem;
  background-color: ${({ theme }) => theme.colors.cardBg};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 1rem 1rem 2rem 1rem;
      `
    )}
`;

export default {
  CreateLockupWrapper,
  HeaderStepper,
};
