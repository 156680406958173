import { FORMAT_COMMA, FORMAT_DOT, FULFILLED } from "shared/constants";

export { default as formatLockup } from "./formatLockup";
export { default as generateTokenList } from "./generateTokenList";
export * from "./statistics";
export * from "./validationSchemes";
export * from "./formatForm";
export * from "./initialFromData";
export * from "./dateOperations";
export * from "./displayAmount";
export * from "./sorting";

export function isNotNullOrUndefined<T extends object>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}
export function assertFulfilled<T>(item: PromiseSettledResult<T>): item is PromiseFulfilledResult<T> {
  return item.status === FULFILLED;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toMap = (array: Array<any>, getBy: string) =>
  array.reduce((acc, item) => ({ ...acc, [item[getBy]]: item }), {});

export const toArray = <T>(map: { [key: string]: T }) => Object.values(map);

export const removeTrailingZeros = (amount: string) => {
  if (amount.includes(FORMAT_DOT) || amount.includes(FORMAT_COMMA)) {
    return amount.replace(/\.?0*$/, "");
  }
  return amount;
};

export const getOutgoingLockupsKey = (id: number, contractId: string) => `${id}-${contractId}`;
