import styled, { css } from "styled-components";

import { ReactComponent as Dashboard } from "assets/images/icons/dashboard.svg";
import { flexColumnNoWrap } from "shared/theme/styles";

const Container = styled.main`
  ${flexColumnNoWrap}
  min-height: 100vh;
  max-width: 100vw;
`;

const Circle = styled.button`
  z-index: 121;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue};
  position: fixed;
  bottom: 72px;
  left: 48px;
  cursor: pointer;
  outline: none;
  border: none;
  box-shadow: 0px 11px 35px rgba(20, 25, 49, 0.9);
  :hover {
    background: ${({ theme }) => theme.buttons.blueHover};
  }
  :active {
    background: ${({ theme }) => theme.buttons.blueActive};
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        bottom: 45px;
        left: 8px;
      `
    )}
`;

const Footer = styled.footer`
  z-index: 100;
  position: static;
  margin-left: 3rem;
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.188rem;
  color: ${({ theme }) => theme.colors.textGrey};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        margin-left: 1rem;
      `
    )}
`;

export default {
  Container,
  Circle,
  Dashboard,
  Footer,
};
