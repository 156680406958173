import styled, { css } from "styled-components";

import { ReactComponent as Arrow } from "assets/images/icons/arrow.svg";
import { hoverTransitionAll } from "shared/theme/styles";

import { Translate } from "../../Translate";

interface IDetails {
  disabled: boolean;
  isOpen: boolean;
  onClick: () => void;
}

const Container = styled.button<{ isOpen: boolean }>`
  border: none;
  outline: none;
  display: flex;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  font-style: normal;
  grid-gap: 0.458rem;
  padding: 0 0.25rem;
  align-items: center;
  line-height: 1.188rem;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.transparent};

  & > svg {
    ${hoverTransitionAll};
  }

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      color: ${theme.colors.blue};
      & > svg {
        transform: rotateX(180deg);
        ${hoverTransitionAll};
        path {
          fill: ${theme.colors.blue};
        }
      }
    `}

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.disabledButton};

    & > svg {
      path {
        fill: ${({ theme }) => theme.colors.disabledButton};
      }
    }
  }
`;

const ButtonDetails: React.FC<IDetails> = ({ disabled, isOpen, onClick }: IDetails) => {
  return (
    <Container disabled={disabled} isOpen={isOpen} onClick={onClick}>
      <Translate value="detail" />
      <Arrow />
    </Container>
  );
};

export default ButtonDetails;
