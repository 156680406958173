import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow.svg";
import { ReactComponent as WalletIcon } from "assets/images/icons/wallet.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { LockupSwitcher } from "shared/components/LockupsBlock/components/LockupSwitcher";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { cropString } from "shared/utils/cropString";
import { EModals, showModal } from "store/slices/modals";

import { IDesktopHeader } from "./interface";
import styles from "./styles";

const DesktopHeader: React.FC<IDesktopHeader> = ({ accountId }: IDesktopHeader) => {
  const { signOut } = useWalletSelector();
  const dispatch = useAppDispatch();
  return (
    <styles.Container>
      <LockupSwitcher />
      <ButtonPrimary
        name={cropString(accountId)}
        handler={() => dispatch(showModal({ modal: EModals.ACCOUNT_MODAL, props: { signOut } }))}
        iconLeft={<WalletIcon />}
        iconRight={<ArrowIcon />}
        color={EButtonColors.WALLET}
      />
    </styles.Container>
  );
};
export default DesktopHeader;
