import styled from "styled-components";

const Container = styled.div<{ colorBG: string }>`
  gap: 0.25rem;
  display: flex;
  font-weight: 400;
  font-size: 0.75rem;
  flex-direction: row;
  align-items: center;
  border-radius: 0.5rem;
  line-height: 0.938rem;
  padding: 0.375rem 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ colorBG }) => colorBG};
`;

export default {
  Container,
};
