import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ELockupDisplayedType, ELockupStage, ILockup } from "shared/interfaces";
import { RootState } from "store";

export type LockupsState = {
  incoming: { [key: number]: ILockup };
  outgoing: { [key: string]: ILockup };
  lockupDisplayedType: ELockupDisplayedType;
  loading: boolean;
};

const initialState: LockupsState = {
  incoming: {},
  outgoing: {},
  lockupDisplayedType: ELockupDisplayedType.SHOW_INCOMING,
  loading: true,
};

const lockupsSlice = createSlice({
  name: "lockups",
  initialState,
  reducers: {
    setIncomingLockups: (state, action: PayloadAction<{ [key: number]: ILockup }>) => {
      return {
        ...state,
        incoming: {
          ...state.incoming,
          ...action.payload,
        },
      };
    },
    setOutgoingLockups: (state, action: PayloadAction<{ [key: string]: ILockup }>) => {
      return {
        ...state,
        outgoing: {
          ...state.outgoing,
          ...action.payload,
        },
      };
    },
    setDisplayedLockups: (state, action: PayloadAction<ELockupDisplayedType>) => {
      return {
        ...state,
        lockupDisplayedType: action.payload,
      };
    },
    setLockupsLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    updateIncomingLockup: (state, action: PayloadAction<{ lockupId: number; lockup: ILockup }>) => {
      return {
        ...state,
        incoming: {
          ...state.incoming,
          [action.payload.lockupId]: action.payload.lockup,
        },
      };
    },
    updateOutgoingLockup: (state, action: PayloadAction<{ lockupId: string; lockup: ILockup }>) => {
      return {
        ...state,
        outgoing: {
          ...state.outgoing,
          [action.payload.lockupId]: action.payload.lockup,
        },
      };
    },
    updateIncomingLockupStage: (state, action: PayloadAction<{ lockupId: number; stage: ELockupStage }>) => {
      const lockupItems = state.incoming[action.payload.lockupId];
      if (lockupItems.stage === action.payload.stage) return;
      return {
        ...state,
        incoming: {
          ...state.incoming,
          [action.payload.lockupId]: {
            ...lockupItems,
            stage: action.payload.stage,
          },
        },
      };
    },
    updateOutgoingLockupStage: (state, action: PayloadAction<{ lockupId: string; stage: ELockupStage }>) => {
      const lockupItems = state.outgoing[action.payload.lockupId];
      if (lockupItems.stage === action.payload.stage) return;
      return {
        ...state,
        outgoing: {
          ...state.outgoing,
          [action.payload.lockupId]: {
            ...lockupItems,
            stage: action.payload.stage,
          },
        },
      };
    },
  },
});

// SELECTORS
export const selectLockups = (state: RootState) => state.lockups;
export const selectLockupDisplayedType = (state: RootState) => state.lockups.lockupDisplayedType;
export const selectLockupsLoading = (state: RootState) => state.lockups.loading;

// ACTIONS
export const {
  setIncomingLockups,
  setOutgoingLockups,
  setDisplayedLockups,
  setLockupsLoading,
  updateIncomingLockup,
  updateOutgoingLockup,
  updateIncomingLockupStage,
  updateOutgoingLockupStage,
} = lockupsSlice.actions;

export const lockupsReducer = lockupsSlice.reducer;
