import { ELockupStage } from "shared/interfaces";

export interface ILockupContract {
  getLockupsCount(): Promise<number | undefined>;
  getMetadata(): Promise<ContractMetadataOutput | undefined>;
  getLockups({ from, limit }: { from: number; limit: number }): Promise<LockupOutput | undefined>;
  getLockup({ lockupId }: { lockupId: number }): Promise<LockupOutput | undefined>;
}

export enum LockupViewMethods {
  getLockupsCount = "get_lockups_count", // (&self)
  getLockups = "get_lockups", // (&self, from_index: u64, limit: u64)
  getLockup = "get_lockup", // (&self, lockup_id: u64)
  getMetadata = "metadata",
}

export enum LockupChangeMethods {
  claim = "claim", // (&self, lockup_id: u64)
  terminate = "terminate", // (&self, lockup_id: u64)
}

export interface ContractMetadataOutput {
  version: string;
  user_id: string;
}

export interface LockupTerminationOutput {
  claim_amount: string;
  claimed_amount: string;
  withdraw_amount: string;
  withdrawn_amount: string;
}

export interface LockupCheckpointOutput {
  end_date: number;
  amount: string;
}

export interface LockupOutput {
  lockup_id: number;
  stage: ELockupStage;
  owner_id: string;
  creator_id: string;
  claimed_amount: string;
  distribution_amount: string;
  distribution_token_id: string;
  distribution_schedule: LockupCheckpointOutput[];
  termination_info: LockupTerminationOutput | null;
  cliff: number;
  start_at: number;
  can_terminate: boolean;
}
