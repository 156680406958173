import { AnimatePresence, motion } from "framer-motion";

import { slideDown } from "shared/theme/animations/slideDown";

import { ISlideDown } from "./interfaces";

const SlideDown: React.FC<ISlideDown> = ({ show, children }: ISlideDown) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div initial="initial" animate="animate" exit="exit" variants={slideDown}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SlideDown;
