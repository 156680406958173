import { IButton, IControl } from "shared/interfaces";

export enum EButtonColors {
  BLUE = "BLUE",
  DARK_BLUE = "DARK_BLUE",
  GREEN = "GREEN",
  DARK_GREEN = "DARK_GREEN",
  GREY = "GREY",
  DARK_GREY = "DARK_GREY",
  PINK = "PINK",
  DARK_PINK = "DARK_PINK",
  WALLET = "WALLET",
  TRANSPARENT = "TRANSPARENT",
}

export interface IButtonStatusColors {
  default: string;
  active: string;
  hover: string;
  text: string;
}

export interface IButtonProps {
  name: string;
  handler: () => void;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  fullWidth?: boolean;
  color?: EButtonColors;
  disabled?: boolean;
}

export interface IButtonIconProps {
  control?: IControl;
}

export interface IButtonFABProps extends IButton {
  isOpened: boolean;
  iconToOpen: JSX.Element;
  loading: boolean;
}
