import { AccountView } from "near-api-js/lib/providers/provider";

import { FactoryMethods } from "services/contracts/FactoryContract/interfaces";
import { FTViewMethod } from "services/contracts/FungibleToken/interfaces";
import { LockupViewMethods, LockupOutput, ContractMetadataOutput } from "services/contracts/LockupContract/interfaces";
import { ITokenMetadata } from "shared/interfaces";

export interface ViewArgs {
  from_index?: number;
  limit?: number;
  account_id?: string;
  owner_id?: string;
  lockup_id?: number;
}

export interface IRPCProviderService {
  viewFunction: <T extends keyof ViewFunction>(
    method: T,
    accountId: string,
    args?: ViewArgs
  ) => Promise<ViewFunction[T] | undefined>;
  viewAccount: (accountId: string) => Promise<AccountView | undefined>;
}

export enum RPCProviderMethods {
  CALL_FUNCTION = "call_function",
  VIEW_ACCOUNT = "view_account",
}

export type ViewFunction = {
  //Factory
  [FactoryMethods.getLockupsContractsCount]: Promise<number | undefined>;
  [FactoryMethods.getLockupsContracts]: Promise<{ [key: string]: string }>;
  [FactoryMethods.getLockupContractId]: Promise<string | undefined>;
  [FactoryMethods.getLockupsByOwner]: Promise<{ [key: string]: number[] }>;
  [FactoryMethods.getLockupsByOwnerCount]: Promise<number | undefined>;
  //Lockups
  [LockupViewMethods.getLockupsCount]: Promise<number | undefined>;
  [LockupViewMethods.getMetadata]: Promise<ContractMetadataOutput | undefined>;
  [LockupViewMethods.getLockups]: Promise<LockupOutput | undefined>;
  [LockupViewMethods.getLockup]: Promise<LockupOutput | undefined>;
  //fungible token
  [FTViewMethod.ftMetadata]: Promise<ITokenMetadata | null>;
  [FTViewMethod.ftBalanceOf]: Promise<string | undefined>;
  [FTViewMethod.storageBalanceBounds]: Promise<{ min: string; max: string }>;
  [FTViewMethod.storageBalanceOf]: Promise<{ total: string; available: string }>;
};
