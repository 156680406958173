import { z } from "zod";

import i18n from "services/translation";
import { ZERO_STRING, EMPTY_STRING } from "shared/constants";
import { IBalance } from "shared/interfaces";
import { BigNumber, formatUnits } from "shared/utils/calculations";
import { getCurrentTimestamp, getDateInSeconds } from "shared/utils/dateOperations";

export const validateBalance = (
  stateBalance: IBalance | null,
  schedule: {
    endDateISOFormat: string;
    amount: string;
  }[],
  ctx: z.RefinementCtx
) => {
  if (!stateBalance) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t("createLockup.schedule.invalidBalance") || EMPTY_STRING,
      path: ["schedule.0.amount"],
    });
    return z.INVALID;
  }

  const { balance, decimal } = stateBalance;
  const readableBalance = formatUnits(balance, decimal);
  const sumAmount = schedule.reduce((acc, item, index) => {
    const sum = BigNumber(item.amount).plus(acc).toFixed();
    if (BigNumber(sum).gt(readableBalance)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("createLockup.schedule.sumLockAmount") || EMPTY_STRING,
        path: [`schedule.${index}.amount`],
      });
    }
    return sum;
  }, ZERO_STRING);
  if (BigNumber(sumAmount).gt(readableBalance)) return z.INVALID;
};

export const validateDate = (
  schedule: {
    endDateISOFormat: string;
    amount: string;
  }[],
  startDateISOFormat: string,
  ctx: z.RefinementCtx
) => {
  const currentTimeStamp = getCurrentTimestamp();
  const validDate = schedule.every((item, index) => {
    const date = getDateInSeconds(item.endDateISOFormat);
    const previousDate = index == 0 ? startDateISOFormat : schedule[index - 1].endDateISOFormat;
    if (currentTimeStamp > date) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("createLockup.schedule.invalidUnlockDate") || EMPTY_STRING,
        path: [`schedule.${index}.endDateISOFormat`],
      });
      return false;
    }
    const previousDateNumber = getDateInSeconds(previousDate);
    if (previousDateNumber > date) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("createLockup.schedule.invalidUnlockDate") || EMPTY_STRING,
        path: [`schedule.${index}.endDateISOFormat`],
      });
      return false;
    } else {
      return true;
    }
  });
  if (!validDate) return z.INVALID;
};

export const validateCliff = (
  schedule: {
    endDateISOFormat: string;
    amount: string;
  }[],
  startDateISOFormat: string,
  cliffISOFormat: string,
  ctx: z.RefinementCtx
) => {
  if (cliffISOFormat.length === 0) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t("createLockup.cliff.emptyDate") || EMPTY_STRING,
      path: ["cliffISOFormat"],
    });
  }
  const endDateISOFormat = schedule[schedule.length - 1].endDateISOFormat;
  const startDate = getDateInSeconds(startDateISOFormat);
  const endDate = getDateInSeconds(endDateISOFormat);
  const cliff = getDateInSeconds(cliffISOFormat);
  if (startDate >= cliff) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t("createLockup.cliff.lessStartDate") || EMPTY_STRING,
      path: ["cliffISOFormat"],
    });
  }
  if (endDate <= cliff) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t("createLockup.cliff.greaterEndDate") || EMPTY_STRING,
      path: ["cliffISOFormat"],
    });
  }
};

export const validateStartDate = (
  schedule: {
    endDateISOFormat: string;
    amount: string;
  }[],
  startDateISOFormat: string,
  ctx: z.RefinementCtx
) => {
  const endDateFirstCheckpoint = schedule[0].endDateISOFormat || null;
  if (!endDateFirstCheckpoint) return;
  const endDate = getDateInSeconds(endDateFirstCheckpoint);
  const startDate = getDateInSeconds(startDateISOFormat);
  if (startDate >= endDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: i18n.t("createLockup.schedule.invalidStartDate") || EMPTY_STRING,
      path: ["startDateISOFormat"],
    });
  }
};
