import styled, { css } from "styled-components";

import { ReactComponent as Plus } from "assets/images/icons/plus.svg";
import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const Form = styled.form`
  ${flexColumnNoWrap}
  padding: 0 1rem;
`;

const WrapperPeriods = styled.div`
  ${flexColumnNoWrap}
  flex-shrink: 0;
  gap: 1rem;
`;

const CheckboxContainer = styled.div`
  ${flexColumnNoWrap}
  flex-shrink: 0;
  margin-bottom: 3rem;
  padding: 0;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 0;
      `
    )}
`;

const Buttons = styled.div`
  bottom: 0;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  position: sticky;
  padding: 2rem 1rem;
  background-color: ${({ theme }) => theme.colors.cardBg};

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 2rem 0 1rem 0;
      `
    )}
`;

const AddPeriod = styled.div`
  ${flexRowNoWrap}
  color: ${({ theme }) => theme.colors.blue};
  align-items: center;
  align-self: center;
  gap: 0.5rem;
  margin-top: 1.875rem;
  cursor: pointer;
  width: fit-content;
  :hover {
    color: ${({ theme }) => theme.buttons.blueHover};
  }
  :active {
    color: ${({ theme }) => theme.buttons.blueActive};
  }
`;

export default {
  Form,
  WrapperPeriods,
  CheckboxContainer,
  Buttons,
  AddPeriod,
  Plus,
};
