import styled from "styled-components";

import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow.svg";
import { flexRowNoWrap, hoverTransitionAll } from "shared/theme/styles";

const Container = styled.div`
  ${flexRowNoWrap}
  position: relative;
  width: 100%;
`;

const Row = styled.div`
  ${flexRowNoWrap}
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  padding: 0 1.125rem 0 0.5rem;
`;

const SelectTokenContainer = styled.div<{ disabledSelect: boolean }>`
  ${flexRowNoWrap}
  align-items: center;
  gap: 0.25rem;
  cursor: ${({ disabledSelect }) => (disabledSelect ? "default" : "pointer")};
  & > svg {
    width: 0.875rem;
    height: 0.5rem;
    ${hoverTransitionAll};
  }
  :hover {
    & > svg {
      transform: translateY(30%);
      ${hoverTransitionAll};
    }
  }
`;

const DataList = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  max-height: 169px;
  overflow: auto;
  z-index: 100;

  background: ${({ theme }) => theme.colors.tokenListBg};
  box-shadow: 0px 4px 12px -4px ${({ theme }) => theme.colors.boxShadowCard};
  border-radius: 1rem;
  padding: 0.5rem;
  & > div {
    padding: 0.5rem;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default {
  Container,
  Row,
  DataList,
  ArrowIcon,
  SelectTokenContainer,
};
