import styled from "styled-components";

import { hoverTransition } from "shared/theme/styles";

const LockupSwitcherWrapper = styled.div<{ withBottomIndent?: boolean }>`
  margin-left: 0.875rem;
  margin-bottom: ${({ withBottomIndent }) => (withBottomIndent ? "1.875rem" : "0")};
`;

const SwitcherItem = styled.button<{ isActive: boolean }>`
  border: 0;
  padding: 0;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  margin-right: 1rem;
  font-size: 0.875rem;
  line-height: 1.4rem;
  background-color: transparent;
  transition: ${hoverTransition};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.blue : theme.colors.textGrey)};

  &:after {
    content: "";
    left: 50%;
    height: 0.125rem;
    position: absolute;
    display: inline-block;
    top: calc(100% + 0.25rem);
    transform: translateX(-50%);
    border-bottom-left-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
    background-color: ${({ theme }) => theme.colors.blue};
    width: ${({ isActive }) => (isActive ? "1.6875rem" : 0)};
    transition: ${hoverTransition};
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.blue};

    &:after {
      width: 1.6875rem;
    }
  }
`;

export default {
  LockupSwitcherWrapper,
  SwitcherItem,
};
