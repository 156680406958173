import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import TokenList from "shared/components/TokenList";
import TokenLogo from "shared/components/TokenLogo";
import { TOKEN_KEY } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import useOnClickOutside from "shared/hooks/useOnClickOutside";
import { IAutocomplete } from "shared/interfaces";
import { generateTokenList } from "shared/utils";
import { selectData } from "store/selectors/selectData";

import styles from "./styles";

const Autocomplete: React.FC<IAutocomplete> = ({ children, metadata, disabled }: IAutocomplete) => {
  const {
    tokens,
    prices,
    user: { balances },
  } = useAppSelector(selectData);
  const tokensList = generateTokenList(balances, prices, tokens);
  const { setValue } = useFormContext();
  const node = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const handleClick = () => {
    if (disabled) return;
    setFocused(!focused);
  };
  const handleToken = (tokenAddress: string) => {
    setValue(TOKEN_KEY, tokenAddress);
    setFocused(false);
  };
  const showDataList = focused && tokensList.length !== 0;
  useOnClickOutside(node, focused ? handleClick : undefined);
  return (
    <styles.Container ref={node}>
      <styles.Row>
        <TokenLogo icon={metadata.icon} alt={metadata.name} />
        {children}
        <styles.SelectTokenContainer disabledSelect={disabled || !tokensList.length} onClick={handleClick}>
          {metadata.symbol}
          {!disabled && !!tokensList.length && <styles.ArrowIcon />}
        </styles.SelectTokenContainer>
      </styles.Row>
      {showDataList && (
        <styles.DataList>
          <TokenList list={tokensList} handleSelectToken={handleToken} />
        </styles.DataList>
      )}
    </styles.Container>
  );
};

export default Autocomplete;
