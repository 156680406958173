import styled, { css } from "styled-components";

import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow.svg";
import { ReactComponent as WalletIcon } from "assets/images/icons/wallet.svg";
import { flexRowNoWrap } from "shared/theme/styles";

const Container = styled.header`
  ${flexRowNoWrap}
  padding: 1.5rem 3rem 2rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & > div {
    ${flexRowNoWrap}
    gap: 1rem;
    height: 2rem;
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 1.5rem 1rem 2rem;
      `
    )}
`;

export default {
  Container,
  ArrowIcon,
  WalletIcon,
};
