import styled from "styled-components";

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  width: 100%;
  input {
    padding: 0;
  }
`;

export default {
  DateWrapper,
};
