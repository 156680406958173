import styled, { css } from "styled-components";

import { ReactComponent as WalletIcon } from "assets/images/icons/wallet.svg";
import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const Container = styled.div`
  ${flexColumnNoWrap}
  background: #1C233E;
  box-shadow: 0px 4px 12px -4px rgba(20, 25, 49, 0.2);
  border-radius: 1.5rem;
  flex-shrink: 0;
  gap: 1.5rem;
  width: 100%;
  padding: 1rem;
`;

const Header = styled.div`
  ${flexRowNoWrap}
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: ${({ theme }) => theme.colors.white};
`;

const Row = styled.div`
  ${flexRowNoWrap}
  justify-content: space-between;
  gap: 0.75rem;
  width: 100%;

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        ${flexColumnNoWrap}
        gap: 1.5rem;
      `
    )}
`;

const Wallet = styled(WalletIcon)`
  margin-right: 0.281rem;
  path {
    fill: ${({ theme }) => theme.colors.textGrey};
  }
`;

const DeletePeriod = styled.button`
  background: ${({ theme }) => theme.colors.transparent};
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.pink};
  cursor: pointer;
  width: fit-content;
  :hover {
    color: ${({ theme }) => theme.buttons.pinkHover};
  }
  :active {
    color: ${({ theme }) => theme.buttons.pinkActive};
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export default {
  Container,
  Header,
  Title,
  Row,
  Wallet,
  DeletePeriod,
};
