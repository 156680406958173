import styled from "styled-components";

const Title = styled.p`
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.188rem;
  text-align: center;
`;

export default {
  Title,
};
