import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { ReactComponent as Arrow } from "assets/images/icons/arrow-right.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { walletDomains } from "services/config";
import { ButtonsGroup } from "shared/components/Buttons/ButtonsGroup";
import { Input } from "shared/components/Input";
import { Translate } from "shared/components/Translate";
import { ZERO_STRING } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { IFirstStageData } from "shared/interfaces";
import { firstStageSchema } from "shared/utils";
import { getOrCreateLockupContractId } from "shared/utils/getOrCreateLockupContractId";
import { selectCreateLockupData, setFirstStageData, increaseStage } from "store/slices/createLockup";
import { closeModal } from "store/slices/modals";

import styles from "./styles";
import WrapperInputStyles from "../components/WrapperInputStyles";

const FirstStageForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { RPCProvider } = useWalletSelector();
  const { firstStage } = useAppSelector(selectCreateLockupData);
  const methods = useForm<IFirstStageData>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: zodResolver(firstStageSchema),
    defaultValues: firstStage,
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<IFirstStageData> = async (values) => {
    try {
      if (isEqual(values, firstStage)) {
        dispatch(increaseStage());
        return;
      }

      const receiverLockupContract = await getOrCreateLockupContractId(values.receiver, RPCProvider);

      dispatch(
        setFirstStageData({
          ...values,
          receiver: values.receiver.toLowerCase(),
          receiverLockupContract: receiverLockupContract,
        })
      );
    } catch (error) {
      console.error(`Error on first step of creating lockup \n ${error}`);
    }
  };
  return (
    <FormProvider {...methods}>
      <styles.Form onSubmit={(e) => e.preventDefault()}>
        <WrapperInputStyles.Container>
          <WrapperInputStyles.Label>
            <div>
              <Translate value="createLockup.receiver.title" />
              <span>*</span>
            </div>
          </WrapperInputStyles.Label>
          <WrapperInputStyles.Border error={Boolean(errors.receiver)}>
            <Input
              type="text"
              placeholder={t("createLockup.receiver.placeholder", { walletDomain: walletDomains[0] }) || ZERO_STRING}
              id="receiver"
            />
          </WrapperInputStyles.Border>
          <WrapperInputStyles.Error>{errors.receiver?.message}</WrapperInputStyles.Error>
        </WrapperInputStyles.Container>
        <styles.Buttons>
          <ButtonsGroup
            cancelButtonHandler={() => dispatch(closeModal())}
            rightButton={{
              name: t("actions.next"),
              handler: handleSubmit(onSubmit),
              iconRight: <Arrow />,
              disabled: !isEmpty(errors),
            }}
          />
        </styles.Buttons>
      </styles.Form>
    </FormProvider>
  );
};

export default FirstStageForm;
