import styled from "styled-components";

import { flexColumnNoWrap } from "shared/theme/styles";

const Container = styled.div`
  ${flexColumnNoWrap}
  gap: 1rem;
  flex: 1;
  & > button {
    width: 100%;
  }
`;

export default {
  Container,
};
