import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { wNearAddress } from "services/config";
import { EMPTY_STRING } from "shared/constants";
import { ECreateLockupStage, IFirstStageData, ISecondStageData } from "shared/interfaces";
import { RootState } from "store";

export interface ICreateLockupState {
  stage: ECreateLockupStage;
  firstStage: IFirstStageData;
  secondStage: ISecondStageData;
}

export const firstStageInitialData: IFirstStageData = {
  receiver: "",
  receiverLockupContract: { id: "", isDeployed: false },
  note: "",
  comment: "",
  thirdPartyOwner: "",
};

export const secondStageInitialData: ISecondStageData = {
  canTerminate: true,
  token: wNearAddress,
  schedule: [],
  cliff: null,
  enableCliff: false,
  cliffISOFormat: EMPTY_STRING,
  startDate: null,
  startDateISOFormat: EMPTY_STRING,
  isFullFiled: false,
};

const initialState: ICreateLockupState = {
  stage: ECreateLockupStage.FIRST,
  firstStage: firstStageInitialData,
  secondStage: secondStageInitialData,
};

const createLockupSlice = createSlice({
  name: "createLockup",
  initialState,
  reducers: {
    decreaseStage: (state) => {
      if (state.stage <= ECreateLockupStage.FIRST) return;
      return {
        ...state,
        stage: state.stage - 1,
      };
    },
    increaseStage: (state) => {
      if (state.stage >= ECreateLockupStage.THIRD) return;
      return {
        ...state,
        stage: state.stage + 1,
      };
    },
    setFirstStageData: (state, action: PayloadAction<IFirstStageData>) => ({
      ...state,
      firstStage: action.payload,
      stage: ECreateLockupStage.SECOND,
    }),
    setSecondStageData: (state, action: PayloadAction<ISecondStageData>) => ({
      ...state,
      secondStage: action.payload,
      stage: ECreateLockupStage.THIRD,
    }),
    setCreateLockupInitialData: () => initialState,
  },
});

// SELECTORS
export const selectCreateLockupData = (state: RootState) => state.createLockup;

// ACTIONS
export const { decreaseStage, increaseStage, setFirstStageData, setSecondStageData, setCreateLockupInitialData } =
  createLockupSlice.actions;

export const createLockupReducer = createLockupSlice.reducer;
