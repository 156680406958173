import { createSelector } from "@reduxjs/toolkit";

import { ILockup } from "shared/interfaces";
import { toArray } from "shared/utils";
import { selectLockups } from "store/slices/lockups";
import { selectPrices } from "store/slices/prices";
import { selectTokens } from "store/slices/tokens";
import { selectUser } from "store/slices/user";

import { selectLoading } from "./selectLoading";

export const selectData = createSelector(
  [selectLockups, selectTokens, selectUser, selectPrices, selectLoading],
  (lockups, tokens, user, prices, loading) => ({
    user,
    lockups: {
      lockupDisplayedType: lockups.lockupDisplayedType,
      incoming: {
        arr: toArray(lockups.incoming) as ILockup[],
        obj: lockups.incoming,
      },
      outgoing: {
        arr: toArray(lockups.outgoing) as ILockup[],
        obj: lockups.outgoing,
      },
    },
    tokens,
    prices,
    loading,
  })
);
