import styled from "styled-components";

import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const Container = styled.div`
  ${flexColumnNoWrap}
  background: ${({ theme }) => theme.colors.cardBg};
  box-shadow: 0px 4px 12px -4px ${({ theme }) => theme.colors.boxShadowCard};
  border-radius: 1.5rem;
  padding: 1rem;
`;

const Row = styled.div`
  ${flexRowNoWrap}
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Column = styled.div`
  ${flexColumnNoWrap}
  grid-gap: .25rem;
`;

const Title = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.938rem;
  color: ${({ theme }) => theme.colors.textGrey};
`;

const Label = styled.p`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.188rem;
  color: ${({ theme }) => theme.colors.white};
`;

export default {
  Container,
  Row,
  Column,
  Title,
  Label,
};
