import { createAsyncThunk } from "@reduxjs/toolkit";

import { IRPCProviderService } from "services/RPCProviderService";
import { retrieveTokensMetadata } from "shared/helpers/tokens";
import { setTokens, setTokensLoading } from "store/slices/tokens";

import { loadBalances } from "./loadBalance";
import { RootState } from "../index";

export const loadTokens = createAsyncThunk<
  void,
  {
    tokenAddresses: string[];
    provider: IRPCProviderService;
  },
  { state: RootState }
>("lockups/loadTokens", async ({ tokenAddresses, provider }, { dispatch, getState }) => {
  try {
    if (!tokenAddresses.length) return;
    const state = getState();
    const accountId = state.user.id;
    const tokens = await retrieveTokensMetadata(tokenAddresses, provider, accountId);

    if (!tokens) return;

    dispatch(setTokens(tokens));
    dispatch(loadBalances({ tokens }));
  } catch (e) {
    console.error(`Error: while loading token metadata \n ${e}`);
  } finally {
    dispatch(setTokensLoading(false));
  }
});
