import { IFinancialStatus } from "./interface";
import styles from "./styles";
import { getStageData } from "./utils";

export function StageLabel({ type, value, stage, symbol, isFiatValue }: IFinancialStatus) {
  const stageData = getStageData(stage, type, value, symbol, isFiatValue);
  if (!stageData) return null;
  return (
    <styles.Container colorBG={stageData.labelBGColor}>
      {stageData.icon}
      {stageData.text}
    </styles.Container>
  );
}
