import { IConfig } from "./config";
import mainnet from "./env-defaults/mainnet";
import testnet from "./env-defaults/testnet";

type Environment = "mainnet" | "testnet";

const environments: { [key in Environment]: IConfig } = {
  mainnet: mainnet,
  testnet: testnet,
};

const currentEnvironment: Environment = (process.env.REACT_APP_NEAR_ENV as Environment) || "mainnet";

export const {
  networkId,
  nodeUrl,
  walletUrl,
  helperUrl,
  explorerUrl,
  contractId,
  wNearAddress,
  usn,
  myNearWalletUrl,
  refFinanceIndexerUrl,
  jumboExchangeIndexerUrl,
  walletDomains,
  defaultTokens,
}: IConfig = {
  ...environments[currentEnvironment],
};
