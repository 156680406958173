import FungibleTokenContract from "services/contracts/FungibleToken";
import { NEAR_TOKEN_ID, ZERO_STRING } from "shared/constants";
import { IBalance, ITokenList, ITokenPrice } from "shared/interfaces";

import { isNotNullOrUndefined } from ".";
import { BigNumber, formatUnits } from "./calculations";

const generateTokenLost = (
  balances: { [key: string]: IBalance },
  prices: { [key: string]: ITokenPrice },
  tokens: { [key: string]: FungibleTokenContract }
): ITokenList[] => {
  const tokensArray = Object.values(balances)
    .map(({ address, balance, decimal }) => {
      const token = tokens[address] || null;
      const price = prices[address]?.price || ZERO_STRING;
      const inValidBalance = BigNumber(balance).eq(ZERO_STRING);
      if (inValidBalance || !token || address === NEAR_TOKEN_ID) return null;
      const value = formatUnits(balance, decimal);
      return {
        token,
        value,
        fiatValue: BigNumber(price).times(value).toFixed(),
      };
    })
    .filter(isNotNullOrUndefined);
  return tokensArray;
};

export default generateTokenLost;
