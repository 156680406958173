import { Trans } from "react-i18next";

import styles from "./styles";

const gitHubLink = "https://github.com/lockup-near";

export default function Footer() {
  return (
    <styles.Footer>
      <p>
        <Trans i18nKey="footer" values={{ year: new Date().getUTCFullYear() }} />
      </p>
      <styles.Link href={gitHubLink} target="_blank" rel="noreferrer">
        <styles.GitHub />
        <p>
          <Trans i18nKey="landing.gitHub" />
        </p>
      </styles.Link>
    </styles.Footer>
  );
}
