import { t } from "i18next";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Arrow } from "assets/images/icons/arrow-diagonal.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { APP_ROUTES } from "routes/appRoutes";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { Translate } from "shared/components/Translate";
import { EDimensions } from "shared/constants";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { defaultVariant } from "shared/theme/animations/landing";

import Card from "./Card";
import styles from "./styles";

export default function Main() {
  const { isSignedIn, openModal } = useWalletSelector();
  const navigate = useNavigate();

  const onClick = useCallback(
    () => (isSignedIn() ? navigate(APP_ROUTES.APP) : openModal()),
    [isSignedIn, navigate, openModal]
  );
  const dimensions = useWindowDimensions();
  const showOpenApp = dimensions === EDimensions.SMALL;

  return (
    <styles.Section variants={defaultVariant} initial="initial" animate="animate">
      <styles.Title clearMargin={showOpenApp}>
        <h1>
          <Translate value="landing.mainTitle" />
        </h1>
        <p>
          <Translate value="landing.mainDescription" />
        </p>
      </styles.Title>
      {showOpenApp && (
        <styles.WrapperButton>
          <ButtonPrimary
            fullWidth
            name={isSignedIn() ? t("landing.openApp") : t("actions.connectWallet")}
            handler={onClick}
            iconRight={isSignedIn() ? <Arrow /> : undefined}
            color={isSignedIn() ? undefined : EButtonColors.DARK_BLUE}
          />
        </styles.WrapperButton>
      )}
      <Card />
    </styles.Section>
  );
}
