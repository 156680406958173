import styled from "styled-components";

import { flexColumnNoWrap, flexRowNoWrap, hideScrollBar } from "shared/theme/styles";

const Container = styled.main`
  ${flexRowNoWrap}
  min-height: 100vh;
  width: 100%;
  gap: ${({ theme }) => theme.width.distanceBetweenSideMenuAndItem};
`;

const Column = styled.div`
  ${flexColumnNoWrap}
  width: 100%;
`;

const SideComponent = styled.div`
  width: ${({ theme }) => theme.width.sideMenu};
  background-color: ${({ theme }) => theme.colors.sideBg};
  padding: 4.625rem 1rem 3rem;
  height: 100vh;
  position: fixed;
  top: 0;
  flex-shrink: 0;
  overflow-x: auto;
  ${hideScrollBar}
  & > div:first-child {
    position: fixed;
    top: 22px;
    left: 32px;
    z-index: 100;
  }
  ::before {
    position: fixed;
    top: 0;
    left: 0;
    content: "";
    height: 3.75rem;
    width: 18.75rem;
    backdrop-filter: blur(5px);
    z-index: 10;
  }
  ::after {
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    content: "";
    height: 2.5rem;
    width: 18.75rem;
    backdrop-filter: blur(5px);
  }
`;

const SidePlaceholder = styled.div`
  width: ${({ theme }) => theme.width.sideMenu};
  height: 100vh;
  flex-shrink: 0;
  z-index: -1;
`;

const Footer = styled.footer`
  z-index: 100;
  position: fixed;
  left: 32px;
  bottom: 16px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.188rem;
  color: ${({ theme }) => theme.colors.textGrey};
`;

export default {
  Container,
  SideComponent,
  SidePlaceholder,
  Column,
  Footer,
};
