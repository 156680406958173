import { toast } from "react-toastify";

import { toastOptions } from "shared/theme/options";

import { errorContent, successContent } from "./Content";

export default class ToastService {
  static success({ text, additionalText }: { text: string; additionalText?: string | null }): void {
    const content = successContent(text, additionalText);
    toast(content, toastOptions);
  }

  static error({ text, href }: { text: string; href?: string }): void {
    const content = errorContent(text, href);
    toast(content, toastOptions);
  }
}
