import { ISecondStageData } from "shared/interfaces";

import { getDateInSeconds } from "./dateOperations";

export const SCHEDULE_KEY = "schedule";

export const formatSecondStage = (values: ISecondStageData, isUnterminated: boolean): ISecondStageData => {
  const schedule = values.schedule.map((item) => {
    const endDate = getDateInSeconds(item.endDateISOFormat);
    return { ...item, endDate };
  });
  const startDate = getDateInSeconds(values.startDateISOFormat);
  const cliff = values.enableCliff ? getDateInSeconds(values.cliffISOFormat) : null;

  return {
    ...values,
    startDate,
    schedule,
    cliff,
    canTerminate: !isUnterminated,
    isFullFiled: true,
  };
};
