import { t } from "i18next";
import React from "react";

import { CREATE_LOCKUP_MODAL_WIDTH_IN_REM } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { ECreateLockupStage, IModalProps } from "shared/interfaces";
import { selectCreateLockupData } from "store/slices/createLockup";

import { getHeaderControls } from "./helpers";
import styles from "./styles";
import { ModalControlsHeader } from "../components/ModalControlsHeader";
import { ModalStageSwitcher } from "../components/ModalStageSwitcher";
import { StepperProgress } from "../components/StepperProgress";
import ModalWrapper from "../ModalWrapper";

export const CreateLockupModal: React.FC<IModalProps> = ({ closeModal }: IModalProps) => {
  const { stage } = useAppSelector(selectCreateLockupData);
  const totalSteps = Object.keys(ECreateLockupStage).length / 2;
  const headerControls = getHeaderControls(stage, closeModal);

  return (
    <styles.CreateLockupWrapper>
      <ModalWrapper closeModal={closeModal} widthInRem={CREATE_LOCKUP_MODAL_WIDTH_IN_REM}>
        <styles.HeaderStepper>
          <ModalControlsHeader
            title={t("modals.createLockup.title")}
            leftControl={headerControls?.left}
            rightControl={headerControls.right}
          />
          <StepperProgress currentStep={stage} totalSteps={totalSteps} />
        </styles.HeaderStepper>
        <ModalStageSwitcher stage={stage} />
      </ModalWrapper>
    </styles.CreateLockupWrapper>
  );
};
