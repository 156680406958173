import { FinalExecutionOutcome } from "@near-wallet-selector/core";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";

import { contractId } from "services/config";
import { FactoryContract } from "services/contracts";
import RPCProviderService from "services/RPCProviderService";
import ToastService from "shared/components/Toast";
import { ITransaction } from "shared/interfaces";
import { RootState } from "store";

import { updateState } from "./updateState";

export const deployContract = createAsyncThunk<
  void,
  {
    provider: RPCProviderService;
    requestSignTransactions: (t: ITransaction[]) => Promise<void | FinalExecutionOutcome[]>;
  },
  { state: RootState }
>("lockups/deployContract", async ({ provider, requestSignTransactions }, { getState, dispatch }) => {
  const { user } = getState();
  try {
    const factory = new FactoryContract({ provider, contractId });
    const transactions = FactoryContract.createDeployLockupContractTransaction(factory.contractId, user.id);
    const result = await requestSignTransactions([transactions]);
    if (result) dispatch(updateState({ provider: provider, contractId }));
  } catch (error) {
    ToastService.error({ text: t("toast.error.createLockup") });
    console.error(`Error during deploy new contract for user ${user.id}: `, error);
  }
});
