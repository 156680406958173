import { Action, FinalExecutionOutcome, TransferAction } from "@near-wallet-selector/core";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";

import { contractId } from "services/config";
import { FactoryContract } from "services/contracts";
import FungibleTokenContract from "services/contracts/FungibleToken";
import LockupContract from "services/contracts/LockupContract";
import RPCProviderService from "services/RPCProviderService";
import ToastService from "shared/components/Toast";
import { RECEIVER_ID } from "shared/constants";
import { getToken } from "shared/helpers/tokens";
import { ITransaction, UpdatedFunctionCallAction } from "shared/interfaces";
import { calcLockupStorageFee } from "shared/utils/calculations";
import { setScheduleContractType } from "shared/utils/setScheduleContractType";
import { RootState } from "store";

import { updateState } from "./updateState";

export const createLockup = createAsyncThunk<
  void,
  {
    provider: RPCProviderService;
    requestSignTransactions: (t: ITransaction<Action>[]) => Promise<void | FinalExecutionOutcome[]>;
  },
  { state: RootState }
>("lockups/createLockup", async ({ provider, requestSignTransactions }, { getState, dispatch }) => {
  try {
    const factory = new FactoryContract({ provider, contractId });
    const {
      createLockup,
      user,
      tokens: { tokens },
    } = getState();
    const { receiver, thirdPartyOwner, receiverLockupContract } = createLockup.firstStage;
    const { token: tokenId, schedule, canTerminate, startDate, cliff } = createLockup.secondStage;
    const token = getToken(tokenId, tokens);
    if (!token) return;

    const lockupOwnerAccountId = thirdPartyOwner ? thirdPartyOwner : user.id;
    const scheduleContractTyped = setScheduleContractType(schedule, token.metadata.decimals);

    const transactions: ITransaction<UpdatedFunctionCallAction | TransferAction>[] = [];
    const createFtTransferArgs = (receiver: string) => ({
      receiver_id: receiver,
      amount: scheduleContractTyped[scheduleContractTyped.length - 1].amount,
      msg: LockupContract.generateCreateLockupMessage({
        canTerminate,
        schedule: scheduleContractTyped,
        ownerId: lockupOwnerAccountId,
        startAt: startDate,
        cliff,
      }),
    });

    if (receiverLockupContract.isDeployed) {
      const ftTransferArgs = createFtTransferArgs(receiverLockupContract.id);
      const ftTransferTransactions = await token.createFtTransferCallTransaction(
        receiverLockupContract.id,
        ftTransferArgs
      );

      if (!ftTransferTransactions) return;
      const storageFee = calcLockupStorageFee(scheduleContractTyped.length);
      const serviceCharge = FungibleTokenContract.createNearSend({
        receiverId: receiverLockupContract.id,
        amount: storageFee,
      });

      transactions.push(...ftTransferTransactions, serviceCharge);
    } else {
      const ftTransferArgs = createFtTransferArgs(receiverLockupContract.id);
      transactions.push(FactoryContract.createDeployLockupContractTransaction(factory.contractId, receiver));
      const ftTransferTransactions = await token.createFtTransferCallTransaction(
        receiverLockupContract.id,
        ftTransferArgs
      );
      if (!ftTransferTransactions) return;
      transactions.push(...ftTransferTransactions);
    }
    localStorage.setItem(RECEIVER_ID, receiver);
    const result = await requestSignTransactions(transactions);
    if (result) dispatch(updateState({ provider: provider, contractId }));
  } catch (error) {
    ToastService.error({ text: t("toast.error.createLockup") });
    console.error("Error during lockup creating: ", error);
  }
});
