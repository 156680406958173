import { Trans } from "react-i18next";

import { HUNDRED } from "shared/constants";

import { IStepperProgressProps } from "./interface";
import styles from "./styles";

export function StepperProgress({ totalSteps, currentStep }: IStepperProgressProps) {
  const progressInPercents =
    totalSteps === currentStep ? HUNDRED : `${Math.round((currentStep / totalSteps) * HUNDRED)}`;

  return (
    <styles.StepperProgressWrapper>
      <styles.StepOfSteps>
        <Trans i18nKey="modals.createLockup.stepOfSteps" values={{ currentStep, totalSteps }} />
      </styles.StepOfSteps>
      <styles.ProgressBar max={HUNDRED} value={progressInPercents} />
    </styles.StepperProgressWrapper>
  );
}
