import { IConfig } from "../config";

export default {
  networkId: "mainnet",
  nodeUrl: "https://rpc.mainnet.near.org",
  walletUrl: "https://wallet.near.org",
  helperUrl: "https://api.fastnear.com",
  explorerUrl: "https://explorer.mainnet.near.org",
  contractId: "factory-v1.lock-up.near",
  wNearAddress: "wrap.near",
  usn: "usn",
  myNearWalletUrl: "https://app.mynearwallet.com/",
  refFinanceIndexerUrl: "https://indexer.ref.finance",
  jumboExchangeIndexerUrl: "https://price-service.jumbo.exchange",
  walletDomains: [".near", ".tg"],
  defaultTokens: [
    "d9c2d319cd7e6177336b0a9c93c21cb48d84fb54.factory.bridge.near",
    "token.jumbo_exchange.near",
    "token.bocachica_mars.near",
  ],
} as const as IConfig;
