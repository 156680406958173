import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker as MUIDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { Controller, useFormContext } from "react-hook-form";

import { ReactComponent as Calendar } from "assets/images/icons/calendar.svg";
import { DATE_FORMAT, EMPTY_STRING } from "shared/constants";
import { IInput } from "shared/interfaces";
import { createFormattedDate } from "shared/utils";

import styles from "./styles";
import inputStyles from "../styles";

const DatePicker: React.FC<IInput> = ({ id, value, deps, ...rest }: IInput) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={id}
      defaultValue={EMPTY_STRING}
      rules={{ deps }}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MUIDatePicker
            {...field}
            components={{
              OpenPickerIcon: Calendar,
            }}
            disabled={rest.disabled}
            disablePast
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <styles.DateWrapper>
                <inputStyles.StyledInput ref={inputRef} {...inputProps} placeholder={DATE_FORMAT} />
                {InputProps?.endAdornment}
              </styles.DateWrapper>
            )}
            value={value || field.value}
            onChange={(date: Dayjs | null) => {
              if (!date) return;
              field.onChange(createFormattedDate(date));
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DatePicker;
