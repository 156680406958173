import { BALANCE_PRECISION, ZERO_STRING } from "shared/constants";

import { BigNumber, formatUnits } from "./calculations";
import { removeTrailingZeros } from "./index";

const MIN_BALANCE = "0.0001";
const MIN_AMOUNT = "0.00001";

export const displayAmount = (amount: string, decimals?: number, precision = 5): string => {
  const formateAmount = formatUnits(amount, decimals || 0);
  const amountBig = BigNumber(formateAmount);
  if (amountBig.eq(ZERO_STRING)) return ZERO_STRING;
  if (amountBig.lte(MIN_AMOUNT)) return `>${MIN_AMOUNT}`;
  return removeTrailingZeros(amountBig.toFixed(precision));
};

export const displayBalance = (amount: string, decimals?: number): string => {
  const formateAmount = formatUnits(amount, decimals || 0);
  const amountBig = BigNumber(formateAmount);
  if (amountBig.eq(ZERO_STRING)) return ZERO_STRING;
  if (amountBig.lte(MIN_BALANCE)) return `>${MIN_BALANCE}`;
  return removeTrailingZeros(amountBig.toFixed(BALANCE_PRECISION));
};
