import { v4 as uuid } from "uuid";

import { Translate } from "shared/components/Translate";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { ELockupDisplayedType } from "shared/interfaces";
import { selectLockupDisplayedType, setDisplayedLockups } from "store/slices/lockups";

import { ILockupSwitcherProps } from "./interface";
import styles from "./styles";

export function LockupSwitcher({ withBottomIndent }: ILockupSwitcherProps) {
  const dispatch = useAppDispatch();
  const lockupDisplayedType = useAppSelector(selectLockupDisplayedType);

  const controls = [
    {
      translate: <Translate value={"lockup.switcher.incoming"} />,
      handler: () => dispatch(setDisplayedLockups(ELockupDisplayedType.SHOW_INCOMING)),
      isActive: lockupDisplayedType === ELockupDisplayedType.SHOW_INCOMING ? true : false,
    },
    {
      translate: <Translate value={"lockup.switcher.outgoing"} />,
      handler: () => dispatch(setDisplayedLockups(ELockupDisplayedType.SHOW_OUTGOING)),
      isActive: lockupDisplayedType === ELockupDisplayedType.SHOW_OUTGOING ? true : false,
    },
  ];

  return (
    <styles.LockupSwitcherWrapper withBottomIndent={withBottomIndent}>
      {controls.map((control, index) => (
        <styles.SwitcherItem key={`${index}-${uuid()}`} isActive={control.isActive} onClick={control.handler}>
          {control.translate}
        </styles.SwitcherItem>
      ))}
    </styles.LockupSwitcherWrapper>
  );
}
