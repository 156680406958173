import Big from "big.js";

export enum ELockupStage {
  Created = "Created",
  Running = "Running",
  Terminated = "Terminated",
  Ended = "Ended",
  Archived = "Archived",
  Cliff = "Cliff",
}

export interface ILockupTermination {
  claimAmount: string;
  claimedAmount: string;
  withdrawAmount: string;
  withdrawnAmount: string;
}

export interface ILockupCheckpoint {
  endDate: number;
  amount: string;
}

export interface ICalculatedTokenData {
  amountToClaim: Big;
  amountToClaimPercents: number;
  tokenAmountPerDay: Big;
}

export interface ILockup {
  id: number;
  stage: ELockupStage;
  ownerId: string;
  creatorId: string;
  contractId: string;
  claimedAmount: string;
  distributionAmount: string;
  distributionTokenId: string;
  distributionSchedule: ILockupCheckpoint[];
  termination: ILockupTermination | undefined;
  calculatedTokenData: ICalculatedTokenData;
  cliff: number | undefined;
  startAt: number | undefined;
  receiver: string;
  canTerminate: boolean;
}

export enum ETypeLockup {
  Incoming = "Incoming",
  Outgoing = "Outgoing",
}

export enum ELockupDisplayedType {
  SHOW_INCOMING,
  SHOW_OUTGOING,
}
