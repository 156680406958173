import { ReactComponent as BackIcon } from "assets/images/icons/arrow-back.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { ECreateLockupStage, IControl } from "shared/interfaces";
import store from "store";
import { decreaseStage } from "store/slices/createLockup";

export const getHeaderControls = (
  stage: ECreateLockupStage,
  closeModal: () => void
): { right: IControl; left?: IControl } => {
  switch (stage) {
    case ECreateLockupStage.FIRST:
      return {
        right: {
          icon: <CloseIcon />,
          handler: closeModal,
        },
      };
    default:
      return {
        left: {
          icon: <BackIcon />,
          handler: () => store.dispatch(decreaseStage()),
        },
        right: {
          icon: <CloseIcon />,
          handler: closeModal,
        },
      };
  }
};
