import FungibleTokenContract from "services/contracts/FungibleToken";
import { ZERO_STRING } from "shared/constants";
import { getPrice } from "shared/helpers/prices";
import { getToken } from "shared/helpers/tokens";
import { ELockupStage, ILockup, ITokenList, ITokenPrice } from "shared/interfaces";

import { BigNumber, formatUnits } from "./calculations";

const getDetailsArray = (
  lockups: ILockup[],
  tokens: { [key: string]: FungibleTokenContract },
  prices: { [key: string]: ITokenPrice }
): ITokenList[] =>
  lockups.reduce((acc: ITokenList[], lockup) => {
    const token = getToken(lockup.distributionTokenId, tokens);
    const priceObj = getPrice(lockup.distributionTokenId, prices);
    const value = lockup.calculatedTokenData.amountToClaim.toFixed();
    const canClaim = !BigNumber(value).eq(ZERO_STRING);
    if (!token || !canClaim) return acc;

    const item = acc.find(({ token }) => token.contractId === lockup.distributionTokenId);
    const readableAvailableToClaim = formatUnits(value, token.metadata.decimals);
    const fiatValue = BigNumber(readableAvailableToClaim)
      .times(priceObj?.price || ZERO_STRING)
      .toFixed();
    if (item) {
      item.value = BigNumber(readableAvailableToClaim).add(item.value).toFixed();
      item.fiatValue = BigNumber(fiatValue).add(item.fiatValue).toFixed();
    } else {
      acc.push({
        value: readableAvailableToClaim,
        token,
        fiatValue,
      });
    }
    return acc;
  }, []);

export const calculatedPrice = (listDetails: ITokenList[]) =>
  listDetails.reduce((acc, token) => BigNumber(acc).add(token.fiatValue).toFixed(), ZERO_STRING);

export const calculatedSumOfPricesPerDay = (lockups: ILockup[], prices: { [key: string]: ITokenPrice }) => {
  try {
    const amountPerDay = lockups.reduce((acc, lockup) => {
      const token = getPrice(lockup.distributionTokenId, prices);
      if (!token) return acc;
      const amount = formatUnits(lockup.calculatedTokenData.tokenAmountPerDay, token.decimal);
      const fiatValue = BigNumber(token.price).times(amount).toFixed();
      return BigNumber(acc).add(fiatValue).toFixed();
    }, ZERO_STRING);
    return amountPerDay;
  } catch (error) {
    console.warn(`Error: while calculated Sum Of Prices Per Day \n ${error}`);
    return ZERO_STRING;
  }
};

export const statisticDetails = (
  incomingLockups: ILockup[],
  outgoingLockups: ILockup[],
  tokens: { [key: string]: FungibleTokenContract },
  prices: { [key: string]: ITokenPrice }
) => {
  const incomingDetails = getDetailsArray(incomingLockups, tokens, prices);
  const outgoingDetails = getDetailsArray(outgoingLockups, tokens, prices);
  const incomingPricePerDay = calculatedSumOfPricesPerDay(incomingLockups, prices);
  const outgoingPricePerDay = calculatedSumOfPricesPerDay(outgoingLockups, prices);
  const incomingLockupIds = incomingLockups
    .filter((lockup) => lockup.calculatedTokenData.amountToClaim.gt(ZERO_STRING) && lockup.stage !== ELockupStage.Cliff)
    .map((lockup) => lockup.id);
  return {
    incomingDetails,
    outgoingDetails,
    incomingPricePerDay,
    outgoingPricePerDay,
    incomingLockupIds,
  };
};
