import { FinalExecutionOutcome } from "@near-wallet-selector/core";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";

import { contractId } from "services/config";
import LockupContract from "services/contracts/LockupContract";
import RPCProviderService from "services/RPCProviderService";
import ToastService from "shared/components/Toast";
import { ITransaction } from "shared/interfaces";
import { RootState } from "store";

import { updateState } from "./updateState";

export const terminate = createAsyncThunk<
  void,
  {
    provider: RPCProviderService;
    requestSignTransactions: (t: ITransaction[]) => Promise<void | FinalExecutionOutcome[]>;
    receiverLockupId: string;
    lockupId: number;
  },
  { state: RootState }
>("lockups/terminate", async ({ provider, requestSignTransactions, receiverLockupId, lockupId }, { dispatch }) => {
  try {
    const transactions: ITransaction = LockupContract.createTerminationTransaction({
      receiverId: receiverLockupId,
      lockupId,
    });
    const result = await requestSignTransactions([transactions]);
    if (result) dispatch(updateState({ provider: provider, contractId }));
  } catch (error) {
    ToastService.error({ text: t("toast.error.terminate") });
    console.error("Error during termination: ", error);
  }
});
