import { TransferAction } from "@near-wallet-selector/core";

import defaultToken from "assets/images/token-icons/defaultToken.svg";
import nearIcon from "assets/images/token-icons/near.svg";
import wrapNearIcon from "assets/images/token-icons/wNEAR.svg";
import { contractId, usn, wNearAddress } from "services/config";
import { FTChangeMethod, FTViewMethod, IFungibleTokenContract } from "services/contracts/FungibleToken/interfaces";
import RPCProviderService from "services/RPCProviderService";
import { NEAR_TOKEN_ID, ONE_YOCTO } from "shared/constants";
import { STORAGE_TO_REGISTER_WNEAR, STORAGE_TO_REGISTER_FT, TRANSACTION_GAS_PRICE } from "shared/constants";
import { ITransaction, TransactionType } from "shared/interfaces";
import { ITokenMetadata } from "shared/interfaces/tokens.interfaces";

import { convertGas } from "../helpers";

const DECIMALS_DEFAULT_VALUE = 0;
const ICON_DEFAULT_VALUE = "";
const CONTRACT_ID = contractId;

export const NEAR_TOKEN = {
  decimals: 24,
  icon: nearIcon,
  name: "Near token",
  version: "0",
  symbol: "NEAR",
  reference: "",
};

interface FungibleTokenContractInterface {
  contractId: string;
  provider: RPCProviderService;
  accountId: string;
}

const defaultMetadata = {
  decimals: DECIMALS_DEFAULT_VALUE,
  icon: ICON_DEFAULT_VALUE,
  name: "Token",
  version: "0",
  symbol: "TKN",
  reference: "",
};

export default class FungibleTokenContract implements IFungibleTokenContract {
  private provider: RPCProviderService;

  constructor(props: FungibleTokenContractInterface) {
    this.contractId = props.contractId;
    this.provider = props.provider;
  }

  contractId = CONTRACT_ID;

  metadata: ITokenMetadata = defaultMetadata;

  async getStorageBalanceBounds(): Promise<{ min: string; max: string } | undefined> {
    return this.provider.viewFunction(FTViewMethod.storageBalanceBounds, this.contractId);
  }

  async getStorageBalance({
    accountId,
  }: {
    accountId: string;
  }): Promise<{ total: string; available: string } | undefined> {
    return this.provider.viewFunction(FTViewMethod.storageBalanceOf, this.contractId, { account_id: accountId });
  }

  async getMetadata(): Promise<ITokenMetadata | null> {
    try {
      if (this.contractId === NEAR_TOKEN_ID) {
        this.metadata = { ...defaultMetadata, ...NEAR_TOKEN };
        return NEAR_TOKEN;
      }

      if (this.metadata.decimals !== DECIMALS_DEFAULT_VALUE && this.metadata.icon !== ICON_DEFAULT_VALUE)
        return this.metadata;

      const metadata = await this.provider.viewFunction(FTViewMethod.ftMetadata, this.contractId);
      if (!metadata) return defaultMetadata;
      if (this.contractId === wNearAddress) metadata.icon = wrapNearIcon;
      if (!metadata.icon) metadata.icon = defaultToken;

      this.metadata = { ...defaultMetadata, ...metadata };
      return metadata;
    } catch (e) {
      console.error(`Error while loading ${this.contractId}`);
    }
    return null;
  }

  async getBalanceOf({ accountId }: { accountId: string }): Promise<string | undefined> {
    if (this.contractId === NEAR_TOKEN_ID) {
      const account = await this.provider.viewAccount(accountId);
      return account?.amount;
    }
    return this.provider.viewFunction(FTViewMethod.ftBalanceOf, this.contractId, { account_id: accountId });
  }

  createStorageDepositTransaction({ accountId, amount }: { accountId: string; amount: string }): ITransaction {
    return {
      receiverId: this.contractId,
      actions: [
        {
          type: TransactionType.FunctionCall,
          params: {
            methodName: FTChangeMethod.storageDeposit,
            args: {
              account_id: accountId,
            },
            deposit: amount,
            gas: convertGas(),
          },
        },
      ],
    };
  }

  async checkStorageBalance({ accountId }: { accountId: string }): Promise<ITransaction | undefined> {
    try {
      if (this.contractId === NEAR_TOKEN_ID || this.contractId === usn) return undefined;
      const storageBalance = await this.getStorageBalance({ accountId });

      if (!storageBalance) {
        const defaultStorageAmount =
          this.contractId === wNearAddress ? STORAGE_TO_REGISTER_WNEAR : STORAGE_TO_REGISTER_FT;

        const storageAmount = (await this.getStorageBalanceBounds())?.max || defaultStorageAmount;
        return this.createStorageDepositTransaction({ accountId, amount: storageAmount });
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  }

  async createFtTransferCallTransaction(
    storageDepositOwnerAccountId: string,
    ftTransferArgs: {
      receiver_id: string;
      amount: string;
      msg: string;
    }
  ): Promise<ITransaction[] | null> {
    try {
      const transactions: ITransaction[] = [];
      const storageDeposit = await this.checkStorageBalance({ accountId: storageDepositOwnerAccountId });
      if (storageDeposit) transactions.push(storageDeposit);

      transactions.push({
        receiverId: this.contractId,
        actions: [
          {
            type: TransactionType.FunctionCall,
            params: {
              methodName: FTChangeMethod.ftTransfer,
              args: ftTransferArgs,
              deposit: ONE_YOCTO,
              gas: convertGas(TRANSACTION_GAS_PRICE),
            },
          },
        ],
      });
      return transactions;
    } catch (error) {
      console.error("Error during ft_transfer transaction creating: ", error);
      return null;
    }
  }

  public static createNearSend({
    receiverId,
    amount,
  }: {
    receiverId: string;
    amount: string;
  }): ITransaction<TransferAction> {
    return {
      receiverId,
      actions: [
        {
          type: TransactionType.Transfer,
          params: {
            deposit: amount,
          },
        },
      ],
    };
  }
}
