import { Controller, useFormContext } from "react-hook-form";

import { EMPTY_STRING } from "shared/constants";
import { IInput } from "shared/interfaces";

import styles from "./styles";

export const Input: React.FC<IInput> = ({ id, value, deps, ...rest }: IInput) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={id}
      defaultValue={EMPTY_STRING}
      rules={{ deps }}
      render={({ field }) => (
        <styles.StyledInput
          {...rest}
          {...field}
          value={rest.disabled ? value : field.value}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          type={rest.type || "text"}
          onWheel={(event) => event.currentTarget.blur()}
        />
      )}
    />
  );
};

export { default as Autocomplete } from "./Autocomplete";
export { default as Checkbox } from "./Checkbox";
export { default as DatePicker } from "./DatePicker";
