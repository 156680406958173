import { ELockupStage, ILockup } from "shared/interfaces";

const convertStageEnumToNumber = (stage: string) => {
  switch (stage) {
    case ELockupStage.Running:
      return 0;
    case ELockupStage.Cliff:
      return 1;
    case ELockupStage.Created:
      return 2;
    case ELockupStage.Ended:
      return 3;
    case ELockupStage.Terminated:
      return 4;
    default:
      return 5;
  }
};

export const sortingLockups = (lockups: ILockup[]) =>
  lockups.sort((a, b) => convertStageEnumToNumber(a.stage) - convertStageEnumToNumber(b.stage));
