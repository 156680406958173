import { LockupOutput } from "services/contracts/LockupContract/interfaces";
import { ILockup } from "shared/interfaces";

import { getUnlockedAmountTokensData } from "./calculations";
import { validateStage } from "./validateStage";

const formatLockup = (lockup: LockupOutput, userContractId: string, receiver: string): ILockup => {
  const {
    lockup_id,
    creator_id,
    owner_id,
    stage,
    claimed_amount,
    distribution_amount,
    distribution_token_id,
    distribution_schedule,
    termination_info,
    cliff,
    start_at,
    can_terminate,
  } = lockup;

  const distributionScheduleConverted = distribution_schedule.map((scheduleItem) => {
    return { amount: scheduleItem.amount, endDate: scheduleItem.end_date };
  });

  const terminationConverted = termination_info
    ? {
        claimAmount: termination_info.claim_amount,
        claimedAmount: termination_info.claimed_amount,
        withdrawAmount: termination_info.withdraw_amount,
        withdrawnAmount: termination_info.withdrawn_amount,
      }
    : undefined;

  const calculatedTokenData = getUnlockedAmountTokensData(
    start_at,
    distributionScheduleConverted,
    distribution_amount,
    claimed_amount,
    terminationConverted
  );
  const currentCliff = cliff === 0 ? undefined : cliff;
  const currentStartAt = start_at === 0 ? undefined : start_at;

  return {
    id: lockup_id,
    creatorId: creator_id,
    ownerId: owner_id,
    contractId: userContractId,
    stage: validateStage(stage, currentCliff, distributionScheduleConverted),
    claimedAmount: claimed_amount,
    distributionAmount: distribution_amount,
    distributionTokenId: distribution_token_id,
    distributionSchedule: distributionScheduleConverted,
    termination: terminationConverted,
    calculatedTokenData,
    receiver,
    canTerminate: can_terminate,
    cliff: currentCliff,
    startAt: currentStartAt,
  };
};

export default formatLockup;
