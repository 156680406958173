import { EButtonColors, IButtonProps } from "./interfaces";
import styles from "./styles";

export function ButtonPrimary({
  name,
  handler,
  iconLeft,
  iconRight,
  fullWidth = false,
  color = EButtonColors.BLUE,
  disabled,
}: IButtonProps) {
  return (
    <styles.ButtonPrimaryWrapper
      type="button"
      onClick={handler}
      color={color}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {iconLeft || null}
      {name}
      {iconRight || null}
    </styles.ButtonPrimaryWrapper>
  );
}
