import { IMessageWarningProps } from "./interface";
import styles from "./styles";

export const MessageWarning: React.FC<IMessageWarningProps> = ({ text, icon }: IMessageWarningProps) => {
  return (
    <styles.MessageWarningWrapper>
      {icon ? <span>{icon}</span> : null}
      <styles.Text>{text}</styles.Text>
    </styles.MessageWarningWrapper>
  );
};
