import { css } from "styled-components";

import { MEDIA_WIDTHS } from "shared/constants";

import { colors, buttons } from "./colors";
import { fonts, transitions } from "./styles";
import { width } from "./width";

const mediaWidth = (key: keyof typeof MEDIA_WIDTHS, styles: TemplateStringsArray | string) => {
  return css`
    @media (max-width: ${MEDIA_WIDTHS[key] - 1}px) {
      ${styles}
    }
  `;
};

const theme = {
  colors,
  buttons,
  transition: transitions,
  fonts,
  breakpoint: MEDIA_WIDTHS,
  mediaWidth,
  width,
};

export default theme;
export { default as GlobalStyle } from "./globalStyles";
