import FungibleTokenContract from "services/contracts/FungibleToken";

export enum ECreateLockupStage {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
}

export interface IFirstStageData {
  receiver: string;
  receiverLockupContract: { id: string; isDeployed: boolean };
  note?: string;
  comment?: string;
  thirdPartyOwner?: string;
}

export interface ICreateLockupSchedule {
  endDate: number;
  amount: string;
  endDateISOFormat: string;
}

export interface ISecondStageData {
  canTerminate: boolean;
  token: string;
  schedule: ICreateLockupSchedule[];
  cliff: number | null;
  enableCliff: boolean;
  cliffISOFormat: string;
  startDate: number | null;
  startDateISOFormat: string;
  isFullFiled: boolean;
}

export enum ECreateLockupData {
  receiver = "receiver",
}

export interface ITokenList {
  token: FungibleTokenContract;
  value: string;
  fiatValue: string;
}
