import styled from "styled-components";

import { ELockupStage, ETypeLockup } from "shared/interfaces";
import { colors } from "shared/theme/colors";
import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const LockupItemWrapper = styled.div<{ typeLockup: ETypeLockup }>`
  ${flexColumnNoWrap}
  padding: 1rem;
  overflow: hidden;
  border-radius: 1.5rem;
  margin-bottom: 0.75rem;
  background: ${({ theme, typeLockup }) =>
    typeLockup === ETypeLockup.Incoming ? theme.colors.incomingCard : theme.colors.outgoingCard};

  box-shadow: 0px 4px 12px -4px ${({ theme }) => theme.colors.boxShadowCard};
  flex-shrink: 0;
`;

const Row = styled.div<{ removeIndents?: boolean; flexEnd?: boolean }>`
  ${flexRowNoWrap}
  align-items: center;
  justify-content: ${({ flexEnd }) => (flexEnd ? "flex-end" : "space-between")};
  margin-bottom: ${({ removeIndents }) => (removeIndents ? "0" : "1rem")};
`;

const LogoTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-left: 0.5rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
`;

const AvailableBalance = styled.div`
  span {
    display: block;
    line-height: 1rem;
    font-size: 0.75rem;

    &:first-child {
      font-weight: 400;
      margin-bottom: 0.125rem;
      color: ${({ theme }) => theme.colors.textGrey};
    }

    &:last-child {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const getProgressBarColor = (stage: ELockupStage) => {
  switch (stage) {
    case ELockupStage.Terminated:
      return colors.transparent;
    case ELockupStage.Ended:
      return colors.textGrey;
    default:
      return `rgba(${colors.green}, 0.4)`;
  }
};

const ProgressBar = styled.progress<{ stage: ELockupStage }>`
  border: 0;
  z-index: 9;
  width: 7.5rem;
  height: 0.25rem;
  position: relative;
  border-radius: 0.125rem;
  margin-bottom: 0.625rem;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 0.125rem;
    width: ${({ stage }) => (stage === ELockupStage.Terminated ? "100%" : "0")};
    height: ${({ stage }) => (stage === ELockupStage.Terminated ? "100%" : "0")};
    background-color: ${({ theme }) => theme.colors.placeholderInput};
    background: ${({ theme }) =>
      // eslint-disable-next-line max-len
      `repeating-linear-gradient(to right, ${theme.colors.darkGrey} 0px, ${theme.colors.darkGrey} 0.125rem, ${theme.colors.placeholderInput} 0.125rem, ${theme.colors.placeholderInput} 0.25rem)`};
  }

  &::-webkit-progress-bar {
    border: 0;
    height: 0.25rem;
    border-radius: 0.125rem;
    background-color: ${({ stage }) => getProgressBarColor(stage)};
  }

  &::-webkit-progress-value {
    border: 0;
    height: 0.25rem;
    border-radius: 0.125rem;
    background-color: ${({ stage, theme }) =>
      stage === ELockupStage.Ended ? theme.colors.textGrey : theme.colors.greenHEX};
  }

  &::-moz-progress-bar {
    border: 0;
    height: 0.25rem;
    border-radius: 0.125rem;
    background-color: ${({ stage }) => getProgressBarColor(stage)};
  }
`;

const ScheduleWrapper = styled.div`
  text-align: right;

  span {
    display: block;
    font-weight: 400;
    text-align: right;
    line-height: 1rem;
    font-size: 0.75rem;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 1rem;
`;

const WrapperButton = styled.div`
  button {
    padding: 0.5rem 1rem;
  }
`;

export default {
  ButtonsWrapper,
  LockupItemWrapper,
  Row,
  LogoTitleWrapper,
  Title,
  AvailableBalance,
  ProgressBar,
  ScheduleWrapper,
  WrapperButton,
};
