import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import { HomePage } from "pages/HomePage";
import Landing from "pages/Landing";

import { APP_ROUTES } from "./appRoutes";
import { ProtectedRoute } from "./ProtectedRoute";

export const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={APP_ROUTES.LANDING} element={<Landing />} />
        <Route
          path={APP_ROUTES.APP}
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route path={APP_ROUTES.DEFAULT} element={<Navigate replace to={APP_ROUTES.APP} />} />
      </Routes>
    </Router>
  );
};
