import styled, { css } from "styled-components";

import { ReactComponent as GitHub } from "assets/images/landing-images/gitHub.svg";
import { flexRowNoWrap } from "shared/theme/styles";

const Footer = styled.footer`
  max-width: 63.5rem;
  width: 100%;
  ${flexRowNoWrap}
  justify-content: space-between;
  padding: 11.25rem 1rem 2.25rem;
  p {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.188rem;
    color: ${({ theme }) => theme.colors.textGrey};
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        align-items: center;
        flex-direction: column-reverse;
        gap: 3rem;
        padding: 11.25rem 0 2.25rem;
      `
    )}
`;

const Link = styled.a`
  cursor: pointer;
  ${flexRowNoWrap}
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textGrey};
`;

export default {
  Footer,
  Link,
  GitHub,
};
