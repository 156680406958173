import styled from "styled-components";

const ModalControlsHeaderWrapper = styled.div`
  display: grid;
  align-items: center;
  margin-bottom: 1.5rem;
  grid-template-columns: 2fr 6fr 2fr;

  div {
    line-height: 0;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
`;

const Title = styled.span`
  font-weight: 600;
  line-height: 140%;
  font-size: 1.25rem;
  text-align: center;
`;

export default {
  ModalControlsHeaderWrapper,
  Title,
};
