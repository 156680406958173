import { createAsyncThunk } from "@reduxjs/toolkit";

import { retrievePrices } from "shared/helpers/prices";
import { setPrices } from "store/slices/prices";

import { RootState } from "../index";

export const loadPrices = createAsyncThunk<
  void,
  {
    tokenAddresses: string[];
  },
  { state: RootState }
>("prices/loadPrices", async ({ tokenAddresses }, { dispatch }) => {
  try {
    const prices = await retrievePrices(tokenAddresses);
    if (!prices) return;
    dispatch(setPrices(prices));
  } catch (e) {
    console.error(`Error: while loading token prices \n ${e}`);
  }
});
