import styled from "styled-components";

const Button = styled.div<{ fullWidth?: boolean; color: string }>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "7.438rem")};
  height: 2rem;
  border-radius: 0.5rem;
  background-color: ${({ color }) => color};
`;

export default {
  Button,
};
