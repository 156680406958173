import styled from "styled-components";

import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const LockupItemWrapper = styled.div`
  ${flexColumnNoWrap}
  padding: 1rem;
  border-radius: 1.5rem;
  flex-shrink: 0;
  position: relative;
  transform-style: preserve-3d;

  background: ${({ theme }) => theme.colors.landingCard};
  box-shadow: 0px 4px 12px -4px ${({ theme }) => theme.colors.boxShadowCard};
`;

const FirstShadowBlock = styled.div`
  position: absolute;
  width: 95%;
  height: 100%;
  border-radius: 1.5rem;
  top: 15px;
  left: 50%;
  transform: translateX(-50%) translateY(0) translateZ(-1px);
  background: ${({ theme }) => theme.colors.landingHiddenFirstCard};
  box-shadow: 0px 3.87692px 11.6308px -3.87692px ${({ theme }) => theme.colors.boxShadowCard};
`;

const SecondShadowBlock = styled.div`
  position: absolute;
  width: 90%;
  height: 100%;
  border-radius: 1.5rem;
  top: 30px;
  left: 50%;
  transform: translateX(-50%) translateY(0) translateZ(-2px);
  background: ${({ theme }) => theme.colors.landingHiddenSecondCard};
  box-shadow: 0px 3.75385px 11.2615px -3.75385px ${({ theme }) => theme.colors.boxShadowCard};
`;

const Row = styled.div<{ removeIndents?: boolean }>`
  ${flexRowNoWrap}
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ removeIndents }) => (removeIndents ? "0" : "1rem")};
`;

const LogoTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-left: 0.5rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.white};
`;

const AvailableBalance = styled.div`
  span {
    display: block;
    line-height: 1rem;
    font-size: 0.75rem;

    &:first-child {
      font-weight: 400;
      margin-bottom: 0.125rem;
      color: ${({ theme }) => theme.colors.textGrey};
    }

    &:last-child {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const ProgressBar = styled.progress`
  border: 0;
  width: 7.5rem;
  height: 0.25rem;
  border-radius: 0.125rem;
  margin-bottom: 0.625rem;
  background-color: rgba(${({ theme }) => theme.colors.green}, 0.4);

  &::-webkit-progress-bar {
    border: 0;
    height: 0.25rem;
    border-radius: 0.125rem;
    background-color: rgba(${({ theme }) => theme.colors.green}, 0.4);
  }

  &::-webkit-progress-value {
    border: 0;
    height: 0.25rem;
    border-radius: 0.125rem;
    background-color: rgb(${({ theme }) => theme.colors.green});
  }

  &::-moz-progress-bar {
    border: 0;
    height: 0.25rem;
    border-radius: 0.125rem;
    background-color: rgba(${({ theme }) => theme.colors.green}, 0.4);
  }
`;

const ScheduleWrapper = styled.div`
  text-align: right;

  span {
    display: block;
    font-weight: 400;
    text-align: right;
    line-height: 1rem;
    font-size: 0.75rem;
  }
`;

export default {
  LockupItemWrapper,
  Row,
  LogoTitleWrapper,
  Title,
  AvailableBalance,
  ProgressBar,
  ScheduleWrapper,
  FirstShadowBlock,
  SecondShadowBlock,
};
