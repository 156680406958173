import { ThemeProvider as MUIThemeProvider, createTheme } from "@mui/material/styles";
import { AnimatePresence } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import "services/translation";

import { WalletSelectorContextProvider } from "providers/WalletProvider";
import { AppRoutes } from "routes";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import theme, { GlobalStyle } from "shared/theme";
import store from "store";
import { closeModal, EModals, IModalsProps, MODALS, selectModalState } from "store/slices/modals";

import reportWebVitals from "./reportWebVitals";

import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const tagManagerArgs = {
  gtmId: "G-XSD4KQ4PMM",
};

TagManager.initialize(tagManagerArgs);

function AppWrapper() {
  const modalState = useAppSelector(selectModalState);
  const dispatch = useAppDispatch();
  const Modal: IModalsProps[EModals] = MODALS[modalState.modal];
  return (
    <>
      <AnimatePresence>
        {Modal && <Modal closeModal={() => dispatch(closeModal())} {...modalState.props} />}
      </AnimatePresence>
      <div>
        <GlobalStyle />
        <AppRoutes />
        <ToastContainer />
      </div>
    </>
  );
}

const darkThemeMUI = createTheme({
  palette: {
    mode: "dark",
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MUIThemeProvider theme={darkThemeMUI}>
        <Provider store={store}>
          <WalletSelectorContextProvider>
            <AppWrapper />
          </WalletSelectorContextProvider>
        </Provider>
      </MUIThemeProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
