import { networkId, helperUrl } from "services/config";

import { fetchSendJson } from "./index";

export const getUserWalletTokens = async (accountId: string): Promise<string[]> => {
  const baseUrl =
    networkId === "mainnet"
      ? `${helperUrl}/v0/account/${accountId}/ft`
      : `${helperUrl}/account/${accountId}/likelyTokens`;

  if (!accountId) return [];

  try {
    const response = await fetchSendJson({ url: baseUrl });
    return networkId === "mainnet" ? response.contract_ids : response;
  } catch (error) {
    console.warn(`Error ${error} while loading likely tokens from \n ${baseUrl}`);
    return [];
  }
};
