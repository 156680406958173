import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import * as M from "shared/components/modals";
import { IModalProps, IAccountModal, ITerminationModal } from "shared/interfaces";
import { RootState } from "store";

export enum EModals {
  EMPTY = "EMPTY",
  STATISTICS_MODAL = "STATISTICS_MODAL",
  CREATE_LOCKUP_MODAL = "CREATE_LOCKUP_MODAL",
  ACCOUNT_MODAL = "ACCOUNT_MODAL",
  TERMINATION_MODAL = "TERMINATION_MODAL",
  DEPLOY_CONTRACT_MODAL = "DEPLOY_CONTRACT_MODAL",
}

export type IModalsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
  [EModals.STATISTICS_MODAL]: IModalProps;
  [EModals.CREATE_LOCKUP_MODAL]: IModalProps;
  [EModals.ACCOUNT_MODAL]: IAccountModal;
  [EModals.TERMINATION_MODAL]: ITerminationModal;
  [EModals.DEPLOY_CONTRACT_MODAL]: IModalProps; // TODO
};

export type IModals = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
  [EModals.STATISTICS_MODAL]: React.FC<IModalProps>;
  [EModals.CREATE_LOCKUP_MODAL]: React.FC<IModalProps>;
  [EModals.ACCOUNT_MODAL]: React.FC<IAccountModal>;
  [EModals.TERMINATION_MODAL]: React.FC<ITerminationModal>;
  [EModals.DEPLOY_CONTRACT_MODAL]: React.FC<IModalProps>;
};

export const MODALS: IModals = {
  [EModals.EMPTY]: null,
  [EModals.STATISTICS_MODAL]: M.StatisticsModal,
  [EModals.CREATE_LOCKUP_MODAL]: M.CreateLockupModal,
  [EModals.ACCOUNT_MODAL]: M.AccountModal,
  [EModals.TERMINATION_MODAL]: M.TerminationModal,
  [EModals.DEPLOY_CONTRACT_MODAL]: M.DeployContractModal,
};

export type ModalProps<T extends EModals> = Omit<IModalsProps[T], "closeModal">;

interface IModalState {
  modal: EModals;
  props: IModalsProps[EModals];
}

const initialState: IModalState = {
  modal: EModals.EMPTY,
  props: null,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    showModal: (_, action: PayloadAction<IModalState>) => action.payload,
    closeModal: () => initialState,
  },
});

// SELECTORS
export const selectModalState = (state: RootState) => state.modals;

// ACTIONS
export const { showModal, closeModal } = modalsSlice.actions;

export const modalsReducer = modalsSlice.reducer;
