import React from "react";

import { EDimensions } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { selectAccountId } from "store/slices/user";

import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

const Header: React.FC = () => {
  const accountId = useAppSelector(selectAccountId);

  const dimensions = useWindowDimensions();
  const isMediumScreen = dimensions === EDimensions.MEDIUM || dimensions === EDimensions.SMALL;

  return isMediumScreen ? (
    <MobileHeader accountId={accountId} isSmallScreen={dimensions === EDimensions.SMALL} />
  ) : (
    <DesktopHeader accountId={accountId} />
  );
};

export default Header;
