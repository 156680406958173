import { providers } from "near-api-js";

import { nodeUrl } from "services/config";
import RPCProviderService from "services/RPCProviderService";

export const checkAccountIdAvailable = async (accountId: string) => {
  try {
    const provider = new providers.JsonRpcProvider({ url: nodeUrl });
    const providerService = new RPCProviderService(provider);
    const viewAccountId = await providerService.viewAccount(accountId);
    return Boolean(viewAccountId);
  } catch (error) {
    console.warn(`Error: account ${accountId} does not exist while viewing \n ${error}`);
    return false;
  }
};
