import { t } from "i18next";

import { ReactComponent as NoLockups } from "assets/images/components-parts/no-lockups.svg";
import { EDimensions } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { ELockupDisplayedType, ETypeLockup } from "shared/interfaces";
import { sortingLockups } from "shared/utils";
import { selectData } from "store/selectors/selectData";

import { LockupItem } from "./components/LockupItem";
import { LockupSwitcher } from "./components/LockupSwitcher";
import styles from "./styles";
import { DeployContractBlock } from "../DeployContractBlock";
import Loader from "../Loader";
import { MessageBlock } from "../MessageBlock";

interface ILockupsBlock {
  showSwitcher?: boolean;
}

export function LockupsBlock({ showSwitcher = false }: ILockupsBlock) {
  const {
    lockups,
    loading,
    user: { isDeployedContract },
  } = useAppSelector(selectData);
  const dimensions = useWindowDimensions();
  const isMediumScreen = dimensions === EDimensions.MEDIUM || dimensions === EDimensions.SMALL;
  const showDeployedContract = isMediumScreen && !isDeployedContract;

  const incomingLockups = sortingLockups(lockups.incoming.arr);
  const outgoingLockups = sortingLockups(lockups.outgoing.arr);
  const lockupDataByDisplayedType =
    lockups.lockupDisplayedType === ELockupDisplayedType.SHOW_INCOMING
      ? { arr: incomingLockups, type: ETypeLockup.Incoming }
      : { arr: outgoingLockups, type: ETypeLockup.Outgoing };

  return (
    <styles.Container>
      {showSwitcher && <LockupSwitcher withBottomIndent />}
      {loading ? (
        <Loader />
      ) : (
        <>
          {showDeployedContract && (
            <styles.StyledDeployContractBlock>
              <DeployContractBlock />
            </styles.StyledDeployContractBlock>
          )}
          <styles.List>
            {lockupDataByDisplayedType.arr.length === 0 ? (
              <MessageBlock text={t("lockup.messages.noLockups")}>
                <NoLockups />
              </MessageBlock>
            ) : (
              lockupDataByDisplayedType.arr.map((lockup) => (
                <LockupItem
                  key={`${lockup.contractId}: ${lockup.id}`}
                  lockup={lockup}
                  typeLockup={lockupDataByDisplayedType.type}
                />
              ))
            )}
          </styles.List>
        </>
      )}
    </styles.Container>
  );
}
