import styled, { css } from "styled-components";

import { getFadeIn } from "shared/theme/animations/landing";
import { flexColumnNoWrap } from "shared/theme/styles";

const Section = styled.section<{ isInView: boolean }>`
  max-width: 46.25rem;
  width: 100%;
  ${flexColumnNoWrap}
  margin-top: 7.5rem;
  ${({ isInView }) => getFadeIn(isInView)}
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 2rem;
  line-height: 2.375rem;
  text-align: center;
  margin-bottom: 4.5rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1.5rem;
        line-height: 1.813rem;
        margin-bottom: 3rem;
      `
    )}
`;

const Description = styled.div`
  ${flexColumnNoWrap}
  gap: 1rem;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.colors.textGrey};
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        font-size: 1rem;
        line-height: 1.375rem;
      `
    )}
`;

export default {
  Section,
  Title,
  Description,
};
