import { t } from "i18next";
import isEmpty from "lodash/isEmpty";

import { FactoryContract } from "services/contracts";
import LockupContract from "services/contracts/LockupContract";
import { IRPCProviderService } from "services/RPCProviderService";
import { DEFAULT_PAGE_LIMIT, ZERO } from "shared/constants";
import { ILockup } from "shared/interfaces";
import { assertFulfilled, formatLockup, getOutgoingLockupsKey, isNotNullOrUndefined, toMap } from "shared/utils";

export async function retrieveIncomingLockupsResult(pages: number, contract: LockupContract) {
  return (
    await Promise.allSettled(
      [...Array(pages)].map((_, i) => contract.getLockups({ from: i * DEFAULT_PAGE_LIMIT, limit: DEFAULT_PAGE_LIMIT }))
    )
  )
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .flat();
}

export async function retrieveIncomingLockups(
  provider: IRPCProviderService,
  userLockups: string
): Promise<{ [key: number]: ILockup }> {
  const lockupContract = new LockupContract({ provider, contractId: userLockups });
  const lockupsLength = await lockupContract.getLockupsCount();
  const pages = Math.ceil(lockupsLength ? lockupsLength / DEFAULT_PAGE_LIMIT : 0);
  const lockupsResult = await retrieveIncomingLockupsResult(pages, lockupContract);
  const lockupArray = lockupsResult
    .filter(isNotNullOrUndefined)
    .map((lockup) => formatLockup(lockup, userLockups, t("lockup.details.receiverYou")));
  const lockupsMap: { [key: number]: ILockup } = toMap(lockupArray, "id");
  return lockupsMap;
}

export async function retrieveLockupsByOwner(pages: number, contract: FactoryContract, accountId: string) {
  const retrievedLockupsByOwner = await Promise.allSettled(
    [...Array(pages)].map((_, i) =>
      contract.getLockupsByOwner({
        ownerId: accountId,
        from: i * DEFAULT_PAGE_LIMIT,
        limit: DEFAULT_PAGE_LIMIT,
      })
    )
  );
  const filteredLockupsByOwner = retrievedLockupsByOwner
    .filter(assertFulfilled)
    .map(({ value }) => value)
    .filter(isNotNullOrUndefined)
    .reduce((acc, element) => Object.assign(acc, element), {});

  return isEmpty(filteredLockupsByOwner) ? null : filteredLockupsByOwner;
}

export async function retrieveUserLockupId(provider: IRPCProviderService, userLockups: string, lockupsId: number[]) {
  const lockupContract = new LockupContract({ provider, contractId: userLockups });

  const [lockupContractMetadata, ...lockupsResult] = await Promise.all([
    await lockupContract.getMetadata(),
    ...lockupsId.map((id) => lockupContract.getLockup({ lockupId: id })),
  ]);

  const receiver = lockupContractMetadata?.user_id;
  if (!receiver) return {};

  const lockupArray = lockupsResult
    .filter(isNotNullOrUndefined)
    .map((lockup) => formatLockup(lockup, userLockups, receiver));

  const lockupsMap: { [key: string]: ILockup } = lockupArray.reduce(
    (acc, item) => ({ ...acc, [getOutgoingLockupsKey(item["id"], item["contractId"])]: item }),
    {}
  );

  return lockupsMap;
}

export async function retrieveOutgoingLockups(
  provider: IRPCProviderService,
  accountId: string,
  contractId: string
): Promise<{ [key: string]: ILockup }> {
  const factory = new FactoryContract({ provider, contractId });
  const lockupsLength = await factory.getLockupsByOwnerCount({ ownerId: accountId });
  const pages = Math.ceil(lockupsLength ? lockupsLength / DEFAULT_PAGE_LIMIT : ZERO);

  const lockupsByOwner = await retrieveLockupsByOwner(pages, factory, accountId);
  if (!lockupsByOwner) return {};

  const userLockups = await Promise.all(
    Object.entries(lockupsByOwner).map(([userLockupId, lockupsId]) =>
      retrieveUserLockupId(provider, userLockupId, lockupsId)
    )
  );

  const lockups = userLockups.reduce((acc, element) => Object.assign(acc, element), {});

  return lockups;
}
