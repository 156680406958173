import { useInView } from "framer-motion";
import { useRef } from "react";

import near from "assets/images/landing-images/test-near-icon.svg";
import { Translate } from "shared/components/Translate";

import styles from "./styles";

const partners = [
  {
    link: "https://near.org/",
    logo: near,
  },
];

export default function Partner() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <styles.Section ref={ref} isInView={isInView}>
      <styles.Title>
        <Translate value="landing.trustedBy" />
      </styles.Title>
      <styles.List>
        {partners.map(({ link, logo }) => (
          <styles.Link key={link} href={link} target="_blank" rel="noreferrer">
            <img src={logo} alt={link} loading="lazy" />
          </styles.Link>
        ))}
      </styles.List>
    </styles.Section>
  );
}
