import { t } from "i18next";

import near from "assets/images/token-icons/near.svg";
import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";
import { ButtonsGroup } from "shared/components/Buttons/ButtonsGroup";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import TokenLogo from "shared/components/TokenLogo";
import { Translate } from "shared/components/Translate";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { IAccountModal } from "shared/interfaces";
import { selectAccountId } from "store/slices/user";

import styles from "./styles";
import ModalWrapper from "../ModalWrapper";

export const AccountModal: React.FC<IAccountModal> = ({ closeModal, signOut }: IAccountModal) => {
  const accountId = useAppSelector(selectAccountId);
  return (
    <ModalWrapper closeModal={closeModal} widthInRem="20.5">
      <styles.Header>
        <ButtonIcon control={{ icon: <styles.CloseIcon />, handler: closeModal }} />
      </styles.Header>
      <styles.Title>
        <Translate value="modals.connectedWallet" />
      </styles.Title>
      <styles.WalletContainer>
        <TokenLogo icon={near} alt="near" />
        <p>{accountId}</p>
      </styles.WalletContainer>
      <ButtonsGroup
        cancelButtonHandler={closeModal}
        rightButton={{
          name: t("actions.disconnect"),
          handler: () => {
            signOut();
            closeModal();
          },
          color: EButtonColors.PINK,
        }}
      />
    </ModalWrapper>
  );
};
