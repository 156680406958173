import { useFormContext } from "react-hook-form";

import { Translate } from "shared/components/Translate";
import { ICheckbox } from "shared/interfaces";

import styles from "./styles";

const Checkbox: React.FC<ICheckbox> = ({ id, title, description, onChange, defaultChecked }: ICheckbox) => {
  const { register } = useFormContext();

  return (
    <styles.Approve>
      <styles.AcceptInput>
        <input type="checkbox" {...register(id, { onChange })} defaultChecked={defaultChecked} />
        <svg viewBox="0 0 21 21">
          <polyline points="5 10.75 8.5 14.25 16 6" />
        </svg>
      </styles.AcceptInput>
      <styles.ApproveDescription>
        <p>
          <Translate value={title} />
        </p>
        <p>
          <Translate value={description} />
        </p>
      </styles.ApproveDescription>
    </styles.Approve>
  );
};

export default Checkbox;
