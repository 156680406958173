import styled, { css } from "styled-components";

import { flexColumnNoWrap } from "shared/theme/styles";

const Container = styled.div`
  height: 100%;
  z-index: 1;
  ${flexColumnNoWrap}
  align-items: center;
  height: 100%;
  position: relative;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        padding: 0 1rem;
      `
    )}
`;

const Background = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: -1;
`;

export default {
  Container,
  Background,
};
