import { createAsyncThunk } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";

import { getUserWalletTokens } from "services/api/userTokens";
import { defaultTokens, wNearAddress } from "services/config";
import { FactoryContract } from "services/contracts";
import { IRPCProviderService } from "services/RPCProviderService";
import { retrieveIncomingLockups, retrieveOutgoingLockups } from "shared/helpers";
import { ILockup } from "shared/interfaces";
import { setIncomingLockups, setOutgoingLockups, setLockupsLoading } from "store/slices/lockups";
import { setLockupId } from "store/slices/user";

import { loadPrices } from "./loadPrices";
import { loadTokens } from "./loadTokens";
import { RootState } from "../index";

export const loadLockups = createAsyncThunk<
  void,
  {
    provider: IRPCProviderService;
    contractId: string;
  },
  { state: RootState }
>("lockups/loadLockups", async ({ provider, contractId }, { dispatch, getState }) => {
  try {
    const state = getState();
    const accountId = state.user.id;
    const factory = new FactoryContract({ provider, contractId });
    const userLockups = await factory.getLockupId({ accountId });
    let incomingLockups: { [key: number]: ILockup } = {};
    if (userLockups) {
      incomingLockups = await retrieveIncomingLockups(provider, userLockups);
      dispatch(setLockupId(userLockups));
    }
    const userTokensId = await getUserWalletTokens(accountId);
    const tokenAddresses = uniq([
      ...defaultTokens,
      ...Object.values(incomingLockups).map((lockup) => lockup.distributionTokenId),
      ...userTokensId,
      wNearAddress,
    ]);

    const outgoingLockups = await retrieveOutgoingLockups(provider, accountId, contractId);

    dispatch(setIncomingLockups(incomingLockups));
    dispatch(setOutgoingLockups(outgoingLockups));
    dispatch(loadTokens({ tokenAddresses, provider }));
    dispatch(loadPrices({ tokenAddresses }));
  } catch (e) {
    console.error(`Error: while initial loading lockups from factory \n ${e}`);
  } finally {
    dispatch(setLockupsLoading(false));
  }
});
