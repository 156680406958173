import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";

import { ReactComponent as Arrow } from "assets/images/icons/arrow-right.svg";
import { ButtonsGroup } from "shared/components/Buttons/ButtonsGroup";
import { Checkbox } from "shared/components/Input";
import { Translate } from "shared/components/Translate";
import { BALANCE_PRECISION, DEFAULT_SCHEDULE, ZERO_STRING } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { ISecondStageData } from "shared/interfaces";
import {
  secondStageSchema,
  formatSecondStage,
  getSecondStageInitialData,
  SCHEDULE_KEY,
  clearedSecondStageForm,
} from "shared/utils";
import { formatUnits } from "shared/utils/calculations";
import { selectBalance } from "store/selectors/selectBalance";
import { increaseStage, selectCreateLockupData, setSecondStageData } from "store/slices/createLockup";
import { closeModal } from "store/slices/modals";

import styles from "./styles";
import Cliff from "../components/Cliff";
import { Period } from "../components/Period";

const TOKEN_KEY = "token";
const FORM_ID_KEY = "formId";

const SecondStageForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { secondStage } = useAppSelector(selectCreateLockupData);
  const [isUnterminated, setIsUnterminated] = useState(!secondStage.canTerminate);
  const methods = useForm<ISecondStageData>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: zodResolver(secondStageSchema),
    defaultValues: getSecondStageInitialData(secondStage),
  });
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const { fields, append, remove } = useFieldArray({ keyName: FORM_ID_KEY, control: control, name: SCHEDULE_KEY });
  const tokenAddress = useWatch({ name: TOKEN_KEY, control });

  const onSubmit: SubmitHandler<ISecondStageData> = (values) => {
    const formattedValues = formatSecondStage(values, isUnterminated);
    if (isEqual(formattedValues, secondStage)) {
      dispatch(increaseStage());
      return;
    }
    dispatch(setSecondStageData({ ...secondStage, ...formattedValues }));
  };

  useEffect(() => {
    const isTokenNotChanged = tokenAddress.includes(secondStage.token);
    if (isTokenNotChanged && secondStage.isFullFiled) return;
    reset(clearedSecondStageForm(tokenAddress));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddress]);

  const balance = useAppSelector((state) => selectBalance(state, tokenAddress));

  const addPeriod = () => append({ ...DEFAULT_SCHEDULE });
  const removePeriod = (index: number) => {
    if (index === 0) {
      const startDateSecondPeriod = getValues("schedule.0").endDateISOFormat;
      setValue("startDateISOFormat", startDateSecondPeriod);
    }
    remove(index);
  };

  return (
    <FormProvider {...methods}>
      <styles.Form onSubmit={(e) => e.preventDefault()}>
        <styles.WrapperPeriods>
          {fields.map((period, index, arr) => (
            <Period
              key={period.formId}
              id={index}
              endDate={period.endDateISOFormat}
              amount={period.amount}
              tokenAddress={tokenAddress}
              balances={balance ? formatUnits(balance.balance, balance.decimal, BALANCE_PRECISION) : ZERO_STRING}
              remove={() => removePeriod(index)}
              periodsLength={arr.length}
            />
          ))}
        </styles.WrapperPeriods>
        <styles.AddPeriod onClick={addPeriod}>
          <styles.Plus />
          <Translate value="actions.addPeriod" />
        </styles.AddPeriod>
        <Cliff />
        <styles.CheckboxContainer>
          <Checkbox
            id="isUnterminated"
            title="createLockup.unterminated.title"
            description="createLockup.unterminated.description"
            defaultChecked={isUnterminated}
            onChange={(e) => setIsUnterminated(e.target.checked)}
          />
        </styles.CheckboxContainer>
        <styles.Buttons>
          <ButtonsGroup
            cancelButtonHandler={() => dispatch(closeModal())}
            rightButton={{
              name: t("actions.next"),
              handler: handleSubmit(onSubmit),
              iconRight: <Arrow />,
              disabled: !isEmpty(errors),
            }}
          />
        </styles.Buttons>
      </styles.Form>
    </FormProvider>
  );
};

export default SecondStageForm;
