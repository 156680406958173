import { t } from "i18next";

import { IButtonGroup } from "./interface";
import styles from "./styles";
import { ButtonPrimary } from "../ButtonPrimary";
import { EButtonColors } from "../interfaces";

export function ButtonsGroup({ cancelButtonHandler, rightButton }: IButtonGroup) {
  return (
    <styles.ButtonsGroupWrapper>
      <ButtonPrimary name={t("actions.cancel")} handler={cancelButtonHandler} color={EButtonColors.DARK_GREY} />
      <ButtonPrimary
        name={rightButton.name}
        handler={rightButton.handler}
        iconRight={rightButton.iconRight}
        iconLeft={rightButton.iconLeft}
        color={rightButton.color}
        disabled={rightButton.disabled}
      />
    </styles.ButtonsGroupWrapper>
  );
}
