import { createSelector } from "@reduxjs/toolkit";

import { IBalance } from "shared/interfaces";
import { RootState } from "store";
import { selectBalances } from "store/slices/user";

export const selectBalance = createSelector(
  [selectBalances, (_: RootState, id: string) => id],
  (balances, id): IBalance | null => {
    const balance = balances[id] || null;
    return balance;
  }
);
