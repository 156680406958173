import styled from "styled-components";

const MessageWarningWrapper = styled.div`
  display: flex;
  max-width: 100%;
  font-weight: 400;
  font-size: 0.75rem;
  align-items: flex-start;
  white-space: break-word;
  color: rgb(${({ theme }) => theme.colors.red});

  span {
    margin-right: 0.25rem;
  }
`;

const Text = styled.div`
  text-align: left;
  white-space: break-spaces;
`;

export default {
  Text,
  MessageWarningWrapper,
};
