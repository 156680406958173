import { Trans } from "react-i18next";

import { ZERO_STRING } from "shared/constants";
import { itemVariants } from "shared/theme/animations/slideDown";
import { displayAmount, displayBalance } from "shared/utils";
import { BigNumber } from "shared/utils/calculations";

import { ITokenListComponent } from "./interface";
import styles from "./styles";
import TokenLogo from "../TokenLogo";

const TokenList: React.FC<ITokenListComponent> = ({ list, handleSelectToken, marginBottom }: ITokenListComponent) => {
  return (
    <>
      {list.map((element) => (
        <styles.MotionRow
          key={element.token.contractId}
          variants={itemVariants}
          onClick={() => handleSelectToken && handleSelectToken(element.token.contractId)}
          $isClickable={Boolean(handleSelectToken)}
          $marginBottom={marginBottom}
        >
          <TokenLogo icon={element.token.metadata.icon} alt={element.token.contractId} />
          <styles.Description>
            <styles.TokenTitle>
              <div>{element.token.metadata.symbol}</div>
              <div>{displayAmount(element.value)}</div>
            </styles.TokenTitle>
            <styles.TokenSubtitle>
              <div>{element.token.metadata.name}</div>
              <div>
                {!BigNumber(element.fiatValue).eq(ZERO_STRING) ? (
                  <Trans i18nKey="fiatValue" values={{ value: displayBalance(element.fiatValue) }} />
                ) : (
                  ZERO_STRING
                )}
              </div>
            </styles.TokenSubtitle>
          </styles.Description>
        </styles.MotionRow>
      ))}
    </>
  );
};

export default TokenList;
