import RPCProviderService from "services/RPCProviderService";
import { ONE_YOCTO, TERMINATE_GAS } from "shared/constants";
import { CLAIM_GAS } from "shared/constants";
import { ITransaction, TransactionType } from "shared/interfaces";

import {
  ILockupContract,
  LockupViewMethods,
  LockupOutput,
  LockupChangeMethods,
  LockupCheckpointOutput,
  ContractMetadataOutput,
} from "./interfaces";
import { convertGas } from "../helpers";

export default class LockupContract implements ILockupContract {
  readonly contractId: string;

  private provider: RPCProviderService;

  public constructor({ provider, contractId }: { provider: RPCProviderService; contractId: string }) {
    this.contractId = contractId;
    this.provider = provider;
  }

  async getLockupsCount(): Promise<number | undefined> {
    return this.provider.viewFunction(LockupViewMethods.getLockupsCount, this.contractId);
  }

  async getMetadata(): Promise<ContractMetadataOutput | undefined> {
    return this.provider.viewFunction(LockupViewMethods.getMetadata, this.contractId);
  }

  async getLockups({ from, limit }: { from: number; limit: number }): Promise<LockupOutput | undefined> {
    return this.provider.viewFunction(LockupViewMethods.getLockups, this.contractId, { from_index: from, limit });
  }

  async getLockup({ lockupId }: { lockupId: number }): Promise<LockupOutput | undefined> {
    return this.provider.viewFunction(LockupViewMethods.getLockup, this.contractId, { lockup_id: lockupId });
  }

  public static createTerminationTransaction({
    receiverId,
    lockupId,
  }: {
    receiverId: string;
    lockupId: number;
  }): ITransaction {
    return {
      receiverId,
      actions: [
        {
          type: TransactionType.FunctionCall,
          params: {
            methodName: LockupChangeMethods.terminate,
            args: {
              lockup_id: lockupId,
            },
            deposit: ONE_YOCTO,
            gas: convertGas(TERMINATE_GAS),
          },
        },
      ],
    };
  }

  public static createClaimTransaction({
    receiverId,
    lockupId,
  }: {
    receiverId: string;
    lockupId: number;
  }): ITransaction {
    return {
      receiverId,
      actions: [
        {
          type: TransactionType.FunctionCall,
          params: {
            methodName: LockupChangeMethods.claim,
            args: {
              lockup_id: lockupId,
            },
            deposit: ONE_YOCTO,
            gas: convertGas(CLAIM_GAS),
          },
        },
      ],
    };
  }

  public static generateCreateLockupMessage({
    canTerminate,
    schedule,
    startAt,
    ownerId,
    cliff,
  }: {
    canTerminate: boolean;
    schedule: LockupCheckpointOutput[];
    startAt: number | null;
    cliff: number | null;
    ownerId?: string;
  }) {
    return JSON.stringify({
      AddLockup: {
        can_terminate: canTerminate,
        schedule: schedule,
        owner_id: ownerId,
        start_at: startAt || undefined,
        cliff: cliff || undefined,
      },
    });
  }
}
