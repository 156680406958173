import { ITokenMetadata } from "shared/interfaces";

export interface IFungibleTokenContract {
  getMetadata(): Promise<ITokenMetadata | null>;
  getBalanceOf({ accountId }: { accountId: string }): Promise<string | undefined>;
}

export enum FTViewMethod {
  storageBalanceBounds = "storage_balance_bounds",
  storageBalanceOf = "storage_balance_of",
  ftMetadata = "ft_metadata",
  ftBalanceOf = "ft_balance_of",
}

export enum FTChangeMethod {
  ftTransfer = "ft_transfer_call",
  storageDeposit = "storage_deposit",
}
