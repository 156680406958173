import { IMessageBlockProps } from "./interface";
import { MessageBlockWrapper } from "./styles";

export function MessageBlock({ text, children }: IMessageBlockProps) {
  return (
    <MessageBlockWrapper>
      {children && <div className="logo">{children}</div>}
      <div className="text">{text}</div>
    </MessageBlockWrapper>
  );
}
