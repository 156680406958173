export const colors = {
  black: "#131313",
  white: "#FFFFFF",
  background: "#141A30",
  backgroundBlue: "36, 43, 72",
  grey: "180, 186, 218",
  blue: "#3BBAFF",
  blueRGB: "59, 186, 255",
  deepBlue: "#1D2443",
  greenHEX: "#1EC659",
  green: "30, 198, 89",
  red: "255, 73, 73",
  pink: "#FE477F",
  darkPink: "#5A3B5F",
  darkGrey: "#4B5271",
  orange: "238, 151, 49",
  cardBg: "#242B48",
  boxShadowCard: "rgba(20, 25, 49, 0.2)",
  boxShadowInput: "#295F8B",
  textGrey: "#B4BADA",
  transparent: "transparent",
  disabledButton: "#656C8B",
  darkGreen: "#2D5457",
  error: "#FE477F",
  borderInput: "#8086A6",
  placeholderInput: "#656C8B",
  sideBg: "#1C233E",
  accountIdBg: "#3E4564",
  tokenListBg: "#3F4768",
  tokenListElement: "#313957",
  blueBoxShadow: "#295F8B",
  circleLoading: "#1D2D4A",
  placeholder: "#202640",
  placeholderElement: "#29304E",
  toastBg: "#313857",
  landingCard: "#313857",
  landingHiddenFirstCard: "#272E4D",
  landingHiddenSecondCard: "#1D2443",
  landingLinearGradient: "linear-gradient(90deg, #3bbaff 1.98%, #1ec659 143.19%)",
  backgroundLayout: "rgba(20, 26, 48, 0.8)",
  incomingCard: "#242B48",
  outgoingCard: "#313857",
};

export const buttons = {
  blue: "#3BBAFF",
  blueHover: "#45C4FF",
  blueActive: "#4FCEFF",

  darkBlue: "#2F4E75",
  darkBlueHover: "#2E5983",
  darkBlueActive: "#2D6492",

  textGreen: "#32DA6D",
  green: "#1EC659",
  greenHover: "#28D063",
  greenActive: "#32DA6D",

  textPink: "#FF82AA",
  pink: "#FE477F",
  pinkHover: "#FF5189",
  pinkActive: "#FF5B93",

  textDarkGrey: "#B4BADA",
  darkGrey: "#474E6D",
  darkGreyHover: "#525978",
  darkGreyActive: "#5D6483",

  darkGreen: "#31585B",
  darkGreenHover: "#31695E",
  darkGreenActive: "#317960",

  darkPink: "#5A3D61",
  darkPinkHover: "#6F4066",
  darkPinkActive: "#83426B",

  wallet: "#242B48",
  walletHover: "#343A52",
  walletActive: "#444A63",
};
