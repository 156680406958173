import { t } from "i18next";
import { providers } from "near-api-js";
import { useEffect } from "react";

import { explorerUrl, nodeUrl } from "services/config";
import ToastService from "shared/components/Toast";
import { CLAIM_TOKEN_METADATA_KEY, EMPTY_STRING, RECEIVER_ID, ZERO_STRING } from "shared/constants";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { ITokenMetadata } from "shared/interfaces";
import { isNotNullOrUndefined } from "shared/utils";
import { formatUnits } from "shared/utils/calculations";
import { selectAccountId } from "store/slices/user";

const FUNCTION_CALL = "FunctionCall";
const TRANSACTION_HASHES = "transactionHashes";
const ERROR_CODE = "errorCode";
const ERROR_MESSAGE = "errorMessage";
const SUCCESS_VALUE = "SuccessValue";

const methodsName = {
  confirm: "confirm",
  ft_transfer_call: "ft_transfer_call",
  deploy_lockup_contract: "deploy_lockup_contract",
  claim: "claim",
  terminate: "terminate",
};

enum TransactionType {
  None = "none",
  FtTransferCall = "ftTransferCall",
  DeployLockup = "deployLockup",
  Claim = "claim",
  Terminate = "terminate",
}

enum StatusType {
  None,
  SuccessValue,
  Failure,
}

interface IDetailsTransaction {
  methodName: string;
  href: string;
  status: StatusType;
  type: TransactionType;
  title: string;
  additionalText: string | null;
}

const localStorageKeys = {
  receiverId: RECEIVER_ID,
  tokenMetadata: CLAIM_TOKEN_METADATA_KEY,
};

const clearHash = (queryParams: URLSearchParams) => {
  const url = new URL(window.location.href);
  if (queryParams.has(TRANSACTION_HASHES)) queryParams.delete(TRANSACTION_HASHES);

  if (queryParams.has(ERROR_CODE) || queryParams.has(ERROR_MESSAGE)) {
    queryParams.delete(ERROR_CODE);
    queryParams.delete(ERROR_MESSAGE);
  }
  window.history.replaceState({}, document.title, url.pathname);
};

const clearLocalStorage = () => Object.values(localStorageKeys).forEach((key) => localStorage.removeItem(key));

const retrieveTypeTransaction = (methodName: string) => {
  switch (methodName) {
    case methodsName.ft_transfer_call: {
      return TransactionType.FtTransferCall;
    }
    case methodsName.deploy_lockup_contract: {
      return TransactionType.DeployLockup;
    }
    case methodsName.claim: {
      return TransactionType.Claim;
    }
    case methodsName.terminate: {
      return TransactionType.Terminate;
    }
    default: {
      return TransactionType.None;
    }
  }
};

const retrieveTitle = (type: TransactionType): string => {
  switch (type) {
    case TransactionType.FtTransferCall: {
      {
        const receiverId = localStorage.getItem(localStorageKeys.receiverId);
        return receiverId ? t(`toast.${type}`, { receiverId }) : t(`toast.ftTransferCallWithoutReceiverId`);
      }
    }
    case TransactionType.DeployLockup: {
      {
        const receiverId = localStorage.getItem(localStorageKeys.receiverId);
        return receiverId ? t(`toast.${type}`, { receiverId }) : t(`toast.deployLockupWithoutReceiverId`);
      }
    }
    default:
      return t(`toast.${type}`);
  }
};

const retrieveAdditionalText = (transaction: providers.FinalExecutionOutcome, type: TransactionType) => {
  switch (type) {
    case TransactionType.FtTransferCall: {
      return t("toast.outgoingVisible");
    }
    case TransactionType.Claim: {
      const localStorageToken = localStorage.getItem(localStorageKeys.tokenMetadata);
      if (!localStorageToken) return null;
      const metadata: ITokenMetadata = JSON.parse(localStorageToken);
      const successValue = (transaction.status as providers.FinalExecutionStatus).SuccessValue || EMPTY_STRING;
      const buff = Buffer.from(successValue, "base64");
      const value = buff.toString("ascii").replace(/"/g, EMPTY_STRING) || ZERO_STRING;
      const readableValue = formatUnits(value, metadata.decimals);
      return t("toast.singleClaim", { amount: readableValue, symbol: metadata.symbol });
    }
    default:
      return null;
  }
};

const detailsTransaction = (transaction: providers.FinalExecutionOutcome): IDetailsTransaction | null => {
  const action = transaction.transaction.actions[0];
  const isTrxHasProperty = Object.prototype.hasOwnProperty.call(action, FUNCTION_CALL);
  if (!isTrxHasProperty) return null;
  const methodName: string = action[FUNCTION_CALL].method_name;
  const type = retrieveTypeTransaction(methodName);
  const successStatus = Object.prototype.hasOwnProperty.call(transaction.status, SUCCESS_VALUE);
  const hash = transaction.transaction.hash as string;
  const title = retrieveTitle(type);
  const additionalText = retrieveAdditionalText(transaction, type);
  return {
    methodName,
    href: `${explorerUrl}/transactions/${hash}`,
    status: successStatus ? StatusType.SuccessValue : StatusType.Failure,
    type,
    title,
    additionalText,
  };
};

export function parseTransactions(transactions: providers.FinalExecutionOutcome[]) {
  const suitableTransactions = transactions.map((tx) => detailsTransaction(tx)).filter(isNotNullOrUndefined);
  if (!suitableTransactions.length) return;

  const isMultipleClaim = suitableTransactions.filter(({ type }) => type === TransactionType.Claim).length > 1;
  const uniqSuitableTransactions = suitableTransactions.reduce((acc: IDetailsTransaction[], transaction) => {
    if (!acc.find((v) => v.type == transaction.type)) acc.push(transaction);
    return acc;
  }, []);

  uniqSuitableTransactions.forEach((transaction) => {
    if (transaction.type === TransactionType.None) return;
    if (transaction.status === StatusType.SuccessValue) {
      const additionalText = isMultipleClaim ? t("toast.multipleClaim") : transaction.additionalText;
      ToastService.success({ text: transaction.title, additionalText });
    } else if (transaction.status === StatusType.Failure) {
      ToastService.error({ text: transaction.title, href: transaction.href });
    }
  });
  clearLocalStorage();
}

export default function useTransactionHash() {
  const accountId = useAppSelector(selectAccountId);
  const url = new URL(window.location.href);
  return useEffect(() => {
    if (accountId) {
      const queryParams = new URLSearchParams(url.search);
      const transactions = queryParams?.get(TRANSACTION_HASHES);
      const errorCode = queryParams?.get(ERROR_CODE);
      const errorMessage = queryParams?.get(ERROR_MESSAGE);
      if (errorCode || errorMessage) ToastService.error({ text: t("toast.error.userRejected") });
      clearHash(queryParams);

      if (transactions) {
        const provider = new providers.JsonRpcProvider({ url: nodeUrl });

        try {
          Promise.all(transactions.split(",").map((txHash) => provider.txStatus(txHash, accountId)))
            .then((res) => parseTransactions(res))
            .catch((e) => console.warn(`${e} error while parse transactions`));
        } catch (e) {
          console.warn(`${e} error while loading tx`);
        }
      }
    }
  }, [accountId, url.search]);
}
