import { t } from "i18next";
import { Trans } from "react-i18next";

import usdt from "assets/images/token-icons/USDT.svg";
import ButtonDetails from "shared/components/Buttons/Additional/ButtonDetails";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { StageLabel } from "shared/components/StageLabel";
import TokenLogo from "shared/components/TokenLogo";
import { Translate } from "shared/components/Translate";
import { HUNDRED_STRING } from "shared/constants";
import { ETypeLockup } from "shared/interfaces";
import { formatDate, getCurrentTimestamp } from "shared/utils";

import styles from "./styles";

const token = {
  metadata: {
    icon: usdt,
    symbol: "USDT",
  },
};

const lockup = {
  distributionAmount: "2385",
  tokenAmountPerDay: "6.5",
  availableToClaimValue: "1464.07",
  progress: 40,
  unlocksDate: getCurrentTimestamp(),
};

export default function Card() {
  return (
    <styles.LockupItemWrapper>
      <styles.Row>
        <styles.LogoTitleWrapper>
          <TokenLogo icon={token.metadata.icon} alt={token.metadata.symbol} rounded />
          <styles.Title>
            <Trans
              i18nKey={"lockup.mainData.title"}
              values={{
                amount: lockup.distributionAmount,
                symbol: token.metadata.symbol,
              }}
            />
          </styles.Title>
        </styles.LogoTitleWrapper>
        <StageLabel type={ETypeLockup.Incoming} value={lockup.tokenAmountPerDay} symbol={token.metadata.symbol} />
      </styles.Row>
      <styles.Row>
        <styles.AvailableBalance>
          <span>
            <Translate value="lockup.mainData.available" />
          </span>
          <span>
            <Trans
              i18nKey={"lockup.mainData.availableToClaimValue"}
              values={{ amount: lockup.availableToClaimValue, symbol: token.metadata.symbol }}
            />
          </span>
        </styles.AvailableBalance>
        <styles.ScheduleWrapper>
          <styles.ProgressBar max={HUNDRED_STRING} value={lockup.progress} />
          <span>
            <Trans i18nKey="lockup.mainData.unlocks" values={{ value: formatDate(lockup.unlocksDate) }} />
          </span>
        </styles.ScheduleWrapper>
      </styles.Row>
      <styles.Row removeIndents>
        <ButtonPrimary name={t("actions.claim")} handler={() => undefined} color={EButtonColors.DARK_GREEN} />
        <ButtonDetails disabled={false} isOpen={false} onClick={() => undefined} />
      </styles.Row>
      <styles.FirstShadowBlock />
      <styles.SecondShadowBlock />
    </styles.LockupItemWrapper>
  );
}
