import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";

import { IModalControlsHeaderProps } from "./interface";
import styles from "./styles";

export function ModalControlsHeader({ title, leftControl, rightControl }: IModalControlsHeaderProps) {
  return (
    <styles.ModalControlsHeaderWrapper>
      <div>
        <ButtonIcon control={leftControl} />
      </div>
      <styles.Title>{title}</styles.Title>
      <div>
        <ButtonIcon control={rightControl} />
      </div>
    </styles.ModalControlsHeaderWrapper>
  );
}
