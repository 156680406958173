import { useFormContext, useWatch } from "react-hook-form";
import { Trans } from "react-i18next";

import { DatePicker, Input } from "shared/components/Input";
import Autocomplete from "shared/components/Input/Autocomplete";
import { StageLabel } from "shared/components/StageLabel";
import { Translate } from "shared/components/Translate";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { ETypeLockup, ISecondStageData } from "shared/interfaces";
import { calcAmountPerDayByPeriod, calcBalanceForPeriod } from "shared/utils/calculations";
import { selectToken } from "store/selectors/selectToken";

import { IPeriod } from "./interfaces";
import styles from "./styles";
import WrapperInputStyles from "../WrapperInputStyles";

export const Period: React.FC<IPeriod> = ({
  id,
  endDate,
  amount,
  tokenAddress,
  balances,
  remove,
  periodsLength,
}: IPeriod) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<ISecondStageData>();
  const token = useAppSelector((state) => selectToken(state, tokenAddress));
  const prevId = id === 0 ? id : id - 1;
  const prevPeriodData = useWatch({ name: `schedule.${prevId}`, control });
  const newPeriodData = useWatch({ name: `schedule.${id}`, control });

  if (!token) return null;
  const period = id + 1;
  const firstPeriod = period === 1;
  const startDate = firstPeriod ? getValues(`startDateISOFormat`) : prevPeriodData.endDateISOFormat;
  const currentEndDate = endDate || newPeriodData.endDateISOFormat;
  const currentAmount = amount || newPeriodData.amount;
  const tokenAmountPerDay = calcAmountPerDayByPeriod(startDate, currentEndDate, currentAmount);
  const currentBalance = firstPeriod ? balances : calcBalanceForPeriod(balances, prevPeriodData.amount);
  const isStartDateInputDisabled = !firstPeriod;
  const disabledDeletePeriod = periodsLength === 1;
  return (
    <styles.Container>
      <styles.Header>
        <styles.Title>
          <Trans
            i18nKey={"createLockup.period.title"}
            values={{
              period,
            }}
          />
        </styles.Title>
        <StageLabel type={ETypeLockup.Outgoing} value={tokenAmountPerDay} symbol={token.metadata.symbol} />
      </styles.Header>
      <WrapperInputStyles.Container>
        <WrapperInputStyles.Label>
          <div>
            <Translate value="createLockup.period.lockAmount" />
            <span>*</span>
          </div>
          <div>
            <styles.Wallet />
            <Trans i18nKey="tokenAmount" values={{ amount: currentBalance, symbol: token.metadata.symbol }} />
          </div>
        </WrapperInputStyles.Label>
        <WrapperInputStyles.Border error={Boolean(errors.schedule?.[id]?.amount)}>
          <Autocomplete metadata={token.metadata} disabled={!firstPeriod}>
            <Input id={`schedule.${id}.amount`} placeholder="0.0" type="number" />
          </Autocomplete>
        </WrapperInputStyles.Border>
        {errors.schedule?.[id]?.amount && (
          <WrapperInputStyles.Error>{errors.schedule?.[id]?.amount?.message}</WrapperInputStyles.Error>
        )}
      </WrapperInputStyles.Container>
      <styles.Row>
        <WrapperInputStyles.Container>
          <WrapperInputStyles.Label>
            <div>
              <Translate value="createLockup.period.startDate" />
              <span>*</span>
            </div>
          </WrapperInputStyles.Label>
          <WrapperInputStyles.Border error={firstPeriod && Boolean(errors.startDateISOFormat)}>
            <DatePicker id="startDateISOFormat" value={startDate} disabled={isStartDateInputDisabled} />
          </WrapperInputStyles.Border>
          {firstPeriod && errors.startDateISOFormat && (
            <WrapperInputStyles.Error>{errors.startDateISOFormat.message}</WrapperInputStyles.Error>
          )}
        </WrapperInputStyles.Container>
        <WrapperInputStyles.Container>
          <WrapperInputStyles.Label>
            <div>
              <Translate value="createLockup.period.endDate" />
              <span>*</span>
            </div>
          </WrapperInputStyles.Label>
          <WrapperInputStyles.Border error={Boolean(errors.schedule?.[id]?.endDateISOFormat)}>
            <DatePicker id={`schedule.${id}.endDateISOFormat`} deps={`startDateISOFormat`} />
          </WrapperInputStyles.Border>
          {errors.schedule?.[id]?.endDateISOFormat && (
            <WrapperInputStyles.Error>{errors.schedule?.[id]?.endDateISOFormat?.message}</WrapperInputStyles.Error>
          )}
        </WrapperInputStyles.Container>
      </styles.Row>
      <styles.DeletePeriod type="button" disabled={disabledDeletePeriod} onClick={remove}>
        <Trans
          i18nKey={"createLockup.period.remove"}
          values={{
            period,
          }}
        />
      </styles.DeletePeriod>
    </styles.Container>
  );
};
