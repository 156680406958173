import { useInView } from "framer-motion";
import { useRef } from "react";

import dao from "assets/images/landing-images/dao.svg";
import fullyDecentralized from "assets/images/landing-images/fully-decentralized.svg";
import lockupForPartner from "assets/images/landing-images/lockup-for-partner.svg";
import { Translate } from "shared/components/Translate";

import styles from "./styles";

const featuresList = [
  {
    id: 1,
    logo: fullyDecentralized,
  },
  {
    id: 2,
    logo: lockupForPartner,
  },
  {
    id: 3,
    logo: dao,
  },
];

export default function Features() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <styles.Section ref={ref} isInView={isInView}>
      <styles.Title>
        <Translate value="landing.features.title" />
      </styles.Title>
      <styles.List>
        {featuresList.map(({ id, logo }) => (
          <styles.Element key={id}>
            <img src={logo} loading="lazy" />
            <styles.ElementTitle>
              <p>
                <Translate value={`landing.features.cardTitle${id}`} />
              </p>
              <p>
                <Translate value={`landing.features.cardDescription${id}`} />
              </p>
            </styles.ElementTitle>
          </styles.Element>
        ))}
      </styles.List>
    </styles.Section>
  );
}
