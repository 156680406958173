import { v4 as uuid } from "uuid";

import { ITwoColumnListProps } from "./interface";
import styles from "./styles";

export default function TwoColumnList({ list, title, largeList = false }: ITwoColumnListProps) {
  return (
    <>
      {title && <styles.Title>{title}</styles.Title>}
      {list.map((listItem) => (
        <styles.Element key={uuid()} $largeList={largeList}>
          <styles.Description>{listItem.title}</styles.Description>
          <styles.Value isStaticWidth={Boolean(listItem.valueAddition)}>
            {listItem.value}
            {listItem.valueAddition && <styles.ValueAddition>{listItem.valueAddition}</styles.ValueAddition>}
          </styles.Value>
        </styles.Element>
      ))}
    </>
  );
}
