import styled from "styled-components";

import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const CliffContainer = styled.div`
  ${flexColumnNoWrap}
  flex-shrink: 0;
  margin: 3rem 0 2rem;
`;

const InputContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
`;

const InputWrapper = styled.div`
  ${flexRowNoWrap}
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  width: 100%;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
`;

const DatePickerToggle = styled.div`
  display: flex;
  align-items: center;
  width: 2rem;
  height: 1.5rem;
  input {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
  button {
    padding: 0;
  }
`;

export default {
  CliffContainer,
  InputContainer,
  InputWrapper,
  DatePickerToggle,
};
