import styled from "styled-components";

import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { flexRowNoWrap } from "shared/theme/styles";

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  & > span {
    padding: 0.313rem 0.313rem 0 0;
  }
`;

const Title = styled.h2`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: center;
  margin: 1.25rem 0 1rem 0;
`;

const Description = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.textGrey};
  margin-bottom: 2.875rem;
`;

const ListWrapper = styled.div`
  margin-bottom: 3.25rem;
  ${flexRowNoWrap}
  justify-content: space-between;
  p {
    font-weight: 400;
    overflow: hidden;
    line-height: 1rem;
    font-size: 0.75rem;
    width: fit-content;
    :first-child {
      color: ${({ theme }) => theme.colors.textGrey};
      max-width: 11.125rem;
    }
    :last-child {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export default {
  Header,
  CloseIcon,
  Title,
  Description,
  ListWrapper,
};
