import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { flexRowNoWrap } from "shared/theme/styles";

const Header = styled(motion.header)`
  max-width: 68.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 3rem;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        padding: 2.5rem 0;
      `
    )}
`;

const RightContainer = styled.div`
  ${flexRowNoWrap}
  gap: 3rem;
  button {
    width: 10.25rem;
    font-size: 1rem;
    font-weight: 600;
  }
`;

const SocialsContainer = styled.div`
  ${flexRowNoWrap}
  & > a {
    height: 2.25rem;
    width: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SocialImage = styled.img`
  max-width: 1.625rem;
  width: 100%;
  max-height: 1.625rem;
`;

export default {
  Header,
  SocialsContainer,
  SocialImage,
  RightContainer,
};
