import StatisticsStyles from "pages/HomePage/components/Statistics/styles";

import { StatisticsCard } from "../StatisticsCard";

export const Statistics: React.FC = () => (
  <StatisticsStyles.Container>
    <StatisticsCard />
    <StatisticsCard />
  </StatisticsStyles.Container>
);
