import { t } from "i18next";
import { Trans } from "react-i18next";

import { useWalletSelector } from "providers/WalletProvider";
import { NEAR_TOKEN } from "services/contracts/FungibleToken";
import { ButtonIcon } from "shared/components/Buttons/ButtonIcon";
import { ButtonsGroup } from "shared/components/Buttons/ButtonsGroup";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import { Translate } from "shared/components/Translate";
import { DEPLOY_CONTRACT_FEE } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { IModalProps } from "shared/interfaces";
import { formatUnits } from "shared/utils/calculations";
import { deployContract } from "store/actions/deployContract";

import styles from "./styles";
import ModalWrapper from "../ModalWrapper";

export const DeployContractModal: React.FC<IModalProps> = ({ closeModal }: IModalProps) => {
  const dispatch = useAppDispatch();
  const { requestSignTransactions, RPCProvider } = useWalletSelector();

  const amount = formatUnits(DEPLOY_CONTRACT_FEE, NEAR_TOKEN.decimals);
  const symbol = NEAR_TOKEN.symbol;

  const deployContractHandler = () => {
    dispatch(deployContract({ provider: RPCProvider, requestSignTransactions }));
  };

  return (
    <ModalWrapper closeModal={closeModal} widthInRem="20.5">
      <styles.Header>
        <ButtonIcon control={{ icon: <styles.CloseIcon />, handler: closeModal }} />
      </styles.Header>
      <styles.Title>
        <Translate value="modals.deployContract.title" />
      </styles.Title>
      <styles.Description>
        <Translate value="modals.deployContract.description" />
      </styles.Description>
      <styles.ListWrapper>
        <p>
          <Translate value="modals.deployContract.fee" />
        </p>
        <p>
          <Trans i18nKey="lockup.details.amountValue" values={{ amount, symbol }} />
        </p>
      </styles.ListWrapper>
      <ButtonsGroup
        cancelButtonHandler={closeModal}
        rightButton={{
          name: t("actions.deployContract"),
          handler: () => {
            deployContractHandler();
            closeModal();
          },
          color: EButtonColors.BLUE,
        }}
      />
    </ModalWrapper>
  );
};
