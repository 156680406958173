import { useCycle } from "framer-motion";
import { Trans } from "react-i18next";

import { ZERO_STRING } from "shared/constants";
import { ETypeLockup } from "shared/interfaces";
import { calculatedPrice, displayBalance } from "shared/utils";
import { BigNumber } from "shared/utils/calculations";

import { IStatisticsCard } from "./interface";
import styles from "./styles";
import { SlideDown } from "../Animations";
import ButtonDetails from "../Buttons/Additional/ButtonDetails";
import { StageLabel } from "../StageLabel";
import TokenList from "../TokenList";
import { Translate } from "../Translate";

const StatisticsCard: React.FC<IStatisticsCard> = ({
  children,
  typeLockup,
  amount,
  details,
  valuePerDay,
}: IStatisticsCard) => {
  const [isOpenDetails, openDetails] = useCycle(false, true);
  const isIncoming = typeLockup === ETypeLockup.Incoming;
  const fiatAvailableToClaim = calculatedPrice(details);
  const isFiatSumAvailable = !BigNumber(fiatAvailableToClaim).eq(ZERO_STRING);

  return (
    <styles.Container>
      <styles.Row>
        <styles.Column>
          <styles.Title>
            <Translate value={isIncoming ? "statistic.lockups" : "statistic.receivers"} />
          </styles.Title>
          <styles.Label>{amount}</styles.Label>
        </styles.Column>
        <StageLabel type={typeLockup} value={valuePerDay} isFiatValue />
      </styles.Row>
      <styles.Row>
        <styles.Column>
          <styles.Title>
            <Translate value={isIncoming ? "statistic.availableToClaim" : "statistic.sending"} />
          </styles.Title>
          <styles.Label>
            {isFiatSumAvailable ? (
              <Trans i18nKey="fiatValue" values={{ value: displayBalance(fiatAvailableToClaim) }} />
            ) : (
              ZERO_STRING
            )}
          </styles.Label>
        </styles.Column>
        <ButtonDetails disabled={!details.length} isOpen={isOpenDetails} onClick={openDetails} />
      </styles.Row>
      <SlideDown show={isOpenDetails && !!details.length}>
        <TokenList list={details} marginBottom={1} />
      </SlideDown>
      {children}
    </styles.Container>
  );
};

export default StatisticsCard;
