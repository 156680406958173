import styled, { css } from "styled-components";

import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const placeholderBg = css`
  background-color: ${({ theme }) => theme.colors.placeholderElement};
  box-shadow: 0px 4px 12px -4px ${({ theme }) => theme.colors.boxShadowCard};
`;

const Container = styled.div`
  ${flexColumnNoWrap}
  background: ${({ theme }) => theme.colors.cardBg};
  box-shadow: 0px 4px 12px -4px ${({ theme }) => theme.colors.boxShadowCard};
  border-radius: 1.5rem;
  padding: 1rem;
  width: 17.5rem;
  gap: 1rem;
  & > div {
    :last-child {
      margin-top: 0.625rem;
    }
  }
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        width: 20.5rem;
      `
    )}
`;

const Row = styled.div`
  ${flexRowNoWrap}
  justify-content: space-between;
  & > div {
    ${flexColumnNoWrap}
    gap: .5rem;
  }
`;

const Title = styled.div`
  width: 4rem;
  height: 0.625rem;
  border-radius: 0.25rem;
  ${placeholderBg}
`;

const Label = styled.div`
  width: 2rem;
  height: 1rem;
  border-radius: 0.25rem;
  ${placeholderBg}
`;

const StageLabel = styled.div`
  width: 4rem;
  height: 1.5rem;
  border-radius: 0.5rem;
  ${placeholderBg}
`;

export default {
  Container,
  Row,
  Title,
  Label,
  StageLabel,
};
