import styled from "styled-components";

import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  & > span {
    padding: 0.313rem 0.313rem 0 0;
  }
`;

const Title = styled.h2`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: center;
  margin: 1.25rem 0 2.25rem 0;
`;

const ListWrapper = styled.div`
  margin-bottom: 4.5625rem;
`;

export default {
  Header,
  CloseIcon,
  Title,
  ListWrapper,
};
