import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const MotionRow = styled(motion.div)<{ $isClickable: boolean; $marginBottom?: number }>`
  ${flexRowNoWrap}
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  ${({ $isClickable }) =>
    $isClickable &&
    css`
      cursor: pointer;
      :hover {
        background: ${({ theme }) => theme.colors.tokenListElement};
      }
    `}
  ${({ $marginBottom }) =>
    $marginBottom &&
    css`
      margin-bottom: 1rem;
    `}
`;

const Description = styled.div`
  ${flexColumnNoWrap}
  flex-grow: 2;
  margin-left: 0.5rem;
  grid-gap: 0.125rem;
  font-size: 0.75rem;
  line-height: 0.938rem;
`;

const TokenTitle = styled.div`
  ${flexRowNoWrap}
  justify-content: space-between;
  font-weight: 600;
  gap: 1rem;
`;

const TokenSubtitle = styled.div`
  ${flexRowNoWrap}
  gap: 1rem;
  justify-content: space-between;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textGrey};
`;

export default {
  MotionRow,
  Description,
  TokenTitle,
  TokenSubtitle,
};
