import { FiatValueManager } from "services/api";
import { ITokenPrice } from "shared/interfaces";
import { isNotNullOrUndefined, toMap } from "shared/utils";
import { BigNumber } from "shared/utils/calculations";

export async function retrievePrices(tokenAddresses: string[]): Promise<{ [key: string]: ITokenPrice }> {
  const [jumboPrices, refPriceData] = await Promise.all([
    FiatValueManager.getJumboPriceData(),
    FiatValueManager.getRefPriceData(),
  ]);

  const prices = tokenAddresses
    .map((tokenId) => {
      const jumboPrice = jumboPrices[tokenId] || null;
      const refPrice = refPriceData[tokenId] || null;
      if (jumboPrice && refPrice) {
        const averagePrice = BigNumber(jumboPrice.price).add(BigNumber(refPrice.price)).div(2).toFixed(2);
        return { ...jumboPrice, price: averagePrice };
      }
      return jumboPrice || refPrice || null;
    })
    .filter(isNotNullOrUndefined);
  return toMap(prices, "id");
}

export const getPrice = (id: string, prices: { [key: string]: ITokenPrice }): ITokenPrice | null => prices[id] || null;
