import styled, { keyframes } from "styled-components";

import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const Approve = styled.div`
  ${flexRowNoWrap}
  align-items: start;
  position: relative;
`;

const bounce = keyframes`
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
`;

const AcceptInput = styled.label`
  position: relative;
  input,
  svg {
    width: 1rem;
    height: 1rem;
    display: block;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: ${({ theme }) => theme.colors.transparent};
    border: none;
    margin: 0.063rem 0 0 0.25rem;
    cursor: pointer;
    border-radius: 6px;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 0.063rem rgba(${({ theme }) => theme.colors.grey}, 0.6);
    :hover {
      box-shadow: inset 0 0 0 0.125rem rgba(${({ theme }) => theme.colors.grey}, 0.6);
    }
    :checked {
      box-shadow: inset 0 0 0 0.688rem ${({ theme }) => theme.colors.blue};
      & + svg {
        animation: ${bounce} 0.4s linear forwards 0.2s;
      }
    }
    :disabled {
      box-shadow: inset 0 0 0 0.063rem rgba(${({ theme }) => theme.colors.grey}, 0.6);
      cursor: not-allowed;
    }
  }
  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 1px;
    left: 4px;
    transform: scale(0) translateZ(0);
  }
`;

const ApproveDescription = styled.div`
  margin: 0.063rem 0 0 0.5rem;
  ${flexColumnNoWrap}
  gap: .5rem;
  p {
    font-weight: 400;
    :first-child {
      font-size: 0.875rem;
      line-height: 1.063rem;
      color: ${({ theme }) => theme.colors.white};
    }
    :last-child {
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: ${({ theme }) => theme.colors.textGrey};
    }
  }
`;

export default {
  Approve,
  AcceptInput,
  ApproveDescription,
};
