import { createAsyncThunk } from "@reduxjs/toolkit";

import { IRPCProviderService } from "services/RPCProviderService";
import { setCreateLockupInitialData } from "store/slices/createLockup";
import { closeModal } from "store/slices/modals";

import { loadLockups } from "./loadLockups";
import { RootState } from "../index";

export const updateState = createAsyncThunk<
  void,
  {
    provider: IRPCProviderService;
    contractId: string;
  },
  { state: RootState }
>("lockups/updateApp", async ({ provider, contractId }, { dispatch }) => {
  try {
    dispatch(loadLockups({ provider, contractId }));
    dispatch(setCreateLockupInitialData());
    dispatch(closeModal());
  } catch (e) {
    console.error(`Error: while initial loading lockups from factory \n ${e}`);
  }
});
