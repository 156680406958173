import { useNavigate } from "react-router-dom";

import { ReactComponent as LogoLockup } from "assets/images/logo/lockup-logo.svg";
import { APP_ROUTES } from "routes/appRoutes";

import { ILogoProps } from "./interface";
import styles from "./styles";

export const Logo: React.FC<ILogoProps> = ({ width }: ILogoProps) => {
  const navigate = useNavigate();
  const redirect = () => navigate(APP_ROUTES.LANDING);
  return (
    <styles.LogoWrapper width={width} onClick={redirect}>
      <LogoLockup />
    </styles.LogoWrapper>
  );
};
