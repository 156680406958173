import { Trans } from "react-i18next";

import { ReactComponent as Dashboard } from "assets/images/icons/dashboard.svg";
import { ButtonFAB } from "shared/components/Buttons/ButtonFAB";
import Header from "shared/components/Header";
import { LockupsBlock } from "shared/components/LockupsBlock";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { selectLoading } from "store/selectors/selectLoading";
import { closeModal, EModals, selectModalState, showModal } from "store/slices/modals";

import styles from "./styles";

const MobilePage: React.FC = () => {
  const loading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();
  const { modal } = useAppSelector(selectModalState);
  const isOpenedStatisticsModal = modal === EModals.STATISTICS_MODAL;

  const modalAction = isOpenedStatisticsModal
    ? closeModal()
    : showModal({ modal: EModals.STATISTICS_MODAL, props: {} });

  return (
    <styles.Container>
      <Header />
      <LockupsBlock showSwitcher />
      <ButtonFAB
        loading={loading}
        isOpened={isOpenedStatisticsModal}
        iconToOpen={loading ? <></> : <Dashboard />}
        handleClick={() => dispatch(modalAction)}
      />
      <styles.Footer>
        <Trans i18nKey="footer" values={{ year: new Date().getUTCFullYear() }} />
      </styles.Footer>
    </styles.Container>
  );
};

export default MobilePage;
