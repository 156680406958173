import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { flexColumnNoWrap } from "shared/theme/styles";

const Section = styled(motion.section)`
  ${flexColumnNoWrap}
`;

const Title = styled.div<{ clearMargin: boolean }>`
  max-width: 36.25rem;
  width: 100%;
  margin-bottom: ${({ clearMargin }) => (clearMargin ? "0" : "6rem")};
  h1 {
    text-align: center;
    margin: 0 0 2rem;
    font-weight: 800;
    font-size: 3rem;
    line-height: 3.625rem;
    background: ${({ theme }) => theme.colors.landingLinearGradient};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
  p {
    text-align: center;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: ${({ theme }) => theme.colors.textGrey};
  }

  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        h1 {
          font-size: 2.25rem;
          line-height: 2.688rem;
          margin-bottom: 1.5rem;
        }
        p {
          font-size: 1rem;
          line-height: 1.375rem;
        }
      `
    )}
`;

const WrapperButton = styled.div`
  margin: 2.25rem 0 4.5rem;
  align-self: center;
  button {
    width: 10.25rem;
    font-size: 1rem;
    font-weight: 600;
  }
`;

export default {
  Section,
  Title,
  WrapperButton,
};
