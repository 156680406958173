import { Trans } from "react-i18next";

import Header from "shared/components/Header";
import { LockupsBlock } from "shared/components/LockupsBlock";
import { Logo } from "shared/components/Logo";

import styles from "./styles";
import Statistics from "../Statistics";

const DesktopPage: React.FC = () => {
  return (
    <styles.Container>
      <styles.SideComponent>
        <Logo width={7.5} />
        <Statistics />
        <styles.Footer>
          <Trans i18nKey="footer" values={{ year: new Date().getUTCFullYear() }} />
        </styles.Footer>
      </styles.SideComponent>
      <styles.SidePlaceholder />
      <styles.Column>
        <Header />
        <LockupsBlock />
      </styles.Column>
    </styles.Container>
  );
};

export default DesktopPage;
