import styled from "styled-components";

export const StyledInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  background: none;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.188rem;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 0.75rem;

  ::placeholder {
    color: ${({ theme }) => theme.colors.placeholderInput};
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default {
  StyledInput,
};
