import styled from "styled-components";

import { ReactComponent as WalletIcon } from "assets/images/icons/wallet.svg";
import { flexColumnNoWrap, flexRowNoWrap, borderTransition } from "shared/theme/styles";

const Container = styled.div`
  ${flexColumnNoWrap}
  gap: 0.25rem;
  width: 100%;
`;

const Border = styled.div<{ error?: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.borderInput)};
  border-radius: 0.5rem;
  min-height: 2.75rem;
  width: 100%;
  transition: ${borderTransition};
  :focus-within {
    border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.blue)};
    box-shadow: 0 0 0 3px ${({ theme, error }) => (error ? theme.colors.transparent : theme.colors.boxShadowInput)};
  }
`;

const Error = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-top: 0.25rem;
  padding: 0 0.75rem;
`;

const Label = styled.div`
  ${flexRowNoWrap}
  justify-content: space-between;
  align-items: center;
  padding: 0 0.75rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.textGrey};

  span {
    color: ${({ theme }) => theme.colors.error};
    margin-left: 0.125rem;
  }
  div {
    ${flexRowNoWrap}
  }
`;

const Wallet = styled(WalletIcon)`
  path {
    fill: ${({ theme }) => theme.colors.textGrey};
  }
`;

export default {
  Container,
  Wallet,
  Label,
  Border,
  Error,
};
