import { useCycle } from "framer-motion";
import { t } from "i18next";
import { Trans } from "react-i18next";

import { ReactComponent as Terminate } from "assets/images/icons/terminate-pink.svg";
import { useWalletSelector } from "providers/WalletProvider";
import { SlideDown } from "shared/components/Animations";
import ButtonDetails from "shared/components/Buttons/Additional/ButtonDetails";
import { ButtonPrimary } from "shared/components/Buttons/ButtonPrimary";
import { EButtonColors } from "shared/components/Buttons/interfaces";
import Divider from "shared/components/Divider";
import { StageLabel } from "shared/components/StageLabel";
import TokenLogo from "shared/components/TokenLogo";
import { Translate } from "shared/components/Translate";
import TwoColumnList from "shared/components/TwoColumnList";
import { HUNDRED_STRING } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { ELockupStage, ETypeLockup } from "shared/interfaces";
import { displayAmount, formatDate, getCurrentTimestamp } from "shared/utils";
import { BigNumber } from "shared/utils/calculations";
import { claim } from "store/actions/claim";
import { selectToken } from "store/selectors/selectToken";
import { EModals, showModal } from "store/slices/modals";

import { ILockupItemProps } from "./interface";
import styles from "./styles";

export function LockupItem({ lockup, typeLockup }: ILockupItemProps) {
  const token = useAppSelector((state) => selectToken(state, lockup.distributionTokenId));
  const dispatch = useAppDispatch();
  const { requestSignTransactions, RPCProvider } = useWalletSelector();
  const [isOpenDetails, openDetails] = useCycle(false, true);
  if (!token) return null;
  const decimals = token.metadata.decimals;
  const amountToClaim = displayAmount(lockup.calculatedTokenData.amountToClaim.toFixed(), decimals);
  const tokenAmountPerDay = displayAmount(lockup.calculatedTokenData.tokenAmountPerDay.toFixed(), decimals);

  const leftToUnlock = displayAmount(
    BigNumber(lockup.distributionAmount).minus(BigNumber(lockup.claimedAmount)).toFixed(),
    token.metadata.decimals
  );

  const detailsList = [
    {
      title: <Translate value="lockup.details.alreadyClaimed" />,
      value: displayAmount(lockup.claimedAmount, token.metadata.decimals),
    },
    {
      title: <Translate value="lockup.details.leftToUnlock" />,
      value: leftToUnlock,
    },
    {
      title: <Translate value="lockup.details.lockupPeriod" />,
      value: `${formatDate(lockup.startAt || lockup.distributionSchedule[0].endDate)} - ${formatDate(
        lockup.distributionSchedule[lockup.distributionSchedule.length - 1].endDate
      )}`,
    },
    {
      title: <Translate value="lockup.details.endCliffPeriod" />,
      value: lockup.cliff ? `${formatDate(lockup.cliff)}` : "-",
    },
    {
      title: <Translate value="lockup.details.owner" />,
      value: lockup.ownerId,
    },
    {
      title: <Translate value="lockup.details.receiver" />,
      value: lockup.receiver,
    },
  ];

  const claimHandler = () => {
    dispatch(claim({ provider: RPCProvider, lockupIds: [lockup.id], requestSignTransactions }));
  };

  const showTerminationModal = () => {
    dispatch(
      showModal({
        modal: EModals.TERMINATION_MODAL,
        props: {
          lockupId: lockup.id,
          receiverLockupId: lockup.contractId,
          amount: displayAmount(lockup.distributionAmount, token.metadata.decimals),
          symbol: token.metadata.symbol,
          receiver: lockup.receiver,
        },
      })
    );
  };

  const disabledClaim = lockup.stage === ELockupStage.Cliff || lockup.stage === ELockupStage.Created;
  const hideButton =
    BigNumber(lockup.distributionAmount).eq(lockup.claimedAmount) || typeLockup === ETypeLockup.Outgoing;
  const lastCheckpoint = lockup.distributionSchedule[lockup.distributionSchedule.length - 1].endDate;
  const lockupEnded = lastCheckpoint < getCurrentTimestamp();
  const canTerminate =
    typeLockup === ETypeLockup.Outgoing && lockup.canTerminate && !lockup.termination && !lockupEnded;

  return (
    <styles.LockupItemWrapper typeLockup={typeLockup}>
      <styles.Row>
        <styles.LogoTitleWrapper>
          <TokenLogo icon={token.metadata.icon} alt={token.metadata.name} rounded />
          <styles.Title>
            <Trans
              i18nKey={"lockup.mainData.title"}
              values={{
                amount: displayAmount(lockup.distributionAmount, token.metadata.decimals),
                symbol: token.metadata.symbol,
              }}
            />
          </styles.Title>
        </styles.LogoTitleWrapper>
        <StageLabel type={typeLockup} value={tokenAmountPerDay} symbol={token.metadata.symbol} stage={lockup.stage} />
      </styles.Row>
      <styles.Row>
        <styles.AvailableBalance>
          <span>
            <Translate value="lockup.mainData.available" />
          </span>
          <span>
            <Trans
              i18nKey={"lockup.mainData.availableToClaimValue"}
              values={{ amount: amountToClaim, symbol: token.metadata.symbol }}
            />
          </span>
        </styles.AvailableBalance>
        <styles.ScheduleWrapper>
          <styles.ProgressBar
            max={HUNDRED_STRING}
            value={lockup.calculatedTokenData.amountToClaimPercents}
            stage={lockup.stage}
          />
          <span>
            <Trans
              i18nKey={
                lockup.stage === ELockupStage.Terminated ? "lockup.mainData.terminated" : "lockup.mainData.unlocks"
              }
              values={{ value: formatDate(lockup.distributionSchedule[0].endDate) }}
            />
          </span>
        </styles.ScheduleWrapper>
      </styles.Row>
      <styles.Row removeIndents flexEnd={hideButton}>
        {!hideButton && (
          <styles.WrapperButton>
            <ButtonPrimary
              name={t("actions.claim")}
              handler={claimHandler}
              disabled={disabledClaim}
              color={EButtonColors.DARK_GREEN}
            />
          </styles.WrapperButton>
        )}
        <ButtonDetails disabled={!detailsList.length} isOpen={isOpenDetails} onClick={openDetails} />
      </styles.Row>
      <SlideDown show={isOpenDetails && !!detailsList.length}>
        <Divider />
        <TwoColumnList list={detailsList} />
        {canTerminate && (
          <styles.ButtonsWrapper>
            <ButtonPrimary
              name={t("actions.terminate")}
              handler={showTerminationModal}
              color={EButtonColors.DARK_PINK}
              iconLeft={<Terminate />}
            />
          </styles.ButtonsWrapper>
        )}
      </SlideDown>
    </styles.LockupItemWrapper>
  );
}
