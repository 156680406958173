import styled, { css } from "styled-components";

import { flexRowNoWrap, hoverTransition } from "shared/theme/styles";

import { getButtonStatusColors } from "./helpers";
import { EButtonColors } from "./interfaces";

const ButtonPrimaryWrapper = styled.button<{ fullWidth: boolean; color: EButtonColors }>`
  ${flexRowNoWrap}
  gap: 0.25rem;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5rem;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
  align-items: center;
  align-items: center;
  border-radius: 0.5rem;
  line-height: 1.188rem;
  justify-content: center;
  justify-content: center;
  color: ${({ color }) => getButtonStatusColors(color).text};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  background: ${({ color }) => getButtonStatusColors(color).default};
  transition: ${hoverTransition};

  &:hover {
    background: ${({ color }) => getButtonStatusColors(color).hover};
  }

  &:active {
    background: ${({ color }) => getButtonStatusColors(color).active};
  }

  &:disabled {
    opacity: 0.4;
    background: ${({ color }) => getButtonStatusColors(color).default};
    cursor: not-allowed;
  }
`;

const ButtonIconWrapper = styled.span`
  line-height: 0;
  cursor: pointer;
  display: inline-block;

  svg {
    path {
      transition: ${hoverTransition};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.textGrey};
      }
    }
  }
`;

const ButtonFABWrapper = styled.button<{ $loading: boolean }>`
  left: 48px;
  width: 3rem;
  border: none;
  height: 3rem;
  z-index: 199;
  bottom: 72px;
  display: flex;
  outline: none;
  position: fixed;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 11px 35px rgba(20, 25, 49, 0.9);
  transition: ${hoverTransition};
  ${({ theme, $loading }) =>
    $loading
      ? css`
          background-color: ${theme.colors.placeholder};
        `
      : css`
          background-color: ${theme.buttons.blue};
          &:hover {
            background: ${theme.buttons.blueHover};
          }
          &:active {
            background: ${theme.buttons.blueActive};
          }
        `}

  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        bottom: 45px;
        left: 8px;
      `
    )}
`;

const ButtonEmptyWrapper = styled(ButtonPrimaryWrapper)`
  background: ${({ theme }) => theme.colors.transparent};
  color: ${({ color }) => getButtonStatusColors(color).default};
  padding: 0;
  &:hover {
    background: ${({ theme }) => theme.colors.transparent};
    color: ${({ color }) => getButtonStatusColors(color).hover};
    svg {
      path {
        fill: ${({ color }) => getButtonStatusColors(color).active};
      }
    }
  }

  &:active {
    background: ${({ theme }) => theme.colors.transparent};
    color: ${({ color }) => getButtonStatusColors(color).active};
    svg {
      path {
        fill: ${({ color }) => getButtonStatusColors(color).active};
      }
    }
  }
`;

export default {
  ButtonPrimaryWrapper,
  ButtonIconWrapper,
  ButtonFABWrapper,
  ButtonEmptyWrapper,
};
