import { Action, FunctionCallAction } from "@near-wallet-selector/core";

export enum TransactionType {
  FunctionCall = "FunctionCall",
  CreateAccount = "CreateAccount",
  DeployContract = "DeployContract",
  Transfer = "Transfer",
  Stake = "Stake",
  AddKey = "AddKey",
  DeleteKey = "DeleteKey",
  DeleteAccount = "DeleteAccount",
}

export interface UpdatedFunctionCallAction extends FunctionCallAction {
  params: {
    methodName: string;
    args: {
      lockup_id?: number;
      account_id?: string;
      user_id?: string;
      receiver_id?: string;
      amount?: string;
      msg?: string;
    };
    gas: string;
    deposit: string;
  };
}

export type ITransaction<T extends Action = UpdatedFunctionCallAction> = {
  receiverId: string;
  actions: T[];
};

export * from "./modals.interfaces";
export * from "./lockup.interfaces";
export * from "./input.interfaces";
export * from "./buttons.interfaces";
export * from "./tokens.interfaces";
export * from "./createLockup.interfaces";
