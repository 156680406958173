import { createAsyncThunk } from "@reduxjs/toolkit";

import FungibleTokenContract from "services/contracts/FungibleToken";
import { retrieveBalances } from "shared/helpers/tokens";
import { setBalances } from "store/slices/user";

import { RootState } from "../index";

export const loadBalances = createAsyncThunk<
  void,
  {
    tokens: { [key: string]: FungibleTokenContract };
  },
  { state: RootState }
>("users/loadBalances", async ({ tokens }, { dispatch, getState }) => {
  try {
    const state = getState();
    const accountId = state.user.id;
    const balances = await retrieveBalances(tokens, accountId);

    if (!balances) return;

    dispatch(setBalances(balances));
  } catch (e) {
    console.error(`Error: while loading balances \n ${e}`);
  }
});
