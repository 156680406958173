import { useEffect } from "react";

import { useWalletSelector } from "providers/WalletProvider";
import { contractId } from "services/config";
import useTransactionHash from "services/receiptsService";
import { EDimensions, FIVE_SECOND } from "shared/constants";
import { useAppDispatch } from "shared/hooks/redux/useAppDispatch";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import { loadLockups } from "store/actions/loadLockups";
import { updateLockups } from "store/actions/updateLockups";

import DesktopPage from "./components/DesktopPage";
import MobilePage from "./components/MobilePage";

export const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { RPCProvider } = useWalletSelector();
  const dimensions = useWindowDimensions();
  useTransactionHash();

  useEffect(() => {
    dispatch(loadLockups({ provider: RPCProvider, contractId }));
  }, [RPCProvider, dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(updateLockups({ provider: RPCProvider }));
    }, FIVE_SECOND);
    return () => clearInterval(interval);
  }, [dispatch, RPCProvider]);

  const isLargeScreen =
    dimensions === EDimensions.LARGE || dimensions === EDimensions.EXTRA_LARGE || dimensions === EDimensions.UNKNOWN;

  return isLargeScreen ? <DesktopPage /> : <MobilePage />;
};
