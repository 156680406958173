import styled from "styled-components";

import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { flexRowNoWrap } from "shared/theme/styles";

const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  & > span {
    padding: 0.313rem 0.313rem 0 0;
  }
`;

const Title = styled.h2`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: center;
  margin: 1.25rem 0 1.5rem;
`;

const WalletContainer = styled.div`
  ${flexRowNoWrap}
  align-items: center;
  gap: 0.875rem;
  padding: 0.625rem;
  background: ${({ theme }) => theme.colors.accountIdBg};
  border-radius: 0.5rem;
  width: 100%;
  margin-bottom: 4.5rem;
  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default {
  Header,
  CloseIcon,
  Title,
  WalletContainer,
};
