import { z } from "zod";

import { walletDomains } from "services/config";
import i18n from "services/translation";
import { EMPTY_STRING, ZERO_STRING } from "shared/constants";
import { WALLET_CHARACTERS } from "shared/constants";
import { validateBalance, validateCliff, validateDate, validateStartDate } from "shared/helpers";
import { IBalance } from "shared/interfaces";
import store from "store";

import { getCurrentTimestamp, getDateInSeconds } from ".";
import { BigNumber } from "./calculations";
import { checkAccountIdAvailable } from "./checkAccountId";

export const firstStageSchema = z.object({
  receiver: z.string().superRefine(async (receiver, ctx) => {
    const validReceiver =
      walletDomains.some((domain) => receiver.endsWith(domain)) || receiver.length === WALLET_CHARACTERS;

    if (validReceiver) {
      const accountIdAvailable = await checkAccountIdAvailable(receiver);
      if (accountIdAvailable) return z.OK;
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("createLockup.receiver.errorInvalidUsername") || EMPTY_STRING,
      });
      return z.INVALID;
    }
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        i18n.t("createLockup.receiver.errorAccountId", { walletDomain: walletDomains.join(", ") }) || EMPTY_STRING,
    });
    return z.INVALID;
  }),
  note: z.string(),
  comment: z.string(),
  thirdPartyOwner: z.string(),
});

const scheduleSchema = z.object({
  endDateISOFormat: z
    .string()
    .min(1, i18n.t("createLockup.schedule.emptyDate") || EMPTY_STRING)
    .superRefine((endDate, ctx) => {
      const currentTimeStamp = getCurrentTimestamp();
      const date = getDateInSeconds(endDate);
      if (currentTimeStamp < date) return z.OK;
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
      });
      return z.INVALID;
    }),
  amount: z
    .string()
    .min(1, i18n.t("createLockup.schedule.emptyAmount") || EMPTY_STRING)
    .refine((amount) => BigNumber(amount).gt(ZERO_STRING), {
      message: i18n.t("createLockup.schedule.negativeAmount") || EMPTY_STRING,
    }),
});

export const secondStageSchema = z
  .object({
    canTerminate: z.boolean(),
    token: z.string(),
    startDateISOFormat: z
      .string()
      .min(1, i18n.t("createLockup.schedule.emptyDate") || EMPTY_STRING)
      .superRefine((startDate, ctx) => {
        const currentTimeStamp = getCurrentTimestamp();
        const date = getDateInSeconds(startDate);
        if (currentTimeStamp < date) return z.OK;
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
        });
        return z.INVALID;
      }),
    schedule: z.array(scheduleSchema),
    enableCliff: z.boolean(),
    cliffISOFormat: z.string(),
  })
  .superRefine(({ schedule, startDateISOFormat, token, enableCliff, cliffISOFormat }, ctx) => {
    const state = store.getState();
    const stateBalance: IBalance | null = state.user.balances[token] || null;
    validateBalance(stateBalance, schedule, ctx);
    validateStartDate(schedule, startDateISOFormat, ctx);
    validateDate(schedule, startDateISOFormat, ctx);

    if (enableCliff) validateCliff(schedule, startDateISOFormat, cliffISOFormat, ctx);
    return z.OK;
  });
