import Big from "big.js";

import { BALANCE_PRECISION, CHECKPOINT_FEE, HUNDRED, LOCKUP_FEE, ONE_DAY, ZERO_STRING } from "shared/constants";
import { ILockupCheckpoint, ILockupTermination } from "shared/interfaces";

import { getCurrentTimestamp, getDateInSeconds, removeTrailingZeros } from "./index";

const BASE = 10;
Big.RM = Big.roundDown;
Big.DP = 30;

export const BigNumber = (value: string | number | Big | undefined | null) => {
  try {
    return Big(value || ZERO_STRING);
  } catch (error) {
    console.warn(`Error: ${error} \n\n while working with this value ${value} of such a type ${typeof value} `);
    return Big(ZERO_STRING);
  }
};

export const convertToDecimalsNumber = (value: string, decimals: number): string => {
  if (decimals === null || decimals === undefined) return value;
  const [wholePart, fracPart = ""] = value.split(".");
  const formatFraction = fracPart.padEnd(decimals, "0").slice(0, decimals);
  const unitedParts = `${wholePart}${formatFraction}`;
  const replaceLeadingZero = unitedParts.replace(/^0+/, "");
  const checkEmptyString = replaceLeadingZero.padStart(1, "0");
  return checkEmptyString;
};

export const formatUnits = (value: string | number | Big, decimals = 18, precision?: number): string => {
  try {
    if (!value) return ZERO_STRING;

    return removeTrailingZeros(BigNumber(value).div(BigNumber(BASE).pow(decimals)).toFixed(precision));
  } catch (error) {
    console.error(`Error Big formatUnits \n ${error}`);
    return ZERO_STRING;
  }
};

export const isWhatPercentOf = (value: Big, totalValue: Big): number => {
  try {
    return Number(value.div(totalValue).mul(HUNDRED).toFixed(0));
  } catch (error) {
    console.error(`Error while percentage calculating \n ${error}`);
    return 0;
  }
};

export const getUnlockedAmountTokensData = (
  startAt: number,
  distributionSchedule: ILockupCheckpoint[],
  distributionAmount: string,
  claimedAmount: string,
  termination?: ILockupTermination
) => {
  const currentTimestamp = getCurrentTimestamp();
  const remainingAmount = BigNumber(distributionAmount).minus(claimedAmount);
  let amountToClaim = BigNumber(0);
  let tokenAmountPerDay = BigNumber(0);
  let amountToClaimPercents = 0;
  const lastScheduleIndex = distributionSchedule.length - 1;
  const endDate = distributionSchedule[lastScheduleIndex].endDate;

  if (termination) {
    const numerator = BigNumber(claimedAmount).plus(termination.claimAmount);
    const denominator = numerator.plus(termination.withdrawAmount);
    const progressBarPercent = denominator.eq(0) ? ZERO_STRING : numerator.div(denominator).toFixed(0);
    amountToClaimPercents = Number(progressBarPercent);
    return {
      amountToClaim: BigNumber(termination.claimAmount),
      amountToClaimPercents,
      tokenAmountPerDay,
    };
  }

  if (currentTimestamp < startAt) {
    return {
      amountToClaim,
      amountToClaimPercents,
      tokenAmountPerDay,
    };
  }

  if (currentTimestamp > endDate) {
    amountToClaim = BigNumber(remainingAmount);
    amountToClaimPercents = HUNDRED;
    return {
      amountToClaim,
      amountToClaimPercents,
      tokenAmountPerDay,
    };
  }

  const totalDuration = BigNumber(endDate).minus(startAt);
  const rewardPerSecond = BigNumber(distributionAmount).div(totalDuration);

  const passedDuration = currentTimestamp - startAt;
  const totalAmountToClaim = rewardPerSecond.mul(passedDuration);
  amountToClaim = BigNumber(totalAmountToClaim).minus(claimedAmount);

  amountToClaimPercents = isWhatPercentOf(totalAmountToClaim, BigNumber(distributionAmount));
  const durationInDays = (endDate - startAt) / ONE_DAY;
  tokenAmountPerDay =
    durationInDays < 1 ? BigNumber(distributionAmount) : BigNumber(distributionAmount).div(durationInDays);

  return {
    amountToClaim,
    amountToClaimPercents,
    tokenAmountPerDay,
  };
};

export const calcAmountPerDayByPeriod = (startDate: string, endDate: string, amount: string): string => {
  try {
    if (!startDate || !endDate || !amount) return ZERO_STRING;
    const endDateNum = getDateInSeconds(endDate);
    const startDateNum = getDateInSeconds(startDate);
    const durationInDays = (endDateNum - startDateNum) / ONE_DAY;

    return durationInDays < 1 ? amount : BigNumber(amount).div(durationInDays).toFixed(BALANCE_PRECISION);
  } catch (error) {
    console.error(`Error while getting tokens amount per day  \n ${error}`);
    return ZERO_STRING;
  }
};

export const calcBalanceForPeriod = (balance: string, amount: string): string => {
  try {
    const newBalance = BigNumber(balance).minus(amount || ZERO_STRING);
    return newBalance.lte(ZERO_STRING) ? ZERO_STRING : removeTrailingZeros(newBalance.toFixed(BALANCE_PRECISION));
  } catch (error) {
    console.error(`Error while calculated balance for period  \n ${error}`);
    return balance;
  }
};

export const calcLockupStorageFee = (scheduleLength: number): string => {
  if (scheduleLength === 1) return LOCKUP_FEE;
  const scheduleFee = BigNumber(scheduleLength).times(CHECKPOINT_FEE);
  return BigNumber(scheduleFee).plus(LOCKUP_FEE).toFixed();
};
