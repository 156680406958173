import styled from "styled-components";

export const MessageBlockWrapper = styled.div`
  max-width: 32.5rem;
  width: 100%;
  text-align: center;
  padding: 3.5625rem;
  border-radius: 1.5rem;
  background-color: rgba(${({ theme }) => theme.colors.backgroundBlue}, 0.4);

  div {
    &.logo {
      margin-bottom: 1.5rem;
    }

    &.text {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.1875rem;
      color: rgba(${({ theme }) => theme.colors.grey});
    }
  }
`;
