import { Navigate } from "react-router-dom";

import { APP_ROUTES } from "routes/appRoutes";
import { useAppSelector } from "shared/hooks/redux/useAppSelector";
import { selectAccountId } from "store/slices/user";

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const accountId = useAppSelector(selectAccountId);

  return accountId ? children : <Navigate to={APP_ROUTES.LANDING} replace />;
};
