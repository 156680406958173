import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IBalance } from "shared/interfaces";
import { RootState } from "store";

export interface IUserState {
  id: string;
  lockupId: string;
  balances: { [key: string]: IBalance };
  isDeployedContract: boolean;
}

const initialState: IUserState = {
  id: "",
  lockupId: "",
  balances: {},
  isDeployedContract: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAccountId: (s, { payload }: PayloadAction<string>) => ({ ...s, id: payload }),
    setLockupId: (s, { payload }: PayloadAction<string>) => ({ ...s, lockupId: payload, isDeployedContract: true }),
    setBalances: (s, { payload }: PayloadAction<{ [key: string]: IBalance }>) => ({ ...s, balances: payload }),
  },
});

// SELECTORS
export const selectUser = (s: RootState) => s.user;
export const selectAccountId = (s: RootState) => s.user.id;
export const selectLockupId = (s: RootState) => s.user.lockupId;
export const selectBalances = (s: RootState) => s.user.balances;
export const selectIsDeployedContract = (s: RootState) => s.user.isDeployedContract;

// ACTIONS
export const { setAccountId, setLockupId, setBalances } = userSlice.actions;

export const userReducer = userSlice.reducer;
