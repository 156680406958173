import RPCProviderService from "services/RPCProviderService";
import { TRANSACTION_GAS_PRICE, DEPLOY_CONTRACT_FEE } from "shared/constants";
import { ITransaction, TransactionType } from "shared/interfaces";

import { FactoryChangeMethods, FactoryMethods, IFactoryContract } from "./interfaces";
import { convertGas } from "../helpers";

export default class FactoryContract implements IFactoryContract {
  readonly contractId: string;

  private provider: RPCProviderService;

  public constructor({ provider, contractId }: { provider: RPCProviderService; contractId: string }) {
    this.contractId = contractId;
    this.provider = provider;
  }

  async getLockupsCount(): Promise<number | undefined> {
    return this.provider.viewFunction(FactoryMethods.getLockupsContractsCount, this.contractId);
  }

  async getLockups({ from, limit }: { from: number; limit: number }): Promise<{ [key: string]: string } | undefined> {
    return this.provider.viewFunction(FactoryMethods.getLockupsContracts, this.contractId, { from_index: from, limit });
  }

  async getLockupId({ accountId }: { accountId: string }): Promise<string | undefined> {
    return this.provider.viewFunction(FactoryMethods.getLockupContractId, this.contractId, { account_id: accountId });
  }

  async getLockupsByOwnerCount({ ownerId }: { ownerId: string }): Promise<number | undefined> {
    return this.provider.viewFunction(FactoryMethods.getLockupsByOwnerCount, this.contractId, { owner_id: ownerId });
  }

  async getLockupsByOwner({
    ownerId,
    from,
    limit,
  }: {
    ownerId: string;
    from: number;
    limit: number;
  }): Promise<{ [key: string]: number[] } | undefined> {
    return this.provider.viewFunction(FactoryMethods.getLockupsByOwner, this.contractId, {
      owner_id: ownerId,
      from_index: from,
      limit,
    });
  }

  public static createDeployLockupContractTransaction(
    factoryAccountId: string,
    lockupOwnerAccountId: string
  ): ITransaction {
    return {
      receiverId: factoryAccountId,
      actions: [
        {
          type: TransactionType.FunctionCall,
          params: {
            methodName: FactoryChangeMethods.deployLockup,
            args: {
              user_id: lockupOwnerAccountId,
            },
            deposit: DEPLOY_CONTRACT_FEE,
            gas: convertGas(TRANSACTION_GAS_PRICE),
          },
        },
      ],
    };
  }
}
