import theme from "shared/theme";

import { EButtonColors, IButtonStatusColors } from "./interfaces";

export function getButtonStatusColors(color: EButtonColors): IButtonStatusColors {
  switch (color) {
    case EButtonColors.BLUE:
      return {
        default: theme.buttons.blue,
        active: theme.buttons.blueActive,
        hover: theme.buttons.blueHover,
        text: theme.colors.white,
      };
    case EButtonColors.GREEN:
      return {
        default: theme.buttons.green,
        active: theme.buttons.greenActive,
        hover: theme.buttons.greenHover,
        text: theme.colors.white,
      };
    case EButtonColors.DARK_GREEN:
      return {
        default: theme.buttons.darkGreen,
        active: theme.buttons.darkGreenActive,
        hover: theme.buttons.darkGreenHover,
        text: theme.buttons.textGreen,
      };
    case EButtonColors.DARK_GREY:
      return {
        default: theme.buttons.darkGrey,
        active: theme.buttons.darkGreyActive,
        hover: theme.buttons.darkGreyHover,
        text: theme.buttons.textDarkGrey,
      };
    case EButtonColors.PINK:
      return {
        default: theme.buttons.pink,
        active: theme.buttons.pinkActive,
        hover: theme.buttons.pinkHover,
        text: theme.colors.white,
      };
    case EButtonColors.DARK_PINK:
      return {
        default: theme.buttons.darkPink,
        active: theme.buttons.darkPinkActive,
        hover: theme.buttons.darkPinkHover,
        text: theme.buttons.textPink,
      };
    case EButtonColors.TRANSPARENT:
      return {
        default: theme.buttons.blue,
        active: theme.buttons.blueActive,
        hover: theme.buttons.blueHover,
        text: theme.colors.white,
      };
    case EButtonColors.WALLET:
      return {
        default: theme.buttons.wallet,
        active: theme.buttons.walletActive,
        hover: theme.buttons.walletHover,
        text: theme.colors.white,
      };
    case EButtonColors.DARK_BLUE:
      return {
        default: theme.buttons.darkBlue,
        active: theme.buttons.darkBlueActive,
        hover: theme.buttons.darkBlueHover,
        text: theme.buttons.blue,
      };
    default:
      return {
        default: theme.buttons.blue,
        active: theme.buttons.blueActive,
        hover: theme.buttons.blueHover,
        text: theme.colors.white,
      };
  }
}
