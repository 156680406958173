import { createAsyncThunk } from "@reduxjs/toolkit";

import LockupContract from "services/contracts/LockupContract";
import { IRPCProviderService } from "services/RPCProviderService";
import { ELockupStage, ILockup } from "shared/interfaces";
import { formatLockup, getCurrentTimestamp, getOutgoingLockupsKey, toArray } from "shared/utils";
import {
  updateIncomingLockup,
  updateIncomingLockupStage,
  updateOutgoingLockup,
  updateOutgoingLockupStage,
} from "store/slices/lockups";

import { RootState } from "../index";

const validateStageCreated = (lockup: ILockup) => {
  const currentTimestamp = getCurrentTimestamp();
  if (lockup.startAt && lockup.startAt < currentTimestamp) {
    if (lockup.cliff && lockup.cliff > currentTimestamp) {
      return ELockupStage.Cliff;
    } else {
      return ELockupStage.Running;
    }
  }
  return lockup.stage;
};

const validateStageCliffAndRunning = (lockup: ILockup) => {
  const currentTimestamp = getCurrentTimestamp();
  if (lockup.cliff && lockup.cliff < currentTimestamp) {
    return ELockupStage.Running;
  }
  const lastCheckpoint = lockup.distributionSchedule[lockup.distributionSchedule.length - 1].endDate;
  const lockupEnded = lastCheckpoint < getCurrentTimestamp();
  if (lockupEnded) {
    return ELockupStage.Ended;
  }
  return lockup.stage;
};

export const updateLockups = createAsyncThunk<
  void,
  {
    provider: IRPCProviderService;
  },
  { state: RootState }
>("lockups/updaterLockups", async ({ provider }, { dispatch, getState }) => {
  try {
    const state = getState();
    const {
      user: { lockupId },
      lockups: { incoming, outgoing },
    } = state;
    const incomingLockups = toArray(incoming);
    const outgoingLockups = toArray(outgoing);
    const incomingLockupContract = new LockupContract({ provider, contractId: lockupId });
    const outgoingLockupContracts = outgoingLockups.reduce((acc: { [key: string]: LockupContract }, lockup) => {
      if (!acc[lockup.contractId]) {
        const contract = new LockupContract({ provider, contractId: lockup.contractId });
        acc[lockup.contractId] = contract;
      }
      return acc;
    }, {});

    if (!incomingLockups.length && !outgoingLockups.length) return;

    incomingLockups.forEach(async (lockup) => {
      switch (lockup.stage) {
        case ELockupStage.Created: {
          if (validateStageCreated(lockup) === lockup.stage) return;
          dispatch(updateIncomingLockupStage({ lockupId: lockup.id, stage: validateStageCreated(lockup) }));
          return;
        }
        case ELockupStage.Cliff:
        case ELockupStage.Running: {
          const retrievedLockup = await incomingLockupContract.getLockup({ lockupId: lockup.id });
          if (retrievedLockup) {
            const formattedLockup = formatLockup(retrievedLockup, lockup.contractId, lockup.receiver);
            dispatch(updateIncomingLockup({ lockupId: lockup.id, lockup: formattedLockup }));
          }
          dispatch(updateIncomingLockupStage({ lockupId: lockup.id, stage: validateStageCliffAndRunning(lockup) }));
          return;
        }
      }
    });

    outgoingLockups.forEach(async (lockup) => {
      const lockupId = getOutgoingLockupsKey(lockup.id, lockup.contractId);
      switch (lockup.stage) {
        case ELockupStage.Created: {
          dispatch(updateOutgoingLockupStage({ lockupId, stage: validateStageCreated(lockup) }));
          return;
        }
        case ELockupStage.Cliff:
        case ELockupStage.Running: {
          const outgoingContract = outgoingLockupContracts[lockup.contractId];
          if (!outgoingContract) return;
          const retrievedLockup = await outgoingContract.getLockup({ lockupId: lockup.id });
          if (retrievedLockup) {
            const formattedLockup = formatLockup(retrievedLockup, lockup.contractId, lockup.receiver);
            dispatch(updateOutgoingLockup({ lockupId, lockup: formattedLockup }));
          }
          dispatch(updateOutgoingLockupStage({ lockupId, stage: validateStageCliffAndRunning(lockup) }));
          return;
        }
      }
    });
  } catch (e) {
    console.error(`Error: while update running lockups \n ${e}`);
  }
});
