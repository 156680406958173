import styled from "styled-components";

import { ReactComponent as Error } from "assets/images/icons/error.svg";
import { ReactComponent as SuccessIcon } from "assets/images/icons/success.svg";
import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const Container = styled.div`
  ${flexRowNoWrap}
  gap: 1rem;
`;

const Description = styled.div<{ $error?: boolean }>`
  ${flexColumnNoWrap}
  align-self: center;
  gap: 0.5rem;
  p {
    :last-child {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.938rem;
      color: ${({ theme }) => theme.colors.textGrey};
    }
    :first-child {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.375rem;
      color: ${({ theme, $error }) => ($error ? theme.colors.error : theme.colors.white)};
    }
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textGrey};
  font-size: 0.75rem;
`;

const ErrorIcon = styled(Error)`
  width: 3rem;
  height: 3rem;
`;

export default {
  SuccessIcon,
  Container,
  Description,
  Link,
  ErrorIcon,
};
