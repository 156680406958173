import { useInView } from "framer-motion";
import { useRef } from "react";

import { Translate } from "shared/components/Translate";

import styles from "./styles";

export default function AboutLockup() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <styles.Section ref={ref} isInView={isInView}>
      <styles.Title>
        <Translate value="landing.aboutLockup.title" />
      </styles.Title>
      <styles.Description>
        <p>
          <Translate value="landing.aboutLockup.firstDesc" />
        </p>
        <p>
          <Translate value="landing.aboutLockup.secondDesc" />
        </p>
        <p>
          <Translate value="landing.aboutLockup.thirdDesc" />
        </p>
      </styles.Description>
    </styles.Section>
  );
}
