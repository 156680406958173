import styled, { css } from "styled-components";

import { flexColumnNoWrap } from "shared/theme/styles";

const Container = styled.div`
  flex: 1;
  ${flexColumnNoWrap}
  margin-top: 1.5rem;
  max-width: 32.5rem;
  width: 100%;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToMedium",
      css`
        margin-top: 0;
        padding: 0 1rem;
        align-self: center;
      `
    )}

  & > svg {
    align-self: center;
    justify-self: center;
    margin-top: 200px;
  }
`;

const List = styled.div`
  ${flexColumnNoWrap}
`;

const StyledDeployContractBlock = styled.div`
  margin: 1rem 0;
  ${({ theme }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        margin-top: 3.75rem;
      `
    )}
`;

export default {
  Container,
  List,
  StyledDeployContractBlock,
};
