import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Trans } from "react-i18next";

import { ReactComponent as Calendar } from "assets/images/icons/calendar.svg";
import { Checkbox } from "shared/components/Input";
import { Translate } from "shared/components/Translate";
import { START_DATE_KEY, ENABLE_CLIFF_KEY, CLIFF_DATE_KEY, DATE_FORMAT } from "shared/constants";
import { ISecondStageData } from "shared/interfaces";
import { createFormattedDate } from "shared/utils";

import styles from "./styles";
import WrapperInputStyles from "../WrapperInputStyles";

const Cliff: React.FC = () => {
  const {
    control,
    formState: { errors },
    clearErrors,
    trigger,
    setValue,
  } = useFormContext<ISecondStageData>();
  const startDate = useWatch({ name: START_DATE_KEY, control });
  const cliffDate = useWatch({ name: CLIFF_DATE_KEY, control });
  const enableCliff = useWatch({ name: ENABLE_CLIFF_KEY, control });

  const formattedStartDate = !startDate ? DATE_FORMAT : startDate;
  const formattedEndDate = !cliffDate ? DATE_FORMAT : cliffDate;

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.checked) {
        clearErrors("cliffISOFormat");
      } else {
        trigger("cliffISOFormat");
      }
    },
    [clearErrors, trigger]
  );

  return (
    <styles.CliffContainer>
      <Checkbox
        id={ENABLE_CLIFF_KEY}
        title="createLockup.enableCliff.title"
        description="createLockup.enableCliff.description"
        onChange={onChange}
      />
      {enableCliff && (
        <styles.InputContainer>
          <WrapperInputStyles.Container>
            <WrapperInputStyles.Label>
              <div>
                <Translate value="createLockup.cliff.title" />
                <span>*</span>
              </div>
            </WrapperInputStyles.Label>
            <WrapperInputStyles.Border error={Boolean(errors.cliffISOFormat)}>
              <styles.InputWrapper>
                <Trans
                  i18nKey="createLockup.cliff.input"
                  values={{ startDate: formattedStartDate, endDate: formattedEndDate }}
                />
                <styles.DatePickerToggle>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      components={{
                        OpenPickerIcon: Calendar,
                      }}
                      disablePast
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <>
                          <input ref={inputRef} {...inputProps} />
                          {InputProps?.endAdornment}
                        </>
                      )}
                      value={cliffDate}
                      onChange={(date: Dayjs | null) => {
                        if (!date) return;
                        setValue("cliffISOFormat", createFormattedDate(date));
                        trigger("cliffISOFormat");
                      }}
                    />
                  </LocalizationProvider>
                </styles.DatePickerToggle>
              </styles.InputWrapper>
            </WrapperInputStyles.Border>
            {errors.cliffISOFormat && (
              <WrapperInputStyles.Error>{errors.cliffISOFormat.message}</WrapperInputStyles.Error>
            )}
          </WrapperInputStyles.Container>
        </styles.InputContainer>
      )}
    </styles.CliffContainer>
  );
};

export default Cliff;
