import background from "assets/images/landing-images/background.svg";

import AboutLockup from "./AboutLockup";
import Features from "./Features";
import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";
import Partner from "./Partner";
import styles from "./styles";

export default function Landing() {
  return (
    <styles.Container>
      <Header />
      <Main />
      <Partner />
      <AboutLockup />
      <Features />
      <Footer />
      <styles.Background src={background} loading="lazy" />
    </styles.Container>
  );
}
