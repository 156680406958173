import { createSelector } from "@reduxjs/toolkit";

import { selectCreateLockupData } from "store/slices/createLockup";

export const selectedCreateLockupData = createSelector([selectCreateLockupData], (lockup) => ({
  stage: lockup.stage,
  startDate: lockup.secondStage.startDate,
  receiver: lockup.firstStage.receiver,
  thirdPartyOwner: lockup.firstStage.thirdPartyOwner,
  schedule: lockup.secondStage.schedule,
  token: lockup.secondStage.token,
  canTerminate: lockup.secondStage.canTerminate,
  cliff: lockup.secondStage.cliff,
  receiverLockupContract: lockup.firstStage.receiverLockupContract,
}));
