import styled from "styled-components";

import { ReactComponent as Info } from "assets/images/icons/info.svg";
import { flexColumnNoWrap, flexRowNoWrap } from "shared/theme/styles";

const Container = styled.div`
  ${flexColumnNoWrap}
  background: ${({ theme }) => theme.colors.cardBg};
  box-shadow: 0px 4px 12px -4px ${({ theme }) => theme.colors.boxShadowCard};
  border-radius: 1.5rem;
  padding: 1rem;
  gap: 1.5rem;
`;

const Description = styled.div`
  ${flexRowNoWrap}
  gap: 0.625rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.938rem;
  color: ${({ theme }) => theme.colors.textGrey};
`;

export default {
  Container,
  Info,
  Description,
};
