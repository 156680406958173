import { motion } from "framer-motion";
import styled, { css } from "styled-components";

const Title = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  margin-bottom: 2rem;
`;

const ValueAddition = styled.div`
  display: block;
  text-align: right;
  margin-top: 0.5rem;
`;

const Element = styled(motion.div)<{ $largeList: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${({ $largeList }) => ($largeList ? "1.5rem" : "0.75rem")};
  gap: 1rem;
  &:last-child {
    margin-bottom: 0;
  }

  & > div {
    font-weight: 400;
    line-height: ${({ $largeList }) => ($largeList ? "1.188rem" : "1rem")};
    font-size: ${({ $largeList }) => ($largeList ? "1rem" : "0.75rem")};
  }
`;

const Description = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.colors.textGrey};
  max-width: 14.375rem;
`;

const Value = styled.div<{ isStaticWidth?: boolean }>`
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme, isStaticWidth }) =>
    theme.mediaWidth(
      "upToSmall",
      css`
        max-width: ${isStaticWidth ? "11.25rem" : "fit-content"}!important;
      `
    )}
`;

export default {
  ValueAddition,
  Title,
  Element,
  Description,
  Value,
};
